@use '../../style/color.scss' as * ;
@import "../../style/font.css";

.staff_container{
  .staff-info .calendar-view{
    // margin-top: 60px;
  }
}
.staff-info{
  font-family: Avenir-Medium;
  .btn-linear-orange{
    width: auto ;
    height: 35px !important;
    line-height: 35px !important;
    bottom: auto;
    background-repeat: repeat !important;
    border-radius: 25px !important;
    font-weight: 600;
    font-family: 'open-sans-semibold';
  }
  .calendar-view{
    display: flex;
    width: 100%;
    //max-width: 784px;
    letter-spacing: 0;
    position: relative;
    flex-wrap: wrap;
    justify-content: flex-start;
    // margin: 60px -20px 0;
  }
  .staff-avatar{
    border-radius: 10px;
    width: 170px;
    height: 155px;
    margin-right: 40px;
    object-fit: cover;
  }
  .calendar-item{
    max-width: 166px;
    max-height: 166px;
    border-radius: 10px;
    padding: 0;
    margin: 20px;
    cursor: pointer;
    img{
      width: 100%;
      height: 100%;
    }
    &:before{
      content:'';
      padding-top:100%; /* vertical value as  100% equals width */
      float:left;
    }
  }

  .txt-name{
    color: #00CDAC;
    font-family: 'open-sans-semibold';
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 4px;
    text-transform: capitalize;
  }
  .txt-note{
    color: #4A4A4A;
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 4px;
    &:nth-child(2), &:nth-child(4){
      font-family: "open-sans-regular";
    }
  }
  .txt-calendar-note{
    bottom: 18px;
    white-space: nowrap;
    font-size: 11px;
    color: $color_DDDCDB;
    z-index: 8;
    position: absolute;
    left: 15px;
    font-family: Avenir-Medium;
  }
  .txt-calendar-work{
    position: absolute;
    bottom: 26px;
    z-index: 8;
    left: 15px;
    font-family: open-sans-bold;
  }
  .txt-work{
    color: #31CCBC;
    font-family: 'open-sans-semibold';
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 4px;
  }
}

.go-back{
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  color: #31CCBC;
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-size: 15.3px;
  line-height: 19px;
  margin-top: 30px;
  // margin-left: 10px;
  // margin-bottom: 30px;
  position: absolute;
  // top:20px;
  // left: 30px;
  // z-index: 10;
  cursor: pointer;
  img{
    width: 14px;
    height: 23px;
    margin-right: 10px;
  }
}
.remove-admin{
  width: 60%;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 20px;
}


.staff-info .calendar-item , .staff-info .calendar-item img{
  width: 164px !important;
  height: 157px !important;

}
#tool-title_0{
  position: relative !important;
  top: -44px;
  left: -34px;
}
span.txt-calendar-note.tool-title{
  color: #DDDCDB;
  font-family: "open-sans-regular";
  font-size: 11.256px !important;
  font-weight: 400 !important;
}
#tool-title_1{
  left: -24px;
  top: -44px
}
#tool-title_2{
  left: -53px;
  top: -44px;
}
#tool-title_3{
  left: -56px; 
  top: -44px
}
#calendar-week , #calendar-month {
font-size: 16px !important;
font-weight: 600 !important;
}
.staff-calendar .time-scroll{
  font-size: 16px !important;
  font-weight: 400px !important;
  font-family: "open-sans-regular";
  margin-right: 32px;
}
.staff-calendar .day-item{
  width: 142px;
  height: 143px;
  padding: 25px;
}
.staff-calendar .day-item .txt-day{
  font-size: 20px;
  font-weight: 800;
  font-family:'open-sans-extrabold';
}
.staff-calendar .day-item .txt-hours {
  font-size: 42px;
  font-weight: 400;
  font-family:"open-sans-regular";
}
.staff-calendar .day-item .txt-unit{
  font-size: 15px;
  font-weight: 400;
  font-family:"open-sans-regular";
}
.staff-calendar .week-view, .staff-calendar .month-view{
  gap: 0px;
}
.back-to-page{
cursor: pointer;
z-index: 9;
}
.back-text{
  position: fixed;
  color:  #4A4A4A;
font-family: 'open-sans-extrabold';
font-size: 16px;
font-style: normal;
font-weight: 800;
line-height: normal;
margin-left: 10px;
}
.staff-info-tools{
  color: var(--Teemie-Text, #4A4A4A);
font-family: 'open-sans-extrabold';
font-size: 20px;
font-style: normal;
font-weight: 800;
line-height: normal;
margin-top: 10%;
}

// media qurrey

@media screen and (max-width: 2560px){

.staff-info .calendar-item {
  max-height: 186px;
  max-width: 264px;
  margin-left: 0px;
}
.staff-info .calendar-item img{
  max-height: 186px;
  max-width: 264px;
}
span.txt-work.txt-calendar-work.tool-name{
  padding-left: 0px;
  top: -57px;
  left: 18px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  position: relative !important;
  font-family: 'open-sans-bold';
}
span.txt-calendar-note.tool-title{
  position: relative !important;
  top: -39px;
  left: -22px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
#calendar-month{
  margin-left: 53px;
  font-family: "open-sans-regular";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}
#calendar-week{
  margin-left: 3px;
  font-family: "open-sans-regular";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
} 
}

@media screen and (max-width: 1440px){
.staff-info .staff-avatar{
  width: 169px !important;
  height: 155px !important;
  margin-right: 0px;
}
.staff-info .txt-name{
  color: #00CDAC;
  font-family: "open-sans-regular";
  font-size: 22px !important;
  font-style: normal;
  font-weight: 700 !important;
  padding-left: 39px;
  text-transform: capitalize;
}
.staff-info .txt-note{
  color:  #4A4A4A;
  font-family: "open-sans-regular";
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  padding-left: 39px;
}
  .staff-info .txt-work{
    color: #31CCBC;
    font-family: 'open-sans-semibold';
    font-size: 17px !important;
    font-style: normal;
    font-weight: 700;
    padding-left: 39px;
  }
  .staff-info .calendar-item{
    width: 164px !important;
    height: 157px !important;
    margin-left: 6px;
  }
  .staff-info .calendar-item img {
    width: 164px !important;
    height: 157px !important;
  }
  span.txt-work.txt-calendar-work.tool-name{
    position: relative !important;
    margin-top: -4px !important;
    align-items: start;
    padding-left: 0px;
    top: -57px;
    left: 18px;
    color: #00CDAC;
    font-family: 'open-sans-bold';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
  }
  span.txt-calendar-note.tool-title{
  position: relative !important;
  top: -30px;
  left: -21px;
  color: #DDDCDB;
  font-family: "open-sans-regular";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  }
.staff-calendar .time-tab span{
  color: #4A4A4A;
  font-family: 'open-sans-regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
#calendar-month{
  margin-left: 53px;
}
#calendar-week{
  margin-left: 6px;
}

}
@media screen and (max-width: 1024px) {
.staff-info .staff-avatar {
  width: 145px !important;
  height: 122px !important;
  margin-right: 0px;
}
.staff-info .txt-name {
  font-size: 18px !important;
  font-style: normal;
  font-weight: 700 !important;
}
.staff-info .txt-note {
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
}

.staff-info .calendar-item {
  width: 132px !important;
  height: 123px !important;
}
.staff-info .calendar-item img {
  width: 132px !important;
  height: 123px !important;
}
span.txt-work.txt-calendar-work.tool-name{
  top: -57px;
  left: 18px;
  font-size: 11px !important;
  font-weight: 700;
}
span.txt-calendar-note.tool-title {
  top: -30px;
  left: -21px;
  font-size: 9px;
  font-weight: 400;
}
#calendar-week {
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
}
#calendar-month {
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
}
}