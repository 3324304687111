@import "../../style/font.css";
.staff-hours {
  font-family: "Helvetica", "Arial", sans-serif;
  border: 1px solid rgba(206, 206, 206, 0.1);
}
.staff-hours .button-not-resp {
  display: flex;
}
.staff-hours .button-resp {
  display: none;
}
.staff-hours .back-to-roster {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  color: #31CCBC;
  font-family: "Helvetica", "Arial", sans-serif;
  font-size: 15.3px;
  line-height: 19px;
  margin-left: 10px;
  margin-bottom: 30px;
  width: 25%;
  float: left;
}
.staff-hours .back-to-roster img {
  width: 14px;
  height: 23px;
  margin-right: 10px;
}
.staff-hours .back-to-roster:hover {
  cursor: pointer;
}
.staff-hours .title {
  margin-bottom: 30px;
}
.staff-hours .member-view {
  padding: 57px 20px 25px !important;
}
.staff-hours .wrap_box.shadow-1 {
  border-radius: 5px;
  margin-bottom: 18px;
  border: 1px solid rgba(206, 206, 206, 0.2);
}
.staff-hours .staffname {
  color: #31ccbc;
  font-family: "open-sans-extrabold";
  font-size: 16px;
  max-height: 25px;
  overflow: hidden;
  text-align: left !important;
  text-overflow: ellipsis;
  white-space: pre;
  width: 100%;
  text-transform: capitalize;
}
.staff-hours .barista {
  color: #4a4a4a;
  font-family: "open-sans-semibold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 10px;
  text-transform: capitalize;
}
.staff-hours .working {
  color: #4a4a4a;
  font-family: "open-sans-regular";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 10px;
  text-align: justify;
}
.staff-hours .topmenu.active {
  color: #31CCBC;
}
a {
  text-decoration: none;
}

.staff-hours-card {
  width: 286px;
  height: 200px;
  position: relative;
  cursor: pointer;
}

.staff-card-image {
  display: flex;
  justify-content: space-between;
}

.staff-hours-view {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 22px;
}

@media screen and (max-width: 1310px) {
  .staff-hours .button-not-resp {
    display: none !important;
  }
  .staff-hours .button-resp {
    display: flex !important;
  }
}

