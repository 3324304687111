.popup {
  min-height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-family: Avenir;
}

.popup-bottom {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-top: 35px;
}

.popup-btn-ok {
  color: #333;
  cursor: pointer;
  border: 1px solid #ccc;
  padding: 2px;
  width: 100px;
  border-radius: 3px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.popup-btn-cancel {
  background-color: #ccc;
  color: white;
  cursor: pointer;
  border: 1px solid #ccc;
  padding: 2px;
  width: 100px;
  border-radius: 3px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.btn-close {
  cursor: pointer;
  position: absolute;
  right: 10px;
  width: 40px;
  height: 40px;
  padding: 5px;
  top: 10px;
}

