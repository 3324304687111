@use '../../style/color.scss' as * ;
@import "../../style/font.css";

.roster-container.calendar-view .header-roster-scroll{
    position: fixed;
    top: 0;
    width: 100%;
    // z-index: 999;
    background: #fff;
    //border-bottom: 1px solid #ddd;
    padding: 10px 0;
  }
  
.roster-container.roster-view.my-team-detail .search-bar{
  width: 97% !important;
}
.roster-container.roster-view{
  .calenderDiv
  {
    width: 89%;
    margin-left: 14%;
  }
  .noti-shift{
    margin-top: 5px;
  }
  .shift-notes{
    font-size: 8px;
  }
  .logo-bell{
    margin: 0px 5px;
    width: 11px;
    height: 13px;
    position: static;
    margin-right: 0px;
  }
  .member-view{
    padding: 4px 40px 0 0 !important;
  }
  .search-view{
    margin-left: 0px !important;
  }
  .add_new_roster{
    padding: 15px;
  }
}
.roster-view{
  .month_view_calender{
   .date-view{
      width: 100%;
      height: 46px;
    }
    .day-view{
      color: #FFF;
      font-family: 'open-sans-extrabold';
      font-size: 14px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
    }
  }
  .header-roster {
    
    .date-header {
      height: 52px;
      flex-wrap: wrap;
      padding-bottom: 22px;
    }

    

    &.scroll-active {
      margin-top: 8px;
      position: fixed;
      background: #fff;
      z-index: 1;
      width: 78%;
      top: 147px;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      padding-top: 30px;

      .date-header {
        padding-bottom: 0;
        height: 35px;
      }

      .date-view {
        min-height: 30px;
      }
    }
  }

  .add_new_roster{
    position: relative;
    right: 0;
  }

  .btn-new-event{
    width: auto;
    margin: 5px 5px 5px 30px;
    font-family: 'open-sans-semibold';
    color: rgb(48, 48, 48);
    font-size: 15px;
    line-height: 20px;
  }
  .border-def{
    border: 1px solid #31CCBC !important ;
  }
  .menu-left-action{
    width: 145px;
    height: 35px;
    line-height: 35px;
  }
  .member-view{
    justify-content: space-between;
    padding: 4px 40px 0 40px !important;
    //padding: 45px 70px 0 70px !important;
  }
  .switch-week{
    max-width: 260px;
    width: 260px;
    min-width: 240px;
    border: none !important;
    font-size: 16px;
    line-height: 22px;
    color: $color_def;
    margin-bottom: 0 !important;
    align-items: center;
    padding: 0 !important;
    font-family: open-sans-extrabold;
    .fa-angle-left, .fa-angle-right{
      width: 20px;
      height: 20px;
      font-size: 22px;
      line-height: 22px;
      cursor: pointer;
      font-weight: bold;
      text-align: left;
    }
    .fa-angle-right{
      text-align: right;
    }
  }
  .tab-view{
    padding: 0 !important;
    //max-width: 300px;
    justify-content: flex-end;
    .tab-item{
      margin: 0 10px;
    }
  }
  .date-view{
    min-height: 60px;
    background-color: $color_31CCBC;
    border-radius: 3px;
    align-items: center;
  }
  .day-view{
    width: calc(100% / 8);
    text-align: center;
    color: $color_white;
    font-size: 15px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    font-family: 'open-sans-extrabold';
    font-weight: 800;
    display: flex;
    flex-direction: column;
  }
  .week-view{
    //padding: 0 70px;
    //margin-top: 22px;
  }
  .roster-view{
    margin-top: 7px;
    .roster-member-view{
      width: 100%;
      //height: 90px;
      margin: 0;
      //border: 1px solid $color_grey;
    }

    .roster-member-col{
      width: calc(100% / 8);
      aspect-ratio: 1 / 1;
      //padding-top: calc((100% / 8) - 14px);
      //height: 100%;
      margin: 7px;
      position: relative;
      //margin-right: 15px;
      border-radius:10px;
      border: 1px solid $color_DDDCDB;
      overflow: hidden;
      .time-info{
        position: absolute !important;
        width: 100% !important;
        height: 100% !important;
        font-size: 21px !important;
        line-height: 27px !important;
        display: flex;
        flex-direction: column;
      }
      .txt-off{
        background-color: transparent !important;
        color: $color_def !important;
      }
      .stick-to-bottom{
        position: absolute;
        bottom: 0px;
        width: 100%;
      }
      .bottom-name-view {
        position: relative;
        color: $color_white;
        text-align: center;
        font-weight: lighter !important;
        font-family: 'open-sans-extrabold';
        font-weight: 800;
        line-height: 15px;
        height: 60px;
        word-break: break-all !important;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 15px;
        text-transform: capitalize;
      }
      .name-view{
        position: absolute;
        top:0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgb(0, 0, 0);
        font-size: 15px;
        line-height: 19px;
        color: $color_white;
        text-align: center;
        font-weight: lighter !important;
        font-family: 'open-sans-extrabold';
      }
      .grey-background {
        position: absolute;
        top:0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.4);
      }
      &:last-child{
        margin-right: 0 !important;
      }
      &:first-child{
        margin-left: 0 !important;
        border-color: transparent;
        font-family: open-sans-bold;
        position: relative;
      }
      &:before{
        content:'';
        padding:37% 0; /* vertical value as  100% equals width */
        //float:left;
        display: inline-block;
      }
      img{
        width: 100%;
        height: 100%;
        position: absolute;
        top:0;
        left:0;
      }
    }
  }

  .button-not-resp{
    display: flex;
  }
  .button-resp{
    display: none;
  }
}
#popup-confirm-roster{
  .modal-content{
    width: 912px !important;
    //height: 442px !important;
    padding: 80px 60px;
    margin: auto;

  }
  .modal-body{
    position: initial;
  }
  .modal-content{
    position: relative;
  }
  .logo{
    height: 100px;
    object-fit: cover;
  }
  .icon-close{

  }
  .modal-title{
    font-size: 21px;
    line-height: 27px;
    color: $color_def;
    text-transform: capitalize;
  }
  .title{
    color: #31CCBC;
    font-family: 'open-sans-bold';
    font-size: 22.4px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    line-height: 27px;
    color: $color_def;
    margin: 40px 0 20px 0;
    display: block;
  }
  .content{
    font-size: 18px;
    line-height: 22px;
    color: $color_4A4A4A;
    font-family:  'open-sans-semibold';
    color: #4A4A4A;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

}
.user-name-day{
  position: relative;
  font-family: 'Open Sans ExtraBold', sans-serif;
  color: #ffffff;
  text-align: center;
  font-weight: lighter !important;
  line-height: 15px;
  word-break: break-all !important;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  padding: 5px;
}
#popup-create-roster .modal-content {
  margin-top: 50px !important;
}

.modal-open #popup-create-roster .modal-dialog, .modal-open #popup-create-roster .modal-content, .modal-open #popup-create-roster .modal-body {
  width: 410px !important;
  //height: 442px !important;
  border-radius: 8px;
  //padding: 20px;
  max-width: 100%;
  height: 599px;

  .carousel{
    height: 80px;
    margin: 20px 0 16px 0;
    display: flex;
    align-items: center;
    .carousel-control{
      background: none;
    }
  }
  .modal-title{
    font-size: 22px;
    line-height: 28px;
    color: $color_def;
    font-family: 'open-sans-bold';
    text-transform: capitalize;
  }
  .title{
    font-size: 22px;
    line-height: 28px;
    font-family: 'open-sans-bold';
    //font-weight: bold;
  }
  .staff-view{
    //min-width: 78px;
    //width: 78px;
    //max-width: 78px;
    //height: 78px;
    //margin: auto;
    // width:100%

    .name-staff {
      font-size: 11px !important;
      padding: 5px !important;
      line-height: 22px !important;
    }
  }
  .fa-angle-left, .fa-angle-right{
    font-size: 62px;
    font-weight: bold;
    opacity: 0.3;
    color: $color_def;
    z-index: 1000;
    cursor: pointer;
  }
  .name-shift{
    font-size: 22px;
    line-height: 30px;
    color: $color_4A4A4A;
    font-family:'open-sans-semibold';
    text-transform: capitalize;
  }
  .txt-off{
    color: $color_def;
    font-size: 40px;
    font-family: 'open-sans-semibold';
  }
  .time-shift{
    font-size: 22px;
    line-height: 30px;
    color: $color_9B9B9B;
    margin-top: 10px;
    font-family: 'open-sans-semibold';
  }
  .slider{
    .slider-list{
      width: 100% !important;
    }
    .slider-slide{
      width: 100% !important;
      height: 100% !important;
      display: flex !important;
      align-items: center;
      justify-content: center;
    }
    .slider-frame{
      display: flex !important;
      align-items: center !important;
      height: 100% !important;
    }
    .slider-control-bottomcenter{
      display: none;
    }
  }
  .btn-linear-invite{
    width: 269px !important;
    height: 50px;
    font-size: 12px !important;
    max-width: 100%;
    border-radius: 25px;
    margin: 0 auto;
    margin-top: 20px;
    font-family: open-sans-regular;
    text-align: center;
  }
  .drop-staff{
    margin: 0px 0 4px 0;
    .fa-check{
      position: absolute;
      top:0;
      left: 0;
      background-color: rgba(0,0,0,0.1);
      width: 100%;
      height:100%;
      font-size: 50px;
      color: $color_def;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.button_display_row{
  margin-top: 40px;
}
.modal-open .modal.holiday-request .modal-content{
  width: 932px;
  height: 599px;
  margin-top: 13%;
}
@media screen and (max-width: 1310px) {
  .roster-view {
    .button-not-resp {
      display: none;
    }
    .button-resp {
      display: flex;
    }
  }
}
.popup-menu-roster-export.tooltip{
  right: 10px;
}
.popup-menu-roster-export-inner{
  width: 200px;
  height: 100px;
  //left: auto !important;
  box-shadow: 0 3px 13px rgba(0, 0, 0, 0.5) !important;
  left: 411px !important;
  top: 10px;
  background-color: $color_white;
  font-family: open-sans-semibold;
  opacity: 1 !important;
  transition: none !important;
  padding: 15px 15px !important;
  border-radius: 5px;
  border: 1px solid rgba(151,151,151,0.5);
  display: flex;
  flex-direction: column;
  .header-name{
    font-size: 12px !important;
    font-family: open-sans-bold !important;
    color: $color_def !important;
    position: relative;
    display: flex;
    align-items: center;
    maginleft: 20px;
  }
  .header-avatar{
    border-radius: 100px;
    width: 40px;
    height: 40px;
    object-fit: cover;
  }
  .number-badge{

  }
  div.export-wrapper{
    width: 167px;
    height: 31px;
  }
  a, div.export {
    width: 167px;
    // margin: auto;
    height: 31px;
    font-size: 15px;
    color:$color_grey;
    position: relative;
    display: flex;
    align-items: center;
    //background-color: $color_def;
    border-radius: 5px;
    padding: 0 10px;
    .icon-link{
      height: 22px;
      object-fit: contain;
      margin-right: 25px;
    }
    &:hover{
      background-color: $color_def;
      color: $color_white;
      .number-badge{
        color: white;
      }
    }
    .number-badge{
      right: 10px;
      left: auto;
      top: 10px;
      background-color: transparent;
      font-size: 17px;
      font-family: open-sans-bold;
      color: $color_def
    }
  }
  .header-name{
    font-size: 13px;
    font-family: Avenir-Roman;
    color: $color_C4C4C4;
    margin: 0 10px 0 20px;
    letter-spacing: 2px;
    white-space: pre;
  }
}

.toggle-switch {
  position: relative;
  width: 75px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
  margin-right: 10px;
  &.small-switch {
    width: 40px;
    .toggle-switch-inner {
      &:after,
      &:before {
        content: "";
        height: 20px;
        line-height: 20px;
      }
    }
    .toggle-switch-switch {
      width: 16px;
      right: 20px;
      margin: 2px;
    }
  }
  &-checkbox {
    display: none;
  }
  &-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
    margin: 0;
  }
  &-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    &:before,
    &:after {
      display: block;
      float: left;
      width: 50%;
      height: 34px;
      padding: 0;
      line-height: 34px;
      font-size: 14px;
      color: white;
      font-weight: bold;
      box-sizing: border-box;
    }
    &:before {
      content: "Yes";
      text-transform: uppercase;
      padding-left: 10px;
      background-color: #31ccbc;
      color: #fff;
    }
  }
  &-disabled {
    background-color: #ddd;
    cursor: not-allowed;
    &:before {
      background-color: #ddd;
      cursor: not-allowed;
    }
  }
  &-inner:after {
    content: "No";
    text-transform: uppercase;
    padding-right: 10px;
    background-color: #bbb!important;
    color: #fff;
    text-align: right;
  }
  &-switch {
    display: block;
    width: 24px;
    margin: 5px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 40px;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
  }
  &-checkbox:checked + &-label {
    .toggle-switch-inner {
      margin-left: 0;
    }
    .toggle-switch-switch {
      right: 0px;
    }
  }

  @media screen and (max-width: 991px) {
    transform: scale(0.9);
  }
  @media screen and (max-width: 767px) {
    transform: scale(0.825);
  }
  @media screen and (max-width: 575px) {
    transform: scale(0.75);
  }
}

.background-grey {
  background: #f4f6f6 !important;
  border: 0px;
}

div.pac-container {
  z-index: 99999 !important;
}

input[type="range"] {
  margin: 40px 0;
}

.pac-target-input {
  background-color: #f4f6f6;
  transition: none;
  color: #9b9b9b;
  border-radius: 0;
  border-width: 0 0 1px!important;
  border-color: #f4f6f6!important;
  box-shadow: none;
  padding: 13px 10px;
  width: 350px;
  margin: 62px 0px 103px 0;
  font-size: 20px;
}

.title-large {
  margin-bottom: 18px;
  font-size: 22px;
  line-height: 36px;
  font-family:'open-sans-bold';
  color: $color_def1;
  font-weight: 700;
  margin-top: 23px;
  letter-spacing: 1px;
}
.content-roster-model{
  color: #4A4A4A;
  text-align: center;
  font-family:'open-sans-regular';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.48px;
  line-height: 26px;
  text-align: center;
}
.content-roster-model-active{
  color: #4A4A4A;
text-align: center;
font-family: 'open-sans-semibold';
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.48px;
}
.clock-content{
  color: #4A4A4A;
text-align: center;
font-family: 'open-sans-semibold';
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.range_input_map {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-left: 5%;
  padding-right: 5%;

}
.input-range-location{
  margin-top: 20px;
  margin-bottom: 0px;
}
.map {
  margin: 20px 0;
}
#myinput {
  // background: linear-gradient(to right, #6AD5E5 0%, #00CDAC 50%, #fff 50%, white 100%);
  background-color: #f4f6f6;
  border: solid 1px #ccc;
  border-radius: 8px;
  height: 15px;
  width: 356px;
  outline: none;
  transition: background 450ms ease-in;
  -webkit-appearance: none;
}
// @media screen and (-webkit-min-device-pixel-ratio:0) {
//   input[type='range'] {
//     // overflow: hidden;
//     -webkit-appearance: none;
//     background-color: #c3dede;
//     border-radius: 25px;
//   }

//   input[type='range']::-webkit-slider-runnable-track {
//     height: 20px;
//     -webkit-appearance: none;
//     color: #31CCBC;
//   }

input[type="range"]::-webkit-slider-thumb {
  width: 25px;
  -webkit-appearance: none;
  height: 25px;
  cursor: ew-resize;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(147, 147, 147, 0.5);
  //     border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 25px;
  //     margin-top: -6px;
}
// }

.bubble{
  position: absolute;
  margin: 10px;
  border-radius: 50px;
  width: 33px;
  height: 20px;
  background: #31ccbc;
  color: white;
}

.modal-open #popup-create-roster .modal-dialog, .modal-open #popup-create-roster .modal-content, .modal-open #popup-create-roster .modal-body {
  width: 830px !important;
}

.info-details {
  text-align: left;
  padding: 0px;
  margin-top: 12px;

  .info-text {
    padding:  0px 10px 0px 0;
    font-size: 17px!important;
    line-height: 30px;
    font-family: 'Avenir-Roman',sans-serif;
  }

  .info-radius {
    padding: 0px 10px 0px 0;
    font-size: 12px;
    color: #9b9b9b;
    font-family: 'Avenir-Roman',sans-serif;
    letter-spacing: 0px;
    line-height: 30px;
  }
}

span.name-shift.time-title,
span.time-shift.time-value{
  width: 50%!important;
}


.info-roster-button {
  margin-top: 5px !important;
}

.custom-modal-title{
  font-size: 22px;
  line-height: 28px;
  font-family: 'open-sans-extrabold',sans-serif;
  color: #31ccbc;
}


.shift-note-detail{
  font-family: 'open-sans-semibold',sans-serif;
  font-size: 13px;
  overflow-y: auto;
  max-height: 100px;
  margin-bottom: 60px;
  line-height: 20px;
}


.margin-0-auto{
  margin:0 auto!important;
}

.modal-body,
.modal-content{
  overflow-x: hidden!important;
}


.button-fonts{
  font-family: 'open-sans-semibold',sans-serif;
}

.centered-button{

  display: block!important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.back-to-roaster-info {
  margin-top: 0;
  //float: right;
  color: #31ccbc;
  font-size: 15.3px;
  height: 28px !important;
  line-height: 28px;
  flex: 1;
  cursor: pointer;
  .back-img {
    height: 15px !important;
    margin: 5px;
  }
}
.todayDate{
  color:#000;
}
.time-shift .form-group{
  width: 100% !important;
}
.time-shift form{
  display: flex;
  gap: 40px;
}
.time-shift .display_row{
  justify-content: center;
}
.manualInput-time-shift .form-group {
  transform: scale(1.3);
  fieldset {
    border: none !important;
  }
  .shift-time-picker {
    display: none !important;
  }
}
.time-shift.manualInput-time-shift {
  .MuiOutlinedInput-input {
    padding: 16.5px 6px !important;
  }
  fieldset.MuiOutlinedInput-notchedOutline {
    border-bottom: 1px solid #d1cece !important;
    border-radius: 0px !important;
  }

}


.event-no-content{
  text-align: center;
  font-family: open-sans-regular;
  font-size: 32px;
  font-weight: 500;
  color: #31ccbc;
  width: 100%;
}
.open-sans-regular{
  font-family: open-sans-regular;
}
.shift-notes{
  font-family: open-sans-regular;
  font-size: 9px;
  font-weight: 800;
  line-height: 8px;
  letter-spacing: 0px;
  text-align: center;
  margin-bottom: 0;
}
.show-popup {
  display: block !important;
  opacity: 1 !important;
  pointer-events: auto !important;
}
.noti-shift{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}
.roster-view .roster-view .roster-member-col .noti-shift .logo-bell{
  margin: 0px 5px;
  //color: rgb(235, 105, 101);
  width: 11px;
  height: 13px;
  position: static;
  margin-right: 0px;
}
.shittime {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  padding-bottom: 20px;
}
.clock-in-edit .title {
  margin-bottom: 60px !important;
  width: 100%;
  margin-top: 40px;
}
.clock-in-edit .time {
  align-content: flex-start;
}
.modal-open .clock-in-edit .time .time-value {
  margin-top: 0 !important;
}
.clock-in-edit .time .name-shift.time-title {
  margin-bottom: 9px;
}
.clock-in-popup.fade .modal-dialog {
  width: 100% !important;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.clock-in-popup.fade .modal-content {
  // max-width: 932px !important;
  margin: 0 auto;
  width: 912px !important;
  padding: 58px;
  padding-bottom: 63px;
  margin-top: 30px;
}
.clock-in-popup.fade .modal-body {
  max-width: 486px !important;
  width: 100% !important;
}
.clock-in-popup.fade .content {
  color: #4a4a4a;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.4px;
  max-width: 311px;
  width: 100%;
  margin: 0 auto;
  font-family: open-sans-regular;
}
.clock-in-popup.fade .range_input_map {
  // margin-bottom: 24px;
}
.modal-open #popup-create-roster .modal-content {
  max-width: 912px !important;
  width: 100% !important;
  margin: 0 auto;
  padding: 56px 104px;
}
.modal-open #popup-create-roster .modal-dialog {
  width: 100% !important;
  margin: 0 auto;
}

#popup-create-roster .btn-linear-invite.edit,
#popup-create-roster .btn-linear-invite.delete {
  height: 34px !important;
  margin-bottom: 7px !important;
  color: #fff;
  text-align: center;
  font-family: Open-Sans-regular;
  color: #FFF;
  text-align: center !important;
  font-size: 8.4px !important;
  font-style: normal !important;
  font-weight: 600;
  line-height: normal !important;
}
.back-to-roaster-info.pop{
  position: absolute;
  left: 0;
  top: 0;
  font-family: 'open-sans-regular', sans-serif;
}
.holiday-body .Already-Scheduled h6.error{
  color: red;
  text-align: start;
}

.roster-view.calendar-view .search-view
{
  width: 41%;
}
.roster-view.my-team-detail .search-bar
{
  width: 84%;
}
.my-team-detail .search-bar{
  min-height: 75px !important;
}

  .calendarWeekName {
  display: grid;
  grid-template-columns: repeat(7,1fr);
  gap: 10px;
  background-color: #31CCBC;
  border-radius: 3px;
  align-items: center;
  margin-left: 34px;
  text-align: center;
  font-family: "open-sans-regular";
  color: #ffffff;
    margin-top: 28px;
  height:46.47px;
    
}
.calendarWeekDate {
  display: grid;
  grid-template-columns: repeat(7,1fr);
  text-align: center;
  font-family: "open-sans-regular";
  // height:95.55px;
  padding: 12PX 0PX;
  gap: 16PX;
 margin-left: 34px;
}
#dayName{
  width: 36.94px;
  height: 36px;
  left: 33.01px;
  margin-left: 33.01px;
  margin-top: -4.44px;
  font-family: "open-sans-regular";
  font-weight: 800;
  font-size: 13.27px;
  line-height: 18.07px;
}
.day {
  width: 97.70px;
  height: 87.85px;
  left: 115.76px;
  border: 0.55px solid #DDDCDC; 
  padding: 25px;
  border-radius: 4px;

}
.today {
  color: #000;
}
.totalMonthDiv{
  display: flex;
  .day-view{
    width: calc(100% / 7);
  }
  .roster-member-view {
    margin: 0px;
    width: 101% !important;
    margin-left: -5px;
  }
  .roster-member-col {
    aspect-ratio: 1 / 1;
    border: 1px solid #dddcdb;
    border-radius: 10px;
    margin: 7px;
    overflow: hidden;
    position: relative;
    width: calc(100%/7)
}
.week_day {
  height: 100%;
}
.name-view.time-info {
  align-items: center;
  background-color: white;
  color: #fff;
  display: flex;
  font-family: open-sans-extrabold;
  font-size: 21px;
  font-weight: lighter !important;
  height: 100%;
  justify-content: center;
  line-height: 27px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
  flex-direction: column;
  position: absolute !important;
}
.time-info {
  font-size: 21px !important;
  line-height: 27px !important;
}
.date-view-content {
  margin-top: 10px;
}
.txt-off {
  background-color: initial !important;
  color: #31ccbc !important;
}
.day-view.today {
  color: #000;
}
.disabled{
  background-color: #DFDFDF;
  color: #9b9b9b;

}
.month_view_calender.loader-active{
  background-color: #ffffff3b;
  opacity: 0.1;
}
.spinnLoader {
  position: fixed;
  top: 50%;
}
}
.loginUserImages{
  width: 100%;
  height:100%;
  border-radius: 5px;
  box-shadow: 0px 1.46px 4.37px 0px #DDDCDC;
  margin-top: 5px;
}
.leftDiv{
  line-height: 21.79px;
  font-family:"open-sans-regular" ;
  margin-top: 10%;
}
.loginUser{
  font-size: 16px;
  font-weight: 800;
  color: #00CDAC;
  align-items: center;
  font-family:'open-sans-extrabold';
  font-style: normal;
  margin-top: 20px;
  text-transform:capitalize;
}
.UserDetails > h5{
  font-family: 'open-sans-semibold';
  font-size: 14px;
  color: #4A4A4A;
  font-weight: 600;  
  letter-spacing: 0.5px;
}
.MonthMessageBtn{
  background-color: #31CCBC;
  font-size: 30px;
  border: none;
  margin-top: 20px;
  color: #FFFFFF !important;
  outline: none !important;
  font-size: 12px !important;
  font-family: 'open-sans-regular';
  font-weight: 500 !important;
  width: 144.17px;
  height: 32.64px;
  padding: 0.83px 0px 1.17px 0px;
  box-shadow: 0px 2px 5px 0px #00000033;

}
.monthselectoptions{
  width: 140px;
  margin-top: -33px;
  border: none !important;
  font-size: 15.3px;
  font-family:"open-sans-regular" ;
  font-weight: 700;
  color: #4A4A4A;
}
.monthselectoptions > div > div > div{
  color: #4A4A4A;
}
.viewList{
  font-size: 15px;
  font-family:'open-sans-bold';
  font-weight: 700;
  color: #4A4A4A !important;
  width: 83px;
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.css-vj8t7z{
  border: none !important;
}
.css-d8oujb{
 display: none !important;
}
.roster-view button.btn-btn-primary{
  left: 850px;
}
.roster-view .tab-view{
  margin-right: -10px;
}
.rosterdetails > h5{
  color: #4A4A4A;
font-family:'open-sans-semibold';
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
  letter-spacing: 0.5px;
}
// ----

.calenderDiv{
  width: 85%;
  height: auto;
  margin-bottom: 50px;
  top: 245.62px;
  left: 483px;
  margin-left: 12%;
}
.monthchangeicons{
 
  font-family: "open-sans-regular" ;
  font-weight: 800;
  font-size: 16.38px;
  letter-spacing: 0;
  text-transform: uppercase;
  // margin-left: 34px;
}
.monthIcons{
  width: 8.24px;
  height: 13.68px;
  margin-top: 4.16px;
  rotate: -180;
}

.drop-down-list-user .fa-angle-down{
  color: #4A4A4A !important;
  font-size: 15px !important;
  font-weight: 700;
  margin-top: 4px;
}
.user-name-title{
  font-family: 'open-sans-bold';
  color: #4a4a4a;
  font-weight: 700;
  text-transform: capitalize;
}
.drop-down-list-user.background{
  background: #31CCBC;
    border-radius: 4px;
    width: 100%;
}
.drop-down-list-user .user-name{
  color: #4a4a4a ;

}
.drop-down-list-user.user-list{
  padding: 0 0 0 20px !important;
  margin: 0  !important;
}
.drop-down-list-user.background .user-name{
  color: #ffffff ;
  text-align: start !important;
  display: flex;
  justify-content: start;
  align-items: center; 
}
.drop-down-list-user{
  color: $color_757575;
  border: none !important;
  cursor: pointer;
  font-size: 16px;
  justify-content: flex-start;
  align-items: center;
  margin: 0px 0 0 15px !important;
  max-width: 260px;
  min-height: 30px;
  width: 100%;
  // color: #31CCBC;
  font-family:  'open-sans-bold';
  text-transform: capitalize;
  
}
.header-roster-scroll .group-user-list-popup{
  top: 100%;
}
.group-user-list-popup {
  position: absolute;
  top: 8%;
  left: 0;
  width: 100%;
  max-width: 300px;
  min-width: auto;
  border-radius: 5px;
  border: 1px solid #eaeaea;
  background: white;
  padding: 20px;
  margin-bottom: 10px;
  z-index: 10;
  margin-left: 49px;
  margin-top: -16px;
  line-height: 35px;
}
.team-name-title{
  font-size: 16px;
  font-family: 'open-sans-extrabold' !important;
  font-weight: 800;
  color: #757575 !important;
  width: fit-content;
  height: 22px;
  scrollbar-width: none;
  cursor: pointer;
}
// .team-name{
//   overflow-x: hidden;
// }
// week --
.search-view{
  flex-basis: fit-content;
  margin-left: -5px !important;
}
.icon-plus{
  width: 20px;
  height: 20px;
  object-fit: cover;
  margin-right: 10px;
}
.roster-view .switch-week .fa-angle-left, .roster-view .switch-week .fa-angle-right{
  width: 20px !important;
  height: 20px !important;
}
.roster-view .roster-view .roster-member-col{
  border-radius: 10px !important;  
}

.searchInputbox{
  width: 209.36px !important;
  height: 32.49px;
  border-radius: 110px;
  border: none !important;
  background: #F3F3F3;
  margin-left: -115px;
  font-family: 'open-sans-regular';
  font-weight: 400 !important;
  font-size: 14px !important;
  padding-left: 22px;
}
.SearchDiv{
  position: relative;
}
.SearchIcon{
  width: 20px;
  height: 20px;
  border: 0.95px solid #FFFFFF;
  position: absolute;
  right: 10px;
  top: 6px;
}
.roster-view .date-view{
  width: 100%;
}


.spinnLoader
{
  height: 30px !important;
  width: 30px !important;
  position: absolute;
  left: 53%;
  top: 37%;
}
.roster-view.my-team-detail .search-bar {
  padding: 0 !important;
  padding-left: 22px !important;
}
.loginuserTitle{
  color: #4A4A4A;
  font-family: 'open-sans-semibold';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;

}
.loginuserTitle{
 width: fit-content;
  overflow-x: hidden;
}
.day.disabled{
  background: #DFDFDF;
}
.dayNumber {
  font-size: 21px !important;
  font-weight: lighter !important;
  font-family: open-sans-extrabold;
}
#clock_In > img{
  width: 20px !important;
  height: 20px !important;
}
.roster-view.my-team-detail .search-bar{
  width: 79% !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
}

.search-view {
  margin-left: 21px !important;
}

.roster-view .roster-view .roster-member-view{
  margin-top: 0px !important;
}
.btn-linear_clock{
  background-image: linear-gradient(to right, #00CDAC, #6AD5E5) !important;
  background-repeat: repeat !important;
  font-size: 13.681px !important;
  background-color: lightgray;
  border-radius: 50px !important;
}
.btn-linear-orange_clock{
  background-image: linear-gradient(to right, #FFDEA7, #EA6362) !important;
  background-color: #cacaca;
  background-repeat: repeat !important;
  border-radius: 50px !important;
  font-size: 13.681px !important;
  font-family: 'open-sans-regular';
  font-weight: 500;

}
.clock_buttonDiv{
  display: flex;
  justify-content: space-between;
  width: 400px;
  margin-top: 60px;
  margin-left: 42px;
}
.shadow_popup {
  box-shadow: 0 .5rem 1rem rgb(255, 255, 255) !important;
}
.emptyDevContainer{
  height: 100px;
  background-color: #fff;
}
.addNewRosterBtn{
  color: #FFF;
  width: 127px;
  text-align: center;
  font-family: 'open-sans-semibold';
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
#clock-title{
  color: #00CDAC;
font-family: 'open-sans-extrabold';
font-size: 26px;
font-style: normal;
font-weight: 800;
line-height: normal;
}
// roster page of media querrys

// responsive display 2560px
@media only screen and (max-width: 2560px) {
  .navbar-expand-lg .navbar-nav li.nav-item{
  width: 110%;
}
  .header-container  {
    padding: 0px !important;
  }
  .searchInputbox{
    width: 309px !important;
  }
  .date-view .display_row{
    margin-top: 21px;
  }
  .roster-view .roster-view .roster-member-view{
    margin-top: 14px;
  }
  .roster-view .date-view{
    width: 100%;
    height: 66px;
  }
  .roster-view{
    .month_view_calender{
     .date-view{
        width: 100%;
        height: 46px;
      }}}
  
  
  .header_fixed{
  position: absolute;
  top: 50%;     
  }
  

  // --month
  
  #dayName{
    text-align: center;
  }
  .calendarWeekDate{
    padding: 0px;
    margin-top: 22px;
    text-align: center;
  }
  .calendarWeekName{
    height: 66px;
    margin-top: 50px;
  }
  monthchangeicons{
    margin-left: 193px;
  }
  .loginUserImages{
    margin-top: 25px;
    width: 274px;
  }
  
  
 
  .roster-view .tab-view {
    margin-right: -10px;
}
}
// responsive display 1440px     
@media only screen and (max-width: 1440px) {
  
  .header-container{
    width: 1181px;
  }
  .roster-view .date-view{
    
    margin-top: 15px;
  }
  #bottom-name {
    height: 50px !important;
  }
  .roster-view .header-roster.scroll-active
  {
    width: 78%;
  }
  .roster-view .switch-week{
    width: 213px;
    height: 22px;
    min-width: 250px;
  }
  .roster-view .tab-view{
    margin-right: -29px;
    color: #4A4A4A;
    font-family: "open-sans-regular";
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal; 
  }
 
  .shittime{
    color: #FFF;
    text-align: center;
    font-family: "open-sans-regular";
    font-size: 21px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  .roster-view.my-team-detail .search-bar{
    padding-left: 24px !important;
  }
  .roster-view .header-roster .date-header{
    margin-top: -10px;
    padding: 10px;
  }
  .roster-view .btn-new-event{
    margin-left: 16px !important;
    padding: 6px;
    color: #757575;
    font-family: 'open-sans-bold';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.544px;
  }
  .team-name-title{
    margin-left: 15px;
    color: #757575;
    font-family: 'open-sans-extrabold';
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  .calendar-view .icon-plus{
    width: 20px;
    height: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .addNewRosterBtn{
    color: #FFF;
    width: 127px;
    text-align: center;
    font-family: 'open-sans-semibold';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .notifications-header-icon{
    width: 30px;
    height: 30px;
    flex-shrink: 0;
  }
  .header-container .header-avatar{
    width: 45px !important;
    height: 45px !important;
    border-radius: 45px !important;
    object-fit: cover !important;
    box-shadow: 0px 1px 14px 0px rgba(0, 0, 0, 0.72) inset;
  }
  .fa{
    width: 20px !important;
    height: 20px !important;
  }
.roster-view .switch-week .fa-angle-left{
  color: #31CCBC;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.roster-view .switch-week .fa-angle-right{
  margin-right: 13px;
}
.searchInputbox{
  font-family: "open-sans-regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 209px !important;
}
.roster-view .day-view{
  color: #FFF;
  text-align: center;
  font-family: "open-sans-regular";
  font-size: 15px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.todayDate{
  color: #000;
  text-align: center;
  font-family: "open-sans-regular";
  font-size: 15px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.header-fa-angle{
  width: 14px !important;
  height: 23px !important;
  margin-left: 32px;
} 
#header-angle{
  margin-left: 10px;
}


.navbar-expand-lg .navbar-nav li.nav-item .nav-link{
  font-size: 18px;
  font-weight: 800;
  font-family: "open-sans-regular";
  color: #4A4A4A;
}
.navbar-light .navbar-brand{
  font-family: "open-sans-regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: #4A4A4A;
}
.header_fixed{
  position: absolute;
  right: 19px;
  top: 50%;
  color: white;
  padding: 10px;
  border-radius: 5px;
}

.roster-view.my-team-detail .search-bar{
  width: 79%;
}
// month view page querrys
.calendarWeekName{
  margin-top: 28px;
height: 46px;
margin-left: 41px;
}
.calendarWeekDate{
  margin-top: 12px;
   margin-left: 45px;
  gap: 18PX;
   height:95px;
}
.loginUserImages {
  width: 133px;
  height: 122px;
  border-radius: 5px;
  box-shadow: 0px 1.46px 4.37px 0px #DDDCDC;
  margin-top: 5px;
  margin-left: 0px;
}
.loginUser ,.loginuserTitle ,.MonthMessageBtn{
  margin-left: 0px; 
}
.day{
  width: 95px;
  height: 87px;
  padding: 13px !important;
}
.leftDiv{
  margin-left: 0px;
}

#dayName{
  color: #FFF;
font-family: "open-sans-regular";
font-size: 13px;
font-style: normal;
font-weight: 800;
line-height: normal;
}
.today{
  color: #000 !important;
text-align: center;
font-family: "open-sans-regular" !important;
font-size: 15px !important;
font-style: normal;
font-weight: 800 !important;
line-height: normal;
}
monthchangeicons{
  margin-left: 41px !important;
}
.loginUserImages{
  width: 133px;
  height: 122px;
}
.user-name-title{
  color: #4A4A4A;
font-family: 'open-sans-bold';
font-size: 15px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.612px;
text-transform: capitalize;
}
.loginUser{
  color: #00CDAC;
font-family: 'open-sans-extrabold';
font-size: 16px;
font-weight: 800;
margin-top: 15px;
}
.rosterdetails{
  color: #4A4A4A;
  font-family:'open-sans-semibold';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
margin-top: 6px;

}
.UserDetails{
  width: 144px;
  height: 32px;
  flex-shrink: 0;
filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.20));
}
.MonthMessageBtn{
  color: #FFF;
  text-align: center;
  font-family: 'open-sans-regular';
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
  padding: 8px;
}
.navbar-expand-lg .navbar-nav li.nav-item{
  width: 100%;
}
// day view 
.my-team-detail .member-view .button-view{
  width: 103%;
  margin-right: -24px;
}
}
// responsive display 1024px
@media only screen and (max-width: 1024px) {
  .navbar-expand-lg .navbar-nav li.nav-item{
    width: 77%;
  }
  .roster-view.my-team-detail .search-bar{
    margin: 0px;
    padding: 0px !important;
  }
  .roster-view.calendar-view .search-view{
    margin-left: -43px !important;
  }
  .header-roster {
    width: 718px;
  }
  .roster-view .date-view {
    width: 742px;
    margin-top: 29px;
  }
  .roster-view .roster-view {
    width: 742px;

  }
  .searchInputbox{
    width: 160px !important;
    height: 26px !important;
  }
  .SearchIcon{
    right: 11px;
    top: 6px !important;
    width: 15px !important;
    height: 15px !important;
  }
  .roster-view .day-view{
    color: #FFF;
    text-align: center;
    font-family: 'open-sans-semibold';
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    width: 718px;    
  }
  .todayDate{
    color: #000;
    text-align: center;
    font-family: "open-sans-regular";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
  }
  .roster-view .member-view{
    padding: 0px !important;
  }
  .roster-view .roster-view .roster-member-col{
    margin-left: 5px !important; 
  }
  .shittime{
    font-size: 13px;
  }
  .roster-view .tab-view{
    margin-right: -49px;
  }
  .calendar-view .tab-view .tab-item{
    font-size: 13px;
    margin-left: -12px !important;
  }
  .team-name-title{
    margin-left: 10px;
    width: 97px;
    font-size: 14px;
  }
  .toolset p{
    display: none;
  }
  .roster-view .switch-week{
    font-size: 13px;
    margin-left: -6px;
    min-width: 282px;
  }
  .roster-view .switch-week .fa-angle-left{
    font-size: 20px;
  }
  .roster-view .switch-week .fa-angle-right{
    font-size: 20px;
    margin-right: 87px;
  }  
  .searchInputbox{
    margin-left: -139px;
    font-size: 13px !important;
  }
  .roster-view .btn-new-event.btn-design{
    margin-right: 164px !important;
  } 
  .header-notification{
    width: 25px !important;
    height: 20px !important;
  }
  .header-container .header-logout{
    font-size: 21px !important;
  }
  .header-container .header-avatar{
    width: 35px !important;
    height: 35px !important;
  }
  .fa{
    margin-left: -3px !important;
    font-size: 21px;
  }
  .left-sidebar{
    width: 19%;
  } 
  .sidebar-header::after{
    width: 224px;
    height: 116px;
  }
  .header-logo{
    margin-left: -6px;
  }
  .navbar-expand-lg .navbar-nav li.nav-item .nav-link{
    font-size: 15px;
    font-weight: 800;
    font-family: "open-sans-regular";
    color: #4A4A4A;
  }
  .navbar-light .navbar-brand{
    font-family: "open-sans-regular";
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    color: #4A4A4A;
  }
  .roster-view .roster-view .roster-member-col .bottom-name-view {
    font-size: 15px;
  }
  .header_fixed{
    position: absolute;
    right: 120px;
    top: 50%;
    transform: translateY(-50%);
    color: white;
    padding: 10px;
    border-radius: 5px;
  }
  .roster-view .roster-view .roster-member-col{
    margin: 5px;
  }
  #bottom-name {
    height: 40px !important;
    font-size: 11px;
  }
  // month view 
  .loginUserImages{
    width: 100px;
    height: 100px;
  }
  .roster-view .btn-new-event{
    // display: none !important;
    margin-left: 8px !important;
  }
  .roster-view .btn-new-event.disableButton{
        display: none !important;

  }
  .viewListDiv{
    margin-right: 729px;
  }
  .calendarWeekName{
    width: 62%;
    margin-left: -10px;
    margin-top: 0px;
  }
  .calendarWeekDate{
    margin-left: -10px;
    gap: 5PX;
    width: 62%;
  }
  .day{
    width: 96%;
    height: 80%;
    padding: 15px !important;
  }
  .dayNumber{
    font-size: 12px !important;
  }
  #dayName{
    font-size: 12px !important;  
    color: #FFF;
    text-align: center;
    font-family: "open-sans-regular";
    font-weight: 600;
  }
  .MonthMessageBtn{
    width: 100px;
  }
  monthchangeicons{
    margin-left: -7px !important;
  }
  .Class_0{
    margin-top: 26px;
  }
  .viewList {
    font-size: 12px;
    font-family: "open-sans-regular";
    font-weight: 600;
    color: #4A4A4A !important;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.loginUser , .loginuserTitle , .user-name-title{
  font-size: 12px !important;
}
.user-name-title{
  width: 80px;
}

.toggleUser_icon{
  font-size: 20px !important; 
  margin-left: 10px !important;
}
.drop-down-list-user .user-name{
  font-size: 13px;
  font-weight: 600;
  font-family: "open-sans-regular";

}
.group-user-list-popup{
  width: fit-content !important;
}
.drop-down-list-user.user-list {
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-top: 4px !important;
  width: fit-content;
}
.header_fixed {
  position: absolute;
  right: 22px;
  }
  .roster-view.my-team-detail .search-bar {
    width: 75%;
}
.pagination {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 40px;
  margin-bottom: 40px;
  width: 744px;
}
  
}


/* responsive display 768px */
.member_profile{
  position: fixed;
  width: 10%;
  // margin-left: -20px;
}
@media (max-width: 1399px) {
  .calenderDiv
  {
    width: 80%;
  }
}
.rosterdetails{
  color: #4A4A4A;
  font-family:'open-sans-semibold';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 6px;
  white-space: pre-line;
}
.roster-container.roster-view.my-team-detail .header-roster-scroll.search-bar{
  width: 80% !important;
}

#export-roster-scroll .popup-menu-roster-export-inner {
  position: fixed;
  top: 140px;
  left: 350px;
}
.clockin_info{
  position: absolute;
  /* top: 10px; */
  bottom: 4px;
  left: 0px;
  width: 129.226px;
  height: 18.388px;
 
}
.clockin_info p{
  color: #FFF;
  text-align: center;
  font-family: 'open-sans-extrabold';
  font-size: 9.12px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.loading-info{
  z-index: 99 !important;
}