$black: #000000;
$blackTwo: #343434;
$primary: #ef4d3c;
$tertiary: #2db54b;
$borderGrey: #ecebeb;
$white: #ffffff;
$secondary: #3b73ae;
$btnBorder: #cdcdcd;
$formControlBorder: #acacac;
$hrColor: #c0c1c1;
$border-grey: #ecebeb;
$border-checkout: #d0d0d0;

$color_black: #4A4A4A;
$color_white: #FFFFFF;
$color_def1: #00CDAC;
$color_def: #31CCBC;
//$color_def: rgb(0, 255, 172, 1);
$color_7F8FA4: #7F8FA4;
//$color_def: #31CCBC;
$color_grey: #9B9B9B;
$color_DDDCDB: #DDDCDB;
$color_DDDCDC: #DDDCDC;
$color_C4C4C4: #C4C4C4;
$color_757575:#757575;
$color_31CCBC:#31CCBC;
$color_4A4A4A: #4A4A4A;
$color_4A90E2: #4A90E2;
$color_31CCBC: #31CCBC;
$color_05668D: #05668D;
$color_797979: #797979;
$color_979797: #979797;
$color_FBFBFB: #FBFBFB;
$color_9B9B9B: #9B9B9B;
$color_F8F8F8: #F8F8F8;
$color_8B9898: #8B9898;
$color_D0021B: #D0021B;
$color_DBD7D7: #DBD7D7;
$color_354052: #354052;
$color_DFDFDF: #DFDFDF;
$color_EBEBEB: #EBEBEB;
$color_FF001F: #FF001F;
$color_F9F9F9: #F9F9F9;
$color_DC5960: #DC5960;
$color_C7C7C7: #C7C7C7;
$color_CCC: #CCC;
//$color_878787: #8787878;
$color_F6F6F6: #F6F6F6;
$color_555: #555;
$color-blue: #1385e5;
$color-gray: #dfe0e4;
$color-white: #ffffff;
$slider-handle-size: 20px;
$slider-size: 4px;
$slider-fix: 20px;
