@use '../../style/color.scss' as * ;
@import "../../style/font.css";

.select2-selection__arrow{
    display: none;
}
.select2-search__field{
    display: none;
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single{
    border: none;
}
// .select2-selection .select2-selection--single{
//     border: none;
// }
.select2-container--classic .select2-selection--single .select2-selection__rendered{
    color: white !important;
}
