@use '../../style/color.scss' as * ;
@import "../../style/font.css";

.user_management_container{
  .body_container
  {
    padding-top: 60px !important;
    padding-left: 0px !important;
  }
}
.userheader h1 {
  font-family: open-sans-extrabold;
  font-size: 25px;
  font-weight: 800;
  line-height: 34px;
  letter-spacing: 0px;
  text-align: left;
  color: #31CCBC;
  margin-top: -15px;
}

.user-table th {
  font-family: 'open-sans-bold';
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.47999995946884155px;
  text-align: left;
  color: #31CCBC;
}

.user-table tr:last-child td {
  border-bottom: none;
}

.searchbar-container {
  position: absolute;
  top: 150px;
  right: 20px;
}

.searchbar-input {
  width: 274.67px;
  height: 41.22px;
  border-radius: 28.73px;
}

.table-body {
  height: '68px';
  top: '454px';
  left: '320px';
}

.user-manage .table-body td {
  border-bottom: 1px solid rgba(144, 144, 144, 0.26);
}

.user-manage .table-body .img-text.disabled {
  opacity: 20%;
  font-family: open-sans-regular;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.47999995946884155px;
  text-align: center;
}

.user-manage .table-body .img-text img {
  min-width: 15px;
  width: 15px;
  height: 15px;
  margin-left: 18px;
}

.userimageEllipse {
  display: inline;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 33px;
}


.image-container {

  white-space: pre;
  cursor: pointer;
  display: inline-block;
  /* Adjust the display property as needed */

  .img-close {
    margin-left: 18px;
    cursor: pointer;
  }

  /* Adjust the padding as needed */
}

.img-text {
  text-align: center;
  font-family: 'open-sans-semibold';
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.47999995946884155px;
  text-transform: capitalize;
}

.user-search {
  padding: 0;
  justify-content: flex-end;
  align-items: center;
  left: 0;
  bottom: 0px;
}

.text-box-search-team {
  border-radius: 100px;
  color: #9B9B9B;
  background-color: Cream;
  // padding: 0 20px;
  font-size: 14px;
  height: 41px;
  width: 274px;
  max-width: 275px;
  margin: 2px;
  border: 1px solid #F9F6EF;
  padding: 15px;
}

.icon-search {
  position: absolute;
  right: 30px;
  top: 10px;
  width: 25px;
  height: 25px;
}

.Textlist {
  font-family:'open-sans-semibold';
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.47999995946884155px;
  text-transform: capitalize;
}

.Textlist::first-letter {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.listMember {
  text-transform: lowercase;
}

.listMember::first-letter {
  text-transform: uppercase;
}

// .logo{
// width: 70.7542495727539px;
// height: 63.99840545654297px;
// top: 281px;
// left: 685px;
// }
.disabled {
  color: gray;
  cursor: not-allowed;
  opacity: 0.5;
}

.new-member {
  Font-family: open-sans-regular;
  width: 100%;
  margin-left: 0px;
  color: #31CCBC;
  white-space: pre;
  font-size: 20px;
  cursor: pointer;
  margin-top: 20px;
  display: flex;
  align-items: center;
  padding-right: 15px;
  padding-bottom: 20px;
  justify-content: flex-end;
}

.img-plus {
  filter: invert(50%) sepia(94%) saturate(314%) hue-rotate(125deg) brightness(95%) contrast(160%);
  ;
  width: 19px;
  min-width: 19px;
  height: 19px;
  margin-left: 10px;
}

.group-down {
  position: absolute;
  left: 260px;
}

.txt-al {
  color: black;
}

.user-manage {
  background: #fbfbfb;
  // padding-left: 53px;
  // padding-right: 50px;
  width: 100%;
}

.user-manage .userheader {
  display: flex;
  justify-content: space-between;
  // margin-bottom: 30px;
  padding: 50px;
  padding-bottom: 0px;
}

.user-manage .userheader .user-search {
  position: relative;
  left: 0;
  bottom: 0;
}

.user-manage .userheader .icon-search {
  top: 15px;
  right: 30px;
  width: 15px;
  height: 15px;
}

.user-manage .userheader .text-box-search-team {
  padding-right: 42px;
}

.user-manage .userheader .text-box-search-team::placeholder {
  font-family: open-sans-regular;
  font-size: 11px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
}
.user_tables{
  padding: 62px;
  padding-top: 0px;
}
.team_sizeDiv{
  display: flex;
  margin-top: 5.5%;
  justify-content: space-between;
  width: 1158px;
  gap: 37px;
  padding-left: 62px;
  align-items: center;
 }
 .team_sizeDiv .management-title{
  color: #31CCBC;
  font-family:'open-sans-extrabold';
  font-size: 25px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
 }
 .team_sizeDiv .input-serach{
  position: relative;
  padding-left: 39.4%;
 }
 .team_sizeDiv input{
  width: 209.362px;
  height: 32.489px;
  flex-shrink: 0;
  border-radius: 110px;
  background: #F3F3F3;
  border: none;
  color:  #4A4A4A;
  font-family: 'open-sans-regular';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 17px;
 }
 .team_sizeDiv .add-btn{
  width: 115.06px;
  height: 26px;
  flex-shrink: 0;
  background: linear-gradient(to left, #6AD5E5, #00CDAC);
  color: #FFF;
  text-align: center;
  font-family: 'open-sans-semibold';
  font-size: 9.577px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 0px;
 }
 .user_management_container .billing-title{
  color: #31CCBC;
  font-family: 'open-sans-extrabold';
  font-size: 25px;
  font-weight: 800;
  font-style: normal;
  line-height: normal;
  margin-top: 5%;
  padding-left: 62px;
 }
 .user_management_container .billing-details{
  display: flex;
      width: 1110px;
      padding-top: 41px;
      padding-left: 54px;
      gap: 55px;
 }
 .user_management_container .billing-box{
  width: 173.7px;
  height: 121.5px;
  flex-shrink: 0;
  border-radius: 9px;
  border: 0.36px solid #E0E0E0;
  background: #FFF;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  transition: border-color 0.3s;
 }
 .user_management_container .billing-box.billing-tool:hover{
  border: 0.36px solid var(--Teemie-Green, #E0E0E0);
 }
 .user_management_container .billing-box:hover{
  border: 1.5px solid var(--Teemie-Green, #31CCBC);
 }
 .user_management_container .billing-box:active{
  border: 1.5px solid var(--Teemie-Green, #31CCBC);
 }

 .user_management_container .billing-box h2{
  width: 131.4px;
  color: #31CCBC;
  text-align: center;
  font-family: 'open-sans-extrabold';
  font-size: 14px;    
  font-style: normal;
  font-weight: 800;
  line-height: normal;
 }
 .user_management_container .billing-box p{
  width: 131.4px;
  color:  #4A4A4A;
  text-align: center;
  font-family:'open-sans-extrabold';
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  padding-top: 10px;
 }
 .user_management_container .billing-box h5{
  color: #F08274;
  text-align: center;
  font-family: 'open-sans-extrabold';
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  cursor: pointer;
 }
 .user_management_container .billing-box h6{
  width: 131.4px;
  color: #4A4A4A;
  text-align: center;
  font-family:'open-sans-extrabold';
  font-size: 10px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
 }
.user-manage .user-table th {
  padding-bottom: 22px;
}

.user-manage .userimageEllipse {
  margin-bottom: 18px;
  margin-top: 15px;
}

.user-manage .new-member {
  font-size: 16px;
  font-weight: 600;
  font-family: open-sans-regular;
}

.user-manage .new-member .img-plus {
  margin-right: 15px;
}

.main {
  min-height: 100vh;
}

.fix-modal.remove-user.fade.in.modal {
  z-index: 999999;
  padding: 0 !important;
}
.remove-user{
  font-family: open-sans-regular;
  font-size: 15px;
  font-weight: 600
}
.fix-modal.fade.in.modal.remove-user .modal-content {
  width: 100% !important;
  max-width: 800px !important;
  padding: 0;
  margin: 0 !important;
}

.fix-modal.fade.in.modal.remove-user .modal-dialog {
  width: 100% !important;
  max-width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fix-modal.fade.in.modal.remove-user .modal-body {
  width: 100% !important;
  max-width: 100% !important;
  padding: 73px 60px;
}

.fix-modal.fade.in.modal.remove-user .modal-body .content {
  font-family:'open-sans-regular';
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.47999995946884155px;
  text-align: center;


}

.remove-user .logo {
  width: 84px;
  height: 74px;
}

.fix-modal.fade.in.modal.remove-user .modal-footers {
  border: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-around;
}

.fix-modal.fade.in.modal.remove-user .login_btn_login {
  max-width: 188px;
  color: #FFF;
  text-align: center;
  font-family: open-sans-regular;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.data-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  // background: rgba(0, 0, 0, 0.23);
}

.user-table .user-name {
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  width: 100%;
}

.user-table .user-detail {
  display: flex;
  align-items: center;
}

.add-staff-sub-title {
  font-family: open-sans-regular;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  width: 70%;
  margin: 0 auto;
  color: #000;
}

.new-member {
  float: right !important;
}

.userRoleSection{
  width: 320px !important;
    max-width: 100%;
    margin: auto;
}
.userRoleSection .select__control{
  width: 100%;
}
.userRoleSection .select__placeholder{
  top: auto !important;
}
.new-member.new-users{
  width: 100%;
  max-width: 123px;
  height: 28px;
  color: #FFF;
  text-align: center;
  font-family: 'open-sans-regular';
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
  // padding: 0 45px;
  margin-top: -35px;
}

.add-member-popup input::placeholder, .add-member-popup input, .add-member-popup .userRoleSection .select__placeholder{
  color: #9B9B9B;
  font-family: open-sans-regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.add-member-popup .select__indicator-separator{
  display: none;
}
.add-member-popup .title{
  color: #00CDAC;
  font-family: open-sans-bold;
  font-size: 26px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal !important;
  display: block;
}
.add-member-popup .select__value-container{
  padding-left: 0;
}
.add-member-popup .select__dropdown-indicator{
  padding-top: 0;
}

.add-member-popup .select__dropdown-indicator svg{
  fill:#00CDAC;
}

.add-member-popup .menu-left-action.btn-linear{
  margin-top: 82px;
  width: 100%;
  max-width: 241px;
  height: 57px;
  border-radius: 0;
  font-family: 'open-sans-regular';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.add-member-popup .form-group{
  margin-bottom: 26px;
}

.modal-open .modal.add-member-popup .modal-body{
  overflow: hidden !important;
}
.modal-open .modal.add-member-popup .modal-content{
  max-height: 100%;
}
.modal.add-member-popup .select__menu{
  text-align: left;
}
.paginations {  
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -26px;
  margin-bottom: 20px;
  
}
.pagination-prev , .pagination-next{
  color:  #4A4A4A;
  font-family: 'open-sans-bold';
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.544px;
  cursor: pointer;
}
.arrow-icon,
.arrow-icon-right {
  cursor: pointer;
  padding: 10px;
  transition: background-color 0.3s;
}

.arrow-icon img,
.arrow-icon-right img {
  width: 20px;
  height: 20px;
}

.arrow-icon-right img {
 rotate: 180deg;
}

.page-numbers {
  cursor: pointer;
  padding: 6px;
  margin: 0 5px;
  transition: background-color 0.3s;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  border: 1px solid #D4D4D4;
  display: flex;
  align-items: center;
  color: #D5D5D5;
text-align: center;
font-family: 'open-sans-bold';
font-size: 13px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.544px;
}

/* Active (highlighted) page number */
.page-numbers.active {
  width: 23px;
  height: 23px;
  background-color: #31CCBC;
  color: white;
  border-radius: 50%;
  display: flex
;
  align-items: center;
  color: #FFF;
  font-family: "open-sans-bold";
  font-size: 14.95px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.626px;
}

/* Page number hover effect */
// .page-numbers:hover {
//   background-color: #f0f0f0;
//   border-radius: 25%;
// }
.hidden {
  display: none;
}
.head{
  color: #31CCBC;
text-align: center;
font-family: 'open-sans-bold';
font-size: 22.4px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-align: center;
width:100%;
height:33px;
padding: 10px 290px;
}
.team-size{
  // position: absolute;
    font-family:open-sans-regular;
    font-size: 16px;
    font-weight: 800;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(49, 204, 188, 1);
}
.team-size p{
  font-family: 'open-sans-semibold' !important;
}
.user-management-selct {
  margin-top: 36px;
}

.user-management-selct .css-15k3avv.select__menu {
  width: 273px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 0.5px solid #DBDBDB;
  background: #FFF;
  margin-left: 25px;
  cursor: pointer;
  z-index: 1;
  max-height: 145px; 
  overflow-y: auto;  
}
.user-management-selct .css-15k3avv.select__menu::-webkit-scrollbar{
  width: 4px;
}
.user-management-selct .css-15k3avv.select__menu::-webkit-scrollbar-thumb{
  background-color: #31CCBC;
}

.user-management-selct  .css-xp4uvy{
  color: #4A4A4A;
  font-family: 'open-sans-regular';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}
.user-management-selct .css-15k3avv div div{
background-color: #FFF;
width: 243px;
margin-top: 10px;
border-bottom: 0.1px solid #eeeeee;
margin-left: 10px;
color: #4A4A4A;
font-family: 'open-sans-semibold';
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.35px;
cursor: pointer;
}

.user-management-selct .css-15k3avv div div:hover{
  color: #31CCBC;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -0.35px;
  font-family: 'open-sans-extrabold';
  cursor: pointer;
}
.user-management-selct .css-xp4uvy.select__single-value{
padding-bottom: 15px;
}
.user-management-selct .css-1g6gooi{
color: #4A4A4A;
padding-bottom: 15px;
padding-left: -5px;

}
.user-management-selct .css-2o5izw.select__control.select__control--is-focused{
  border: none;

}
.user-management-selct .css-2o5izw.select__control.select__control--is-focused .css-1hwfws3.select__value-container{
  margin-left: -5px;

} 

.user-management-selct .css-1492t68.select__placeholder{
  margin: 0px;
}
.user-management-selct .css-vj8t7z.select__control{
  margin-left: 23px;
}
.no-data{
  text-align: center;
  margin-top: 40px;
}
#my-account-details-modal{
  .title{
    color: #00CDAC;
    font-family: 'open-sans-extrabold';
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
      text-align: center;
      margin-top: 10px;
      text-transform: capitalize;
  }
  .sub-title{
    color: #9B9B9B;
    text-align: center;
    font-family: 'open-sans-regular';
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  #user-managment-pop-up{
    height: 600px;
  }
}
#billing-month{
padding-top: 20px;
cursor: pointer;
}
.billing-amount{
  margin-bottom: 8px;
}
#user-management-pop-up{
  .modal-content{
    position: relative;
  }
  .logo{
    margin-top: 15px;
  }
}
.remove-user-icons{
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
}
.add-staff-popup{
  margin-top: 60px;
}
.payment-success-btn{
  display: none;
}