.createpass {
  text-align: left;
}
.createpass .form-group .display_row {
  display: block !important;
}
.createpass .form-label {
  text-align: left;
}
.createpass input[type=checkbox] {
  margin-right: 15px;
  border-radius: none;
  -ms-transform: scale(1.4);
  -moz-transform: scale(1.4);
  -webkit-transform: scale(1.4);
  -o-transform: scale(1.4);
  position: relative;
  top: -2px;
}
.createpass span.txtradio {
  font-size: 18px;
}
.createpass input[type=text],
.createpass input[type=password] {
  background: transparent;
  box-shadow: none !important;
  border-bottom: 1px solid #ddd;
  border-radius: 0px !important;
}
.createpass .email {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

