@use '../../style/color.scss' as * ;
@import "../../style/font.css";

.my-account {
  max-width: 100%;

  .view-edit {
    // display: flex;
    flex-direction: column;

    // position: absolute;
    // top: 0;
    // right: -88px;
    span {
      cursor: pointer;
      height: 40px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      font-family: open-sans-semibold;
      font-size: 18px;
      color: $color_4A90E2;
    }
  }

  .avatar-image {
    width: 146px;
    height: 146px;
    max-width: 100%;
    object-fit: cover;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  }

  .title-large {
    height: 70px;
    color: #31CCBC;
    font-family:'open-sans-extrabold';
    font-size: 25px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }

  .btn-choose-file {
    text-decoration: none;
    color: #00CDAC;
  text-align: center;
  font-family: 'open-sans-bold';
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
    display: flex;
    justify-content: center;
  }

  .member-view {
    padding: 40px;
  }
 

  .col-sm-12,
  .col-md-12,
  .col-sm-6,
  .col-md-6 {
    padding: 0;
  }

  // .form-create-task{
  //   max-width: 390px;
  //   margin: 0 auto;
  //   padding:  70px 30px 0 40px;
  // }
  .icon-eye {
    right: 0;
  }

  .form-control-feedback {
    right: 20px !important;
  }

  .btn-save-account {
    position: absolute;
    right: 0;
    top: 20px;
    height: 30px;
    width: 100px;
    border-radius: 5px;
    line-height: 0;
  }

  .txt-subscription {
    color: $color_4A4A4A;
    font-family: open-sans-semibold;
    position: relative;
    font-size: 18px;
    height: 50px;
    max-width: 390px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;

    span {
      color: $color_4A90E2;
      text-align: right;
      position: absolute;
      cursor: pointer;
      width: 335px;
      right: -88px;
    }
  }

  .scroll {
    padding: 70px 30px 0 40px;
  }

  .view-upload {
    width: 184px;
    max-width: 50%;
    padding: 0 20px;
  }


  .form-control {
    line-height: 40px;
    max-height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
    padding: 0;
    margin-left: 30px;
    font-size: 18px;
    border: none !important;
    border-color: transparent !important;
    box-shadow: none !important;
  }

  .form-input {
    width: 100%;
    padding: 0 0 0 10px !important;
  }
}

.root-popup-change-plan {
  margin-top: 60px !important;
}

#popup-change-plan {
  .modal-dialog {
    margin: 0;
  }
#my-account-popup-body{
  height: 407px;
  padding-top: 40px;
}
#my-account-popup-body-2{
  height: 485px;
  padding-top: 40px;
}
.my-account-modal{
  .modal-content{
    padding: 0px;
  }
}
  .popup-change-profile {
    margin: 0 !important;
  }

  .btn-confirm {
    width: 188px;
    height: 44px;
    margin: 20px 0 12px 0;
    color: #FFF;
    text-align: center;
    font-family: 'open-sans-semibold';
    font-size: 13.681px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .form-control {
    display: block;
    width: 345px;
    max-width: 341.59px;
    padding: 6px 12px;
    font-size: 15px;
    line-height: 1.42857143;
    border: none;
    border-bottom: none !important;
    border-radius: 4px;
    background-color: #f6f6f6 !important;
    transition: none;
    color: #4A4A4A;
    height: 48px !important;
  }

  .icon-member {
    max-width: 227px;
    max-height: 60px;
    margin: 20px 0;
    object-fit: contain;
    width: 100%;
  }

  .modal-dialog,
  .modal-content,
  .modal-body {
    // width: 100% !important;
    max-height: none;
    overflow-y: hidden !important;
  }

  .modal-body {
    padding: 20px 0;
  }

  .col-sm-4,
  .col-sm-4,
  .col-sm-12 {
    padding: 0;
    margin: 0;
  }

  .title1 {
    color: #4A4A4A;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 20px;
    white-space: pre-line;
    font-family: 'open-sans-semibold';
    
  }
  .image-container {
    display: flex;
    margin-left: 54%;
  }
  .image-card.small{
    width: 40px;
    height: auto;
    object-fit: contain;
    margin: 0 0 0 6px;
    padding: 0;
    box-shadow: none;
  }
  .image-card{
    border:1px solid transparent;
    width: 101px;
    height: 68px;
    -o-object-fit: contain;
    object-fit: contain;
    margin: 0 25px;
    padding: 5px 23px;
    box-shadow: 2px 2px 8px rgba(0,0,0,0.5);
    border-radius: 7px;
  }
  .title {
    font-size: 22px;
    line-height: 32px;
    margin: 20px 0px;
    display : block;
    font-weight: 700;
    font-style: normal;
    line-height: normal;
    font-family: 'open-sans-bold';
  }
  .logo-pay{
    width: 70.75px;
    padding-bottom: 20px;
    //height: 14%;
  }
  .register-payment{
    letter-spacing: -1px;
    .formRegister{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 40%;

    }
    .image-card.small{
      width: 40px;
      height: auto;
      object-fit: contain;
      margin: 0 0 0 6px;
      padding: 0;
      box-shadow: none;
    }
    .image-card{
      border:1px solid transparent;
      width: 101px;
      height: 68px;
      -o-object-fit: contain;
      object-fit: contain;
      margin: 0 25px;
      padding: 5px 23px;
      box-shadow: 2px 2px 8px rgba(0,0,0,0.5);
      border-radius: 7px;
    }
  }
  .plan-view {
    width: calc(100%/3 - 20px);
    margin: 0 10px;
    padding: 30px 0;
    border-radius: 7px;
  }

  .txt-price {
    font-family: Avenir;
    font-size: 24px;
    font-weight: bold;
    color: $color_def;
    margin-bottom: 20px;
  }

  .login_btn_login {
    margin-top: 60px;
    width: 184px;
    height: 42px;
    max-width: 80%;
    color: #FFF;
    text-align: center;
    font-family: 'open-sans-semibold';
    font-size: 13.681px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    &.current {
      color: $color_4A90E2 !important;
      margin: 30px auto 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.popup-change-profile {
  height: 100%;

  .modal-content {
    height: 100%;
  }

  .modal-body {
    padding: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.disabled {
  cursor: not-allowed;
}

.price_team_count.disabled:hover {
  background-color: #ffffff;
  color: #595959;
}

.start_now_btn.disabled,
.start_now_btn.disabled:hover {
  background-color: #595959;
  color: #ffffff;
}

//change Plan Toggle

.your_price_section .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.your_price_section .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.your_price_section .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.your_price_section .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.your_price_section input:checked+.slider {
  background-color: #2196F3;
}

.your_price_section input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

.your_price_section input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.your_price_section .slider.round {
  border-radius: 34px;
}

.your_price_section .slider.round:before {
  border-radius: 50%;
}

.team-size-update.row {
  margin: 0 5%;
}

.team-size-notes {
  height: 66px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #595959;
}

.team-size-title {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 20.25px;
  line-height: 28px;
  letter-spacing: -1.125px;
  text-align: center;
  text-transform: capitalize;
  color: #31CCBC;
}

.team-content {
  margin: 0 54px;
  margin-top: 35px !important;
  margin-bottom: 15px !important;
}
.price_team_count{
  background-color: #F9F9F9;
}
.back-to-team img {
  width: 10px;
  height: 18px;
  margin-right: 13px;
}

.team-content .team-size-notes {
  margin-top: 17px;
  font-family: open-sans-regular;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #595959;
}
.payment-btn{flex-wrap: wrap;}
.payment-btn > div{
  flex: 0 0 100%;
}

.back-to-team {
  line-height: 18px;
}

.current-team h2,
.current-team h2 {
  font-style: normal;
  font-weight: 800;
  font-size: 20.25px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -1.125px;
  text-transform: capitalize;
  color: #31CCBC;
  max-width: 195px;
  margin: 0 auto;
  margin-bottom: 30px
}

.team-count h3 {
  font-family: 'Avenir', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 30.6px;
  line-height: 42px;
  text-align: center;
  letter-spacing: -1.7px;
  color: #595959;
}

.team-value h3 {
  font-family: 'Avenir', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 30.6px;
  line-height: 42px;
  text-align: center;
  letter-spacing: -1.7px;
  color: #595959;
}

.current-team .or {
  font-family: 'Futura', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20.25px;
  line-height: 27px;
  text-align: center;
  letter-spacing: -1.125px;
  text-transform: uppercase;
  color: #31CCBC;
  float: left;
  width: 100%;
}

.current-team .price_team_count_number {
  font-family: 'Avenir', sans-serif;
  font-style: normal;
  font-weight: 800 !important;
  font-size: 30.6px;
  line-height: 42px;
  text-align: center;
  letter-spacing: -1.7px;
  color: #595959;
}
.current-team.team-size h2{
  max-width: 100%;
}
.current-team .error{
  margin-top: 10px;
}
.current-team .price_team_count_active .price_team_count_number {
  color: #fff;
}

.current-team .price_team_count,
.current-team .price_team_count_active {
  width: 55px;
  height: 55px;
}

.current-team .exact-team-size {
  font-family: 'Avenir', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13.95px;
  line-height: 19px;
  text-align: center;
  letter-spacing: -0.775px;
  color: #717171;
  float: left;
  width: 100%;
}

.main .pricing_section {
  min-height: calc(100vh - 95px);
  background-color: #fff;
  margin-bottom: 20px;
}

.current-team .price_input {
  background-color: #F9F9F9;
}

.current-team .price_input_section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  float: left;
  width: 100%;
}

.plan-svg {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.plan-svg .svg {
  width: 130px;
}

.price_text_monthly {
  font-family: 'Avenir', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 22.5px;
  line-height: 31px;
  text-align: center;
  color: #595959;
}
.current-team .confirm{
  display: flex;
  justify-content: center;
  margin: 80px 0;
}
.current-team .confirm-btn {

  background: #31CCBC;
  box-shadow: 0px 3.2px 6.4px rgba(0, 0, 0, 0.2);
  border-radius: 1.56px;
  font-family: 'Avenir', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  border: none;
  color: #FFFFFF;
  padding: 14px 50px;
}

.current-team .confirm-btn.disabled
{
  background: #cdcdcd;
}

.my-team .account-details {
  display: block;
  // padding-top: 25px;
  // padding-left: 0;
  // margin-bottom: 50px;
  margin-top: 37px;
}

.my-team .account-details .form-label,
.my-account .form-control {
  font-family: 'open-sans-semibold';
}

.my-account .view-edit {
  flex-direction: column;
  display: flex;
  flex: 0 0 18%;
}

.my-account .title-large {
  height: auto;
  padding-bottom: 15px;
  margin-top: 44px;
}

.my-account .view-upload {
  width: 200px;
  max-width: 100%;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.my-account .profile {
display: flex;
    justify-content: start;
    gap: 19px;
}

.my-account .txt-subscription {
  max-width: 100%;
  height: auto;
  margin-bottom: 47px;
  justify-content: flex-start;
}
.my-account .txt-subscription .sub-value{
  margin-left: 15px;
  position: static;
  text-align: left;
  color: #4A4A4A;
  font-family: open-sans-semibold;
  position: relative;
  font-size: 18px;
}
.my-account .txt-subscription span {
  right: 0;
}
.my-account .subscription .title-large{
  margin-bottom: 71px;
}

.my-account .txt-subscription span {
  text-align: left;
  width: 43%;
}
#popup-change-plan .title1, #popup-change-plan .register-payment input, #popup-change-plan .button_display_row button{
  font-family: 'open-sans-semibold';
}
#popup-change-plan .title1{
  font-family: 'open-sans-semibold' !important;
}
.modal-open .team-size-popup .modal-content{
  padding: 73px 145px !important;
  width: 100% !important;
  max-width: 912px !important;
}
.modal-open .team-size-popup .modal-dialog{
  width: 100% !important;
  max-width: 912px !important;
}
.modal-open .team-size-popup .modal-para{
  color: #4A4A4A;
  text-align: center;
  font-family: open-sans-regular;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.48px;
}
.modal-open .team-size-popup .logo {
  width: 70.754px;
  height: 63.998px;
  flex-shrink: 0;
  object-fit: contain;
}
.modal-open .team-size-popup .login_btn_login{
  max-width: 188px;
  color: #FFF;
  text-align: center;
  font-family: open-sans-regular;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.5px;
}
.modal-open .team-size-popup .modal-footers{
  display: flex;
  justify-content: space-around;
  border: none;
  padding: 0;
  margin: 0;
}
// .popup-change-profile .edit-name, .popup-change-profile .edit-company-name, .popup-change-profile .edit-email{
//   margin-bottom: 52px !important;
// }
.my-account .txt-subscription span.change-method {
  width: 60%;
  right: -100px;
}
.payment-btn{
  display: flex;
  justify-content: center;
  gap: 0 47px;
}
.my-account .view-edit span{
  font-size: 18px;
  font-weight: 600;
  font-family:"open-sans-regular";
}

.my-account .form-control{
  color: #4A4A4A;
  font-family: 'open-sans-semibold';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.my-account .btn-choose-file{
  color: #00CDAC;
  text-align: center;
  font-family: 'open-sans-bold';
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  text-transform: capitalize;
}
#popup-change-plan{
right: 0px;
    top: 30px;
}
.popup-change-plan{
  right: 0px;
}
#changing_logo{
  top: 30px;
  position: relative;
}
.my-account-close-icon{
  position: absolute;
  top: 22px;
  right: 16px;
  cursor: pointer;
}




// .profile{
//   padding-left: 0px !important;
// }
.shadow_popup {
  box-shadow: 0 .5rem 1rem rgb(255, 255, 255) !important;
}
.content{
font-family: 'open-sans-semibold';
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 21px;
}
.about-user-companys .details{
  display: flex;
  align-items: baseline;
}
.about-user-companys .details span{
  color: #31CCBC;
  font-family: 'open-sans-semibold';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  width: 199px;
}
.about-user-companys .details div{
  width: 312px;
  color: #4A4A4A;
  font-family: 'open-sans-semibold';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  white-space: nowrap;  
  overflow: hidden;  
  text-overflow: ellipsis;
  text-transform: capitalize;

}
.about-user-companys .details .email-text{
text-transform: none;
}
.about-user-companys .details .edite-details{
  color: #F08274;
  width: 100px;
text-align: center;
font-family: 'open-sans-extrabold';
font-size: 12px;
font-style: normal;
font-weight: 800;
line-height: normal;
cursor: pointer;
}
.profile .lazy-image__container{
  width: 150px;
  height: 150px;
  flex-shrink: 0;
  border-radius: 15px;
}
.my-account .btn-choose-file .change-logo-input{
  top: -22px;
  cursor: pointer;
}
