@use '../../style/color.scss' as * ;
@import "../../style/font.css";

.welcome-view{
  background-color: transparent !important;
  //padding: 70px 30px 10px 90px;
  width: 100%;
  margin: 0 !important;
  .title-small{
    font-family: 'open-sans-regular';
    font-size:16px;
    line-height:22px;
    width:100%;
    margin-top:20px;
    color: $color_4A4A4A;
    max-width: 80%;
  }

  .step-item{
    margin-bottom: 30px;
    //display: flex;
  }

  .btn-upload, .btn-save-pdf{
      width: 129px;
    height: 32px;
    color: white;
    font-family: 'open-sans-semibold';
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    margin: 35px 0 0;
    border-radius: 3px;
    position: relative;
    padding: 0;
    .upload{
      position: absolute;
      width: 100%;
      height: 100%;
      background: red;
      left: 0;
      top: 0;
      opacity: 0;
      cursor: pointer;
    }
  }

  .img-logo{
    position: absolute;
    top:0px;
    right:10px;
    width: 92px;
    height: 184px;
  }

  .btn-save-pdf{
    cursor: pointer;
    margin: 0;
    min-height: 30px;
  }

  .col-sm-12, .col-sm-8, .col-sm-6, .col-md-12, .col-md-8, .col-md-6{
    padding: 0;
  }

  .list-item{
    margin-top: 70px;
  }

  .upload-pdf{
    min-height: 400px;
    border: 1px dashed;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
    margin-top: 40px;
    margin-bottom: 25px;
    position: relative;
  }
  .list-pdf-success{
    margin-top: 30px;
    z-index: 3;
    .icon-pdf{
      max-width: 600px;
      background-color: white;
      height: 40px;
      min-height: 40px;
      margin: 0 0 10px 0;
      cursor: pointer;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
      border: 1px solid $color_C7C7C7;
      font-family: open-sans-regular;
      font-size: 15px;
      height: auto;
      span{
        //display: block;
      }
      .txt-remove-pdf{
        color: $color_D0021B;
        font-size: 16px;
      }
    }
  }

  .txt-remove{
    font-size: 15px;
    line-height: 21px;
    color: $color_D0021B;
    position: absolute;
    right: 40px;
    cursor: pointer;
  }

  .scroll{
    padding:70px 30px 100px 90px;
  }

  .avatar-image{
    width: 264px;
    max-width: 100%;
    object-fit: contain;
    border-radius: 4px;
  }

  .input-title{
    max-width: 190px;
    height: 30px;
    font-size: 11px;
    line-height: 30px;
    font-family: open-sans-semibold;
    color: $color_4A4A4A;
    border-radius: 5px;
    border: none;
    background: #cdcdcd2e;
    padding: 0 10px;
    margin: 25px 0 18px 0;
  }

  .text-area-content{
    outline: none;
    height: 320px;
    padding: 35px 30px;
    border: none;
    color: $color_4A4A4A;
    border-radius: 5px;
    background: #cdcdcd2e;
    font-size: 14px;
    line-height: 19px;
    font-family: open-sans-semibold;
    resize: none;
    width: 100%;
  }

  .text-index{
    font-size: 14px;
    line-height: 19px;
    font-family: open-sans-semibold;
    color: $color_def;
    position: relative;
  }

  .tab-view{
    justify-content: flex-start;
    border:none !important;
    margin-top:30px !important;
    //width: calc(100% - 190px);
    //min-width: 347px;
    //max-width: 500px;
    .tab-item{
      font-size: 21px;
      line-height: 28px;
      color: $color_black;
      border-bottom: 3px solid transparent;
      border-top: 3px solid transparent;
      cursor: pointer;
      font-weight: 400;
      height: 40px;
      white-space: pre;
      font-family: open-sans-regular;
      &:first-child{
        margin-right: 100px;
      }
    }
    .active{
      border-bottom-color: $color_def;
      font-family: open-sans-bold;
    }
  }
}