@use '../../style/color.scss' as * ;
@import "../../style/font.css";

.shift-view{
  font-family: open-sans-semibold;
  .create-shift{
    color: $color_def;
  }
  .back-to-roster{
    cursor: pointer;
    color: $color_31CCBC;
    font-size: 15.3px;
    line-height: 19px;
    margin-left: 10px;
    font-family: open-sans-semibold;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    img{
      width: 14px;
      height: 23px;
      margin-right: 10px;
    }
  }
  .shift-form{
    padding: 80px 20px 25px !important;
  }

  .shift-item{
    width: 306px;
    height: 80px;
    border-radius: 3px;
    background-color: $color_white;
    margin-bottom: 15px;
    padding: 10px;
    align-items: center;
    position: relative;
    p{
      margin: 5px;
      font-size: 16px;
      line-height: 20px;
      font-family: open-sans-semibold;
      color: $color_4A4A4A;


    }
    .color-box{
      width: 56px;
      height: 50px;
      margin-right: 10px;
      border-radius: 3px;
      font-size: 9px;
      display: flex;
      align-items: center;
    }
    .btn-view{
      position: absolute;
      right: 10px;
      text-align: left;
      p {
        font-size: 12.8px;
        line-height: 16px;
        color: red;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
        text-rendering: optimizeLegibility !important;
        -webkit-font-smoothing: antialiased !important;

        &:hover {
          cursor: pointer;
        }
      }
    }
    .hours-view{
      color: $color_4A4A4A;
      font-size: 18px;
      line-height: 25px;
      text-align: left;
      font-family: open-sans-semibold;
    }
    .hours-time{
      font-size: 14.4px;
      line-height: 18px;
      color: $color_9B9B9B;
      font-family: open-sans-semibold;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
      text-rendering: optimizeLegibility !important;
      -webkit-font-smoothing: antialiased !important;
    }
  }

  .btn-linear-invite{
    bottom:0;
    margin-top: 53px;
    position: initial;
    border-radius: 25px;
    font-family: 'open-sans-regular';
  }

  .color-view{
    margin-top: 10px;
  }
  .color-div{
    width: 40px;
    height: 40px;
    cursor: pointer;
    margin: 1px;
    font-size: 9px;
    display: flex;
    align-items: center;
  }
  .create-shift-specific .display_row.form-input.align-center {
    width: 140px;
  }
  .form-create-shift{
    .MuiStack-root .shift-time-picker{
      display: none;
    }
    .form-group {
      margin-bottom: 53px;
      &.has-error {
        input {
          border-color: #FF9494;
          &::placeholder {
            color: #FF9494
  
          }
        }
      }
    }
    display: flex;
    flex-wrap: wrap;
    width: 335px;
    justify-content: space-around;
    .form-label{
      font-family: open-sans-semibold;
      width: auto;
      font-size: 19px;
      line-height: 24px;
      font-weight: 100;
      text-align: start !important;
      color: $color_4A4A4A;
      padding-left: 0 !important;
    }
    .form-input{
      width: 100%;
    }
    .rc-time-picker input{
      text-align: center;
      width: 50% !important;
    }
    .rc-time-picker-input1{
      border: none;
      border-radius: 0;
      border-bottom: 1px solid $color_C4C4C4;
      color: $color_9B9B9B;
      font-size: 19px;
      line-height: 24px;
      text-align: center;
      font-family: open-sans-semibold;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
      text-rendering: optimizeLegibility !important;
      -webkit-font-smoothing: antialiased !important;
      padding: 4px 0;
      &.has-error {
        color: #FF9494;
        border-color: #FF9494;
      }
      &.none-has-error.has-error {
        border-color: $color_C4C4C4;
        color: $color_9B9B9B;
      }
    }
    .rc-time-picker-input{
      border: none;
      border-radius: 0;
      border-bottom: 1px solid $color_C4C4C4;
      color: $color_9B9B9B;
      font-size: 19px;
      line-height: 24px;
      text-align: center;
      font-family: open-sans-semibold;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
      text-rendering: optimizeLegibility !important;
      -webkit-font-smoothing: antialiased !important;
      padding: 4px 0;

      &.has-error {
        color: #FF9494;
        border-color: #FF9494;
      }
      &.none-has-error.has-error {
        border-color: $color_C4C4C4;
        color: $color_9B9B9B;
      }
    }
    .form-control{
      background: transparent;
      border: 1px solid $color_C4C4C4;
      border-width: 0 0 1px 0;
      height: 40px;
      line-height: 40px;
      border-radius: 0;
      padding: 0;
      outline: none !important;
      box-shadow: none !important;
    }
    :first-child{
      width: 100%;
      font-size: 19px !important;
    }
    :not(:first-child){
      width: 48%;
      .display_row{
        flex-direction: column;
        margin: 0 0;
      }
    }
  }
  .MuiInputAdornment-sizeMedium {
    display: none;
}
  .shift-select-color {
    font-family: open-sans-semibold;
    font-size: 15px;
    line-height: 19px;
    color: $color_4A4A4A;
  }
  .txt-condensed-extra-bold {
    color: $color_31CCBC;
  }
  
}
.form-create-shift .MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.time-shift .form-group{
  width: 100% !important;
}
.shadow_popup {
  box-shadow: 0 .5rem 1rem rgb(255, 255, 255) !important;
}
.back-to-page{
  cursor: pointer;
  z-index: 9;
  }