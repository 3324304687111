.date-time-picker {
  position: relative;
  font-size: 16px;
  width: 100%;
  background: transparent;
}
.date-time-picker input {
  height: 40px;
  border-color: #C4C4C4;
  max-width: 100% !important;
  box-shadow: none;
  padding: 0 6px;
  margin: 0 !important;
  color: #333;
}
.date-time-picker .fa-calendar {
  position: absolute;
  right: 0;
  top: 12px;
  font-size: 14px;
  color: gray;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-right: 8px !important;
}
.date-time-picker #popover-date {
  width: 200px !important;
}
.date-time-picker .DayPicker-Weekday {
  padding: 0.8rem;
}

.popover {
  width: 236px !important;
}

.popover-date-time {
  width: 295px !important;
  max-width: 295px !important;
}

@media screen and (max-width: 768px) {
  .send-message-form .date-time-picker input {
    font-size: 14px;
  }
}

