@import "../../style/font.css";
.terms {
  margin: 0 !important;
  padding-top: 30px;
  background: white;
  display: inline-table;
  max-width: 100% !important;
}
.terms .title-small {
  font-family: open-sans-regular;
  font-size: 14px;
  color: #9B9B9B;
  line-height: 28px;
  text-align: left;
}
.terms .icon-help {
  width: 40px;
  object-fit: contain;
  margin-right: 20px;
}
.terms .row-hep {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

