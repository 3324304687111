@use '../../style/color.scss' as * ;
@import "../../style/font.css";

.message-view{
  background: transparent;
  .load-more{
    z-index: 10;
    position: absolute;
    // bottom: -15px;
    left: 0;
    width: calc(100% - 30px);
    text-align: center;
    padding: 5px 15px;
    margin: -10px 15px;
    display: flex;
    justify-content: center;
    // background-image: linear-gradient(to right, transparent, lightgray, transparent);
  }
  .list-chat{
    width: 400px;
    max-width: 30%;
    padding:10px !important;
    margin-right: 10px;
    font-family: open-sans-regular !important;
    .input-search{
      width: calc(100% - 90px);
      border-radius: 10px;
      padding: 15px;
      background-color: $color_F9F9F9;
      border: none;
      font-size: 16px;
      color: $color_C4C4C4;
      height: 48px;
    }
    .text-cancel{
      width: 68px;
      margin-left: 22px;
      font-family: Avenir-Medium;
      font-size: 20px;
      line-height: 27px;
      color: $color_FF001F;
      background-color: transparent;
      border: none;
      cursor: pointer;
    }
  }
  .new-message{
    width: 100%;
    padding: 10px 10px 20px;
    white-space: pre;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #000;
    font-family: "open-sans-regular";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 20px;
    cursor: pointer;
    .img-plus{
      //border: 1px solid $color_4A90E2;
      //border-radius: 100px;
      width: 19px;
      min-width: 19px;
      height: 19px;
      margin-left: 10px;
      //display: flex;
      //align-items: center;
      //justify-content: center;
      //background-color: $color_4A90E2;
      //color:white;
      //font-size: 19px;
    }
  }
  .member-chat{
    width: 380px;
    height: 80px;
    border-radius: 8px;
    padding: 10px;
    max-width: 95%;
    cursor: pointer;
    margin-bottom: 15px;
    background-color: transparent;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    .member-chat-name{
      font-size: 17px;
      line-height: 23px;
      width: 240px;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre;
    }
    .member-chat-title{
      font-size: 14px;
      height: 23px;
      width: 240px;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre;
      color: $color_9B9B9B;
    }
    &.active{
      background-color: $color_def;
      color: $white !important;
      -webkit-transition: all 0.5s ease-in-out;
      -moz-transition: all 0.5s ease-in-out;
      -o-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
      .member-chat-title {
        color: white !important;
      }
      .member-chat-name{
        color: white !important;
      }
    }
    img{
      border-radius: 100px;
      min-width: 61px;
      height: 63px;
      width: 63px;
      object-fit: cover;
      margin-right: 22px;
    }
    .chat-number-badge{
      background-color: $color_4A90E2;
      border-radius: 100px;
      font-size: 10px;
      line-height: 10px;
      color: $white;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .chat-view{
    padding: 20px !important;
    width: 100% !important;
    min-width: 70% !important;
    max-width: calc(100% - 400px) !important;
    .list-message{
      max-height: calc(100% - 166px) !important;
      padding: 15px;
      height: 100%;
      //display: flex;
      align-content: flex-end;
      flex-wrap: wrap;
    }
    .list-message::-webkit-scrollbar{
      width: 3px;
      border-radius: 10px;
      background-color: white;
    }
    .list-message::-webkit-scrollbar-thumb{
      background-color: #bfc0c0;
      border-radius: 10px;  
      
    }
  
    .member-info{
      position: relative;
      flex-wrap: wrap;
      grid-gap: 15px;
      display: flex;
      padding-top: 13px;
      max-height: calc(100% - 455px);
      .member-chat{
        width: 100% !important;
        max-width: 100% !important;
        flex: 0 0 22%;
        .member-chat-name, .member-chat-title{
          width: 100%;
        }
      }
      hr{
        width: 95%;
        margin: 10px auto 10px auto;
        border: 0.5px solid $color_DFDFDF !important;
      }
    }
    .content-chat{
      width: 100%;
      margin: 20px 0 50px 0;
      height: fit-content;
      img{
        border-radius: 100px;
        width: 48px;
        height: 48px;
      }
      .content-text{
        margin: 0 25px;
        border-radius: 12px 12px 12px 0;
        padding: 13px;
        background-color: $color_EBEBEB;
        min-width: 140px;
        position: relative;
        font-size: 13px;
        font-weight: 500;
        line-height: 18px;
        word-break: break-word;
        font-family:"open-sans-regular"!important;
        color: #FFF;
        .time-chat{
          position: absolute;
          font-size: 8px;
          line-height: 15px;
          right: 10px;
          bottom: -13px;
          color: $color_grey;
        }
      }
      &.is-me{
        display: flex;
        flex-direction: row-reverse;
        .content-text {
          border-radius: 12px 12px 0px 12px;
        }
        .time-chat {
          color: #FFF;
        }
      }
      &.is-me .content-text{
        background-color: $color_def;
        color: $white;
      }
    }
  }
  .stick-to-bottom{ 
    padding: 0px !important;
    width: 100% !important;
    min-width: 70% !important;
    max-width: 100% !important;
  
    .chat-box{
      padding: 0;
      position: relative;
      textarea{
        max-height: 100px;
        border: none;
      }
    }
  }
  .message-loading{
    position: absolute;
    top: -35px;
    left: 0;
  }
  .chat-box{
    height: 87px;
    width: 98%;
    position: relative;
    padding: 15px;
    textarea{
      width: 102%;
      height: 100%;
      border-radius: 5px;
      outline: none;
      border-color: $color_DDDCDC;
      font-size: 13px;
      line-height: 18px;
      padding: 10px;
      resize: none;
      background-color:#F4F4F4;
      border: none;
      margin-top: -13px;
      margin-left: 2px;
    }
    span{
      position: absolute;
      right: 19px;
      top: 41px;
      bottom: 20px;
      font-size: 13px;
      line-height: 18px;
      color: $color_9B9B9B;
    }
  }
  .body_container{
    padding-top: 110px !important;
  }
}

.roster-view.message-view .list-chat {
  margin-top: 10px !important;
}
.member-chat-role{
  color: #ff0000;
  font-size: 14px;
  text-transform: lowercase;
}
.member-chat-title{
  text-transform: capitalize;
  color: #9B9B9B;
  font-family:  "open-sans-regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 3px;
}

.member-chat-name{
  color: #4A4A4A;
  font-family: "open-sans-regular";
  font-size: 17px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
  text-transform: capitalize;
}
// .chat-screen{
//     font-family: open-sans-regular !important;
//     font-size: 15px;
//     font-weight: 600;
//     margin-top: 450px;
//     margin-left: 460px;
// }
 .create-group .btn-edit-group {
  position: absolute;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 206.8px !important;
  // height: 40px;
  border-radius: 4.8px;
  font-family:'open-sans-regular';
  font-weight: 500;
  top: -4px;
  left: 83px; 
}
  // .group-chat-close{
  //   background: #31CCBC;
  // }
  // .group-close{
  //   width: 12px;
  //   height: 11.86px;
  //   top: 0.07px;
  //   left: 0px ;   
  // }

  .list-border-bottom{
    border: 1px solid #eeeeee !important;
  }
  .group-images {
    display: flex;   
    overflow-x: hidden;
    flex: 0 0 33.3%;
 }
 .chat-member-name {
  flex: 0 0 58.33%;
  width: 100%;
  overflow: hidden;
  margin-left: 6px;
}
  .profile-images1{
    position: relative;
    right: 70px;
  }
  .profile-images2{
    position: relative;
    right: 130px;
  }
  .group-chat-close {
    position: relative;
    margin-right: 22px;
}
.message-view .member-chat .group-chat-close img{
  margin: 0;
}
.group-chat-close::before {
    content: url('../../assets/images/group-close.png') !important;
    width: 100%;
    height: 100%;
    background: #31CCBC;
    position: absolute;
    left: 0;
    opacity: 0.78;
    top: 0;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.not-started {
  width: 100%;
  height: calc(100% - 188px) !important;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  position: relative;
}
.not-started p {
  color: #31CCBC;
  text-align: center;
  font-family: 'open-sans-bold';
  font-size: 37px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  opacity: 0.1956;
  max-width: 520px;
  text-align: center;
}
.message-view .chat-view .list-message{
  padding: 10px;
  height: 100%;
  align-content: flex-end;
}
.message-view .chat-view .member-info{
  max-height: 100%;
  height: 60px;
}
.time-chat{
  top: 37px;
  left: 49px;
}
.memberChatList{
  width: 35px;
  height: 35px;
  border-radius: 35px;
}
.message-view .chat-box span{
  font-size: 13px;
  font-weight: 400;
  font-family: "open-sans-regular";
  color: #9B9B9B;
}
.listOfChat{
  margin-top: 36px;
  padding-left: 25px;
}

.listOfChat::-webkit-scrollbar{
  width: 3px;
  height: 20px;
  border-radius: 10px;
  background-color: white;
}
.listOfChat::-webkit-scrollbar-thumb{
  background-color: #bfc0c0;
  border-radius: 10px;  
  
}

.message-view .chat-view .content-chat .content-text{
  color: #000;
  text-align: start;
}
.message-view .chat-view .content-chat .content-text .time-chat{
  display: flex;
  justify-content: end;
  align-items: end;
}
.message-view .list-chat{
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 4px 0px;
  margin-bottom: 5px;
}
.message-view .chat-view {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 4px 0px;
  margin-bottom: 5px;
}
.search_bar{
  border-radius: 100px;
    color: #9B9B9B;
    background-color: Cream;
    font-size: 11px;
    height: 14px;
    width: 179px;
    max-width: 264px;
    margin: 2px;
    border: 1px solid #F9F6EF;
    padding: 15px;
    margin-top: 26px;
    font-family: "open-sans-regular";
}
.img-search{
  position: absolute;
  width: 15px;
  height: 15px;
  top: 178px;
  left: 159px;
}
.searchDiv{
  display: flex;
}
