.createpass{


  text-align: left;

  .form-group .display_row{
    display: block !important;
  }
  .form-label{
    text-align: left;
  }
  input[type="checkbox"]{
    margin-right: 15px;
    border-radius: none;
    -ms-transform: scale(1.4);
    -moz-transform: scale(1.4);
    -webkit-transform: scale(1.4);
    -o-transform: scale(1.4);
    position: relative;
    top: -2px;
  }
  span.txtradio{
    font-size: 18px;
  }
  input[type="text"],
  input[type="password"]{
    background: transparent;
    box-shadow: none !important;
    border-bottom: 1px solid #ddd;
    border-radius: 0px !important;
  }
  .email{
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    //background: #dddddd2b !important;
  }
}