@import "../../style/font.css";
@import "../my-team-detail/my-team-detail.css";
.feed-page {
  background: white;
  border-radius: 3px;
}
.feed-page .date-times {
  right: 6px;
  color: #4A4A4A;
  font-size: 10.8px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  font-family: "open-sans-bold";
  padding-top: 7px;
}
.feed-page .title {
  color: var(--Teemie-Green, #31CCBC);
  font-family: "open-sans-extrabold";
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  padding-bottom: 30px;
  padding-top: 65px;
  position: fixed;
  background-color: #fff;
  width: 100%;
  z-index: 1;
  margin-left: -2px;
}
.feed-page .title-left {
  color: var(--Teemie-Green, #31CCBC);
  font-family: "open-sans-extrabold";
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.feed-page .feed-name {
  font-family: "Helvetica", "Arial", sans-serif;
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 24px;
  color: #31CCBC;
  position: relative;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  width: 92%;
}
.feed-page .feed-name p {
  margin: 0px;
  color: #31CCBC;
  font-family: "open-sans-bold";
  font-size: 15.68px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.feed-page .feed-view {
  font-family: "Helvetica", "Arial", sans-serif;
  padding: 40px 60px 0px;
  /*margin: 40px 0 0 0 !important;*/
}
.feed-page .feed-view .img_left {
  width: 80px;
}
.feed-page .feed-view .img_left img {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  margin-top: 5px;
  left: 5px;
}
.feed-page .feed-view .img_left .ann_user_name {
  color: #31CCBC;
  margin-top: 3px;
  text-align: center;
  font-family: "open-sans-bold";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 68px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 5px;
  margin-top: 10px;
}
.feed-page .feed-view .right_content {
  word-break: break-all;
  margin-left: 15px;
}
.feed-page .feed-view .subheading {
  margin-top: 2px;
  padding-right: 90px;
}
.feed-page .feed-view .team-view {
  margin: 0px;
}
.feed-page .feed-view .team-view .short-descrition {
  color: #9B9B9B;
  overflow: hidden;
  position: relative;
  line-height: 20px;
  font-size: 17px;
  font-family: left;
  max-height: 3.6em;
  text-align: justify;
  padding-right: 1em;
  font-family: "open-sans-light";
  margin-right: 10px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}
.feed-page .feed-view .team-view .short-descrition:after {
  /* points in the end */
  content: "";
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of text */
  right: 0;
  /* set width and height */
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  /* bg color = bg color under block */
  background: white;
}
.feed-page .feed-view .team-view .short-descrition:before {
  /* points in the end */
  content: "...";
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of block */
  right: 0;
  bottom: 0;
}
.feed-page .feed-view .team-view .feed-information {
  padding: 1px 10px 10px 10px !important;
  margin: 0px 10px 0px -9px;
  border: none !important;
  margin-bottom: 15px;
}
.feed-page .feed-view .team-view .feed-information .info-in {
  border-radius: 5px;
}
.feed-page .shadow-1 {
  position: relative;
  box-shadow: 0px 2.4px 5.4px 0px #DDDCDC !important;
  resize: none;
}
.feed-page .add-comment-text {
  color: #4A4A4A;
  font-family: "open-sans-regular";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  height: 56px;
  // background-color: #FAFAFA;
  margin-right: 1px;
}
.feed-page .add-comment-text::placeholder {
  color: #4A4A4A;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}
.feed-page .input_name_announcement {
  width: 500px;
  height: 269px;
}
.feed-page span.like img {
  width: 15.06px;
  height: 13.44px;
}
.feed-page span.like:hover {
  cursor: pointer;
}
.feed-page span.delete img {
  width: 15.5px;
  height: 15.5px;
}
.feed-page span.delete:hover {
  cursor: pointer;
}
.feed-page span.Comment img {
  width: 15.02px;
  height: 15px;
}
.feed-page span.Comment:hover {
  cursor: pointer;
}
.feed-page .titleupload {
  font-family: "open-sans-bold";
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 0 !important;
}
.feed-page .titleupload img {
  width: 87.261px;
  height: 87.261px;
  border-radius: 7.153px;
  margin-right: 15px;
  position: relative;
  top: -1px;
}
.feed-page .button-view button {
  border: none;
  width: 175px;
  height: 35px !important;
  line-height: 12px !important;
  border-radius: 3px;
  text-align: center;
  color: #ffffff;
}
.feed-page .Button-view {
  width: 175px;
  float: right;
  justify-content: flex-end;
  margin-left: 27rem;
  margin-top: -80px;
}
.feed-page .Button-view Button {
  border: none;
  width: 175px;
  height: 35px !important;
  line-height: 12px !important;
  border-radius: 3px;
  text-align: center;
  color: #ffffff;
  margin-top: 24px;
}
.feed-page .upload_form {
  display: flex;
  align-items: flex-start;
  padding-top: 15px;
}
.feed-page .upload_form div {
  line-height: 45px;
  margin-top: 0px;
}
.feed-page .upload_form:after {
  content: "";
  display: block;
  clear: both;
}
.feed-page .full_w {
  height: 100%;
}
.feed-page input.uploadimg {
  position: absolute;
  top: 0px;
  opacity: 0;
  width: 100%;
}
.feed-page input.uploadimg_ava {
  height: 73%;
  width: 197px;
  position: absolute;
  bottom: 0px;
  opacity: 0;
}
.feed-page .avatar-image {
  width: 197px;
  height: 150px;
  object-fit: contain;
}
.feed-page .wrap-one {
  overflow-y: auto;
  overflow-x: hidden;
}
.feed-page .wrap-one::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
}
.feed-page .wrap-one::-webkit-scrollbar {
  width: 2px;
}
.feed-page .wrap-one::-webkit-scrollbar-thumb {
  background: #9B9B9B;
}
.feed-page .content-image-first {
  width: 100%;
  height: auto;
  max-width: 100%;
  height: auto;
  display: block;
  margin-top: 10px;
  margin-bottom: 18px;
  border-radius: 3.5px;
}
.feed-page .team-view.display_row:first-of-type .wrap_box {
  margin-top: 0px;
}
.feed-page .team-view.display_row:last-of-type .wrap_box {
  margin-bottom: 1px;
}
.feed-page .wrap_box {
  padding: 27px 20px 15px 20px;
  border-radius: 8px;
  margin-top: 30px;
  margin-bottom: -40px;
}
.feed-page .comment_box {
  padding: 20px 15px;
  border-radius: 8px;
  width: 547px;
  height: 180px;
  margin-top: 35px;
}
.feed-page .go-back {
  width: 100%;
  font-weight: 800;
  z-index: 1;
  line-height: normal;
  padding-top: 65px;
  padding-bottom: 18px;
  margin-left: -4px;
  background-color: white;
  position: fixed;
  margin-top: 0px !important;
  .go-back-section {
    width: 130px;
  }
}
.feed-page .go-back .go-back-button {
  color: var(--Teemie-Green, #31CCBC);
  font-family: "open-sans-extrabold";
  font-size: 16px;
  font-style: normal;
  width: 100px;
  display: inline-block;
  padding-top: 1px;
}
.feed-page ::-webkit-input-placeholder { /* Chrome */
  font-family: inherit;
  font-size: 16px;
  line-height: 19px;
  color: #9B9B9B;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}
.feed-page :-ms-input-placeholder { /* IE 10+ */
  font-family: inherit;
  font-size: 16px;
  line-height: 19px;
  color: #9B9B9B;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}
.feed-page ::-moz-placeholder { /* Firefox 19+ */
  font-family: inherit;
  font-size: 16px;
  line-height: 19px;
  color: #9B9B9B;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}
.feed-page :-moz-placeholder { /* Firefox 4 - 18 */
  font-family: inherit;
  font-size: 16px;
  line-height: 19px;
  color: #9B9B9B;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}

.announcement-information {
  color: #31CCBC;
  font-size: 20px;
  font-family: inherit;
  line-height: 24px;
  font-family: "open-sans-regular";
}

.announcement-feeds .feed-information .right_content {
  padding-right: 0;
  padding-top: 5px;
  right: 27.5px;
  margin-left: 15px;
}

.announcement-feeds .feed-information .right_content .feed-para p {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: "open-sans-regular";
  right: 27.5px;
}

.feed-paragrapgh {
  text-align: justify;
  color: var(--Teemie-Text, #4A4A4A);
  font-family: "open-sans-regular";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  margin-bottom: 10px;
}

.feed-paragrapgh-select-announcement {
  color: var(--Teemie-Text, #4A4A4A);
  font-family: "open-sans-regular";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  margin-bottom: 20px;
}

.feed-paragrapgh-commentbox {
  word-wrap: break-word;
  width: 290px;
  min-height: 120px;
  color: var(--Teemie-Text, #4A4A4A);
  font-family: "open-sans-regular";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 20px;
  margin-left: 15px;
}

.Announcement-content {
  width: 642px;
  height: 269px;
  border-radius: 6px;
  background: #FAFAFA;
  border: none;
  padding: 20px;
  color: #4A4A4A;
  font-family: "open-sans-regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 50;
}

.comment-content {
  margin-left: 15px;
  margin-bottom: 15px;
  width: 100%;
}

.third-one-image {
  width: 10px;
  height: 10px;
  margin-top: 10px;
}

.image-title {
  width: 97px;
  height: 97px;
  border-radius: 7px;
}

.image-text {
  font-size: 10px;
  color: #FFF;
  text-align: center;
  font-size: 8.11px;
  font-style: normal;
  font-weight: 700;
  margin-left: 1px;
  margin-top: -53px;
}

.title-titleupload {
  width: 97px;
  height: 97px;
}

.whole {
  width: 100%;
  height: 100vh;
}

.first-one {
  display: flex;
  border-radius: 5.6px;
  background: #FFF;
  padding: 20px 0 0 15px;
  border-radius: 8px;
}

.first-one-new {
  display: flex;
  min-height: 185px;
  border-radius: 5.6px;
  background: #FFF;
  box-shadow: 0px 2.4px 5.4px 0px #DDDCDC;
  padding: 20px 15px;
  border-radius: 8px;
  margin: 0px 20px 10px 0px !important;
}

.image-comment img {
  width: 100%; /* Ensures the image doesn't overflow */
  height: auto; /* Maintains aspect ratio */
  display: block; /* Removes extra spacing */
  margin-bottom: 18px;
}

.another {
  display: flex;
  gap: 50px;
}

.sub-heading {
  color: #31CCBC;
  font-family: "open-sans-bold";
  font-size: 15.68px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.text-area-para {
  width: 393px;
  color: var(--Teemie-Text, #4A4A4A);
  font-family: "open-sans-regular";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ava-member-comment {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  margin-top: 5px;
}

.input_content {
  width: 642px; /* Fixed width */
  height: 269px; /* Fixed height */
  resize: none; /* Prevent resizing */
  overflow: auto; /* Add scroll if content exceeds the height */
}

.ann_user_name_comment {
  color: #31CCBC;
  text-align: center;
  font-family: "open-sans-bold";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 68px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 5px;
  margin-top: 10px;
}

.date-times-comment {
  position: absolute;
  right: 70px;
  top: 0px;
  color: #4A4A4A;
  font-size: 13px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  font-family: "open-sans-bold";
}

.icons_feed {
  margin-top: 50px;
}

span.comment_icons {
  display: flex;
  padding: 0px 8px;
  gap: 10px;
  img {
    width: 15.5px;
    height: 15.5px;
  }
}

span.comment_icons:hover {
  cursor: pointer;
}

span.comment_icons_two {
  display: flex;
  justify-content: center;
  gap: 10px;
  bottom: -16px;
  .like-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .like-count {
      font-size: 10px;
      font-family: 'open-sans-regular';
      font-weight: 400;
      padding-top: 5px;
    }     
  }
}

span.comment_icons_two:hover {
  cursor: pointer;
}

.third-delete {
  width: 15.5px;
  height: 15.5px;
}

.third-like {
  width: 15.06px;
  height: 13.44px;
}

.first-one-like {
  width: 15.06px;
  height: 13.44px;
}

.time-stamp {
  color: #4A4A4A;
  font-family: "open-sans-bold";
  font-size: 10.8px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  align-items: end;
  padding-top: 10px;
}

.time-stamp-third {
  color: #4A4A4A;
  font-family: "open-sans-bold";
  font-size: 10.8px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  align-items: end;
  margin-right: 4px;
}

.second-one {
  display: flex;
  align-items: center;
  height: 185px;
  border-radius: 5.6px;
  background: #FFF;
  margin-top: 16px;
  padding: 20px 15px;
}

.second-one-div {
  flex-direction: column;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 94px;
  width: 80px;
}

.second-one-image {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  margin-top: 5px;
}

.arrow-image {
  width: 20px;
  height: 20px;
  margin-top: 44px;
  margin-left: 10px;
  margin-bottom: 0px;
}

.third-div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 60px;
}

.third-one-image {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  margin-top: 5px;
}

.comment-header-second {
  margin-top: 20px;
  font-size: 25px;
  text-align: center;
  margin-left: 10px;
}

.user-name {
  display: block;
  color: #31CCBC;
  text-align: center;
  font-family: "open-sans-bold";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 68px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 5px;
  margin-top: 10px;
}

.comment-time {
  font-size: 12px;
  color: gray;
}

.comment-title {
  color: #31CCBC;
  font-family: "open-sans-bold";
  font-size: 15.68px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 5px 0;
  margin-bottom: 10px;
  width: 90%;
}

.comment-text {
  font-size: 14px;
  color: #444;
}

.mentions {
  display: inline-flex;
  align-items: center;
  margin-left: 5px;
}

.mention-avatar {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  margin-left: -8px;
  border: 2px solid white;
}

.comment-actions {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.comment-actions i {
  font-size: 16px;
  margin-left: 10px;
  cursor: pointer;
  color: gray;
  transition: 0.3s;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  margin-top: 5px;
  left: 40px;
}

.comment-input-box {
  display: flex;
  background: white;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 600px;
  align-items: center;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

.user_image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-top: 10px;
}

.user-name-second {
  margin-top: 10px;
  color: #31CCBC;
  text-align: center;
  font-family: "open-sans-bold";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 68px;
  height: 19;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.comment-placeholder {
  width: 95%;
  height: 115px;
  border: none;
  border-radius: 6px;
  display: flex;
  padding: 20px;
  font-size: 12px;
  resize: none;
  background-color: #f6f6f6;
  outline: none;
  margin-left: 15px;
  margin-top: 15px;
  font-family: "open-sans-regular";
  font-weight: 400;
  color: #4A4A4A;
}

textarea.comment-placeholder::-webkit-scrollbar {
  width: 2px;
}

textarea.comment-placeholder::placeholder {
  font-size: 12px;
  color: #4A4A4A;
}

.post-button {
  color: var(--Teemie-Green, #31CCBC);
  text-align: right;
  font-family: "open-sans-extrabold";
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-top: 15px;
  cursor: pointer;
  margin-right: 15px;
  display: flex;
  justify-content: flex-end;
}

.third-one {
  display: flex;
  background: #FFF;
  padding: 20px 10px;
  border-radius: 8px;
}

.ann_user_name_comment_second {
  color: #31CCBC;
  margin-top: 10px;
  text-align: center;
  font-family: "open-sans-bold";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 48px;
  height: 19;
}

.comment-right-content {
  display: flex;
}

.add-comment-textarea {
  height: 269px;
  border-radius: 6px;
  background: #FAFAFA;
  border: none;
  margin-right: 2px;
  padding: 20px;
  padding-left: 14px;
  color: #4A4A4A;
  font-family: "open-sans-regular";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}

.add-comment-textarea::placeholder {
  color: #4A4A4A;
  font-family: "open-sans-regular";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}

.title-titleupload {
  margin-top: -20px;
  margin-left: 5px;
}

.title-left-comment {
  color: var(--Teemie-Green, #31CCBC);
  font-family: "open-sans-extrabold";
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.new-screen {
  display: flex;
  gap: 50px;
}

.add-comment-textarea {
  height: 269px !important;
  /* Set the desired height */
  resize: none; /* Optional: Prevent resizing */
  margin-top: -14px;
  background: #FAFAFA;
}

.add-comment-textarea-new {
  height: 269px !important;
  /* Set the desired height */
  width: 100%;
  resize: none; /* Optional: Prevent resizing */
  margin-top: 23px;
  background: #FAFAFA;
  border: none;
  margin-bottom: 24px;
  outline: none;
  padding-left: 20px;
  padding-top: 20px;
}

.add-comment-textarea:focus {
  outline: none;
  box-shadow: none;
  border: none;
  background-color: #fafafa;
}

.cancel-button {
  width: 185.372px;
  height: 37.213px;
  background-image: linear-gradient(135deg, #FFDEA7 0%, #EA6362 100%);
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  padding-left: 62px;
  padding-right: 62px;
  border-radius: 25px;

  color: #FFF;
  text-align: center;
  font-family: "open-sans-semibold";
  font-size: 13.681px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.confirm-button {
  width: 185.372px;
  height: 37.213px;
  background-image: linear-gradient(135deg, #00CDAC 0%, #6AD5E5 100%);
  padding: 10px;
  padding-left: 62px;
  padding-right: 62px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 25px;
  margin-left: 50px;
  color: #FFF;
  text-align: center;
  font-family: "open-sans-semibold";
  font-size: 13.681px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.register_error {
  margin-top: -36px;
}

.image-comment-new {
  width: 100%;
  height: 302px;
  max-width: 100%;
  height: auto;
  display: block;
  margin-top: 21px;
  margin-bottom: 18px;
}

.third-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 60px;
  gap: 34px;
}

.delete-like-icon .delete, .delete-like-icon .like {
  padding: 0px 6px;
}

.delete-like-icon {
  display: flex;
  .like-section-new {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .like-count {
      font-size: 10px;
      font-family: 'open-sans-regular';
      font-weight: 400;
      padding-top: 5px;
    } 
  }    
}


.confirm-announcement-button {
  margin-left: auto;
  margin-top: -25px;
}

.create-announcement-section {
  position: fixed;
  min-width: 38%;
}

.input-area {
  width: 100%;
}

.comment-content-third {
  width: 90%;
}

.post-comment-button {
  display: flex;
  justify-content: right;
  .menu-left-action {
    width: 140px;
  }
}

.add-comment-section {
  position: fixed;
  width: 38%;
}

textarea.add-comment-textarea-new::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0px;
  color: #4A4A4A;
}
.content_image {
  margin-top: 10px;
  margin-bottom: 10px;
}
.image-comment {
  margin-top: 20px;
  margin-bottom: 25px;
}
.announcement-title-comment{
  padding: 1px 10px 10px 10px !important;
  margin: 0px 10px 0px -9px;
  border: none !important;
  margin-bottom: 15px;
}

.feed-action-section{
  display: flow-root;
  .comment{
    float: left;
    margin-left: 90px;
  }
  .like_delete{
    float: right;
    display: flex;
    gap: 6px;
    margin-right: 5px;
  }
  .like-section-new {
    display: inline-flex;
    flex-direction: column; /* Stack vertically */
  }
  .like-count{
    font-size: 10px;
    font-weight: 400;
    padding-left: 5px;
  }
}
/*# sourceMappingURL=feed-page.css.map */