@import "../../style/font.css";
.welcome-view {
  background-color: transparent !important;
  width: 100%;
  margin: 0 !important;
}
.welcome-view .title-small {
  font-family: "open-sans-regular";
  font-size: 16px;
  line-height: 22px;
  width: 100%;
  margin-top: 20px;
  color: #4A4A4A;
  max-width: 80%;
}
.welcome-view .step-item {
  margin-bottom: 30px;
}
.welcome-view .btn-upload, .welcome-view .btn-save-pdf {
  width: 129px;
  height: 32px;
  color: white;
  font-family: "open-sans-semibold";
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  margin: 35px 0 0;
  border-radius: 3px;
  position: relative;
  padding: 0;
}
.welcome-view .btn-upload .upload, .welcome-view .btn-save-pdf .upload {
  position: absolute;
  width: 100%;
  height: 100%;
  background: red;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}
.welcome-view .img-logo {
  position: absolute;
  top: 0px;
  right: 10px;
  width: 92px;
  height: 184px;
}
.welcome-view .btn-save-pdf {
  cursor: pointer;
  margin: 0;
  min-height: 30px;
}
.welcome-view .col-sm-12, .welcome-view .col-sm-8, .welcome-view .col-sm-6, .welcome-view .col-md-12, .welcome-view .col-md-8, .welcome-view .col-md-6 {
  padding: 0;
}
.welcome-view .list-item {
  margin-top: 70px;
}
.welcome-view .upload-pdf {
  min-height: 400px;
  border: 1px dashed;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  margin-top: 40px;
  margin-bottom: 25px;
  position: relative;
}
.welcome-view .list-pdf-success {
  margin-top: 30px;
  z-index: 3;
}
.welcome-view .list-pdf-success .icon-pdf {
  max-width: 600px;
  background-color: white;
  height: 40px;
  min-height: 40px;
  margin: 0 0 10px 0;
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  border: 1px solid #C7C7C7;
  font-family: open-sans-regular;
  font-size: 15px;
  height: auto;
}
.welcome-view .list-pdf-success .icon-pdf .txt-remove-pdf {
  color: #D0021B;
  font-size: 16px;
}
.welcome-view .txt-remove {
  font-size: 15px;
  line-height: 21px;
  color: #D0021B;
  position: absolute;
  right: 40px;
  cursor: pointer;
}
.welcome-view .scroll {
  padding: 70px 30px 100px 90px;
}
.welcome-view .avatar-image {
  width: 264px;
  max-width: 100%;
  object-fit: contain;
  border-radius: 4px;
}
.welcome-view .input-title {
  max-width: 190px;
  height: 30px;
  font-size: 11px;
  line-height: 30px;
  font-family: open-sans-semibold;
  color: #4A4A4A;
  border-radius: 5px;
  border: none;
  background: rgba(205, 205, 205, 0.1803921569);
  padding: 0 10px;
  margin: 25px 0 18px 0;
}
.welcome-view .text-area-content {
  outline: none;
  height: 320px;
  padding: 35px 30px;
  border: none;
  color: #4A4A4A;
  border-radius: 5px;
  background: rgba(205, 205, 205, 0.1803921569);
  font-size: 14px;
  line-height: 19px;
  font-family: open-sans-semibold;
  resize: none;
  width: 100%;
}
.welcome-view .text-index {
  font-size: 14px;
  line-height: 19px;
  font-family: open-sans-semibold;
  color: #31CCBC;
  position: relative;
}
.welcome-view .tab-view {
  justify-content: flex-start;
  border: none !important;
  margin-top: 30px !important;
}
.welcome-view .tab-view .tab-item {
  font-size: 21px;
  line-height: 28px;
  color: #4A4A4A;
  border-bottom: 3px solid transparent;
  border-top: 3px solid transparent;
  cursor: pointer;
  font-weight: 400;
  height: 40px;
  white-space: pre;
  font-family: open-sans-regular;
}
.welcome-view .tab-view .tab-item:first-child {
  margin-right: 100px;
}
.welcome-view .tab-view .active {
  border-bottom-color: #31CCBC;
  font-family: open-sans-bold;
}

