@import "../../style/font.css";
hr {
  border: 2px dashed #31CCBC !important;
}

@media screen and (max-width: 480px) {
  .register .txt-condensed-extra-bold {
    font-size: 27px;
  }
}

