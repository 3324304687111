@use '../../style/color.scss' as * ;
@import "../../style/font.css";
.terms{
  margin: 0 !important;
  padding-top: 30px;
  background: white;
  display: inline-table;
  max-width: 100% !important;
  .title-small{
    font-family: open-sans-regular;
    font-size: 14px;
    color: $color_grey;
    line-height: 28px;
    text-align: left;
  }
  .icon-help{
    width: 40px;
    object-fit: contain;
    margin-right: 20px;
  }
  .row-hep{
    display: flex;
    align-items: center;
    margin: 10px 0;
  }
}