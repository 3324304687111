@import "../../style/font.css";
.shift-view {
  font-family: open-sans-semibold;
}
.shift-view .create-shift {
  color: #31CCBC;
}
.shift-view .back-to-roster {
  cursor: pointer;
  color: #31CCBC;
  font-size: 15.3px;
  line-height: 19px;
  margin-left: 10px;
  font-family: open-sans-semibold;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}
.shift-view .back-to-roster img {
  width: 14px;
  height: 23px;
  margin-right: 10px;
}
.shift-view .shift-form {
  padding: 80px 20px 25px !important;
}
.shift-view .shift-item {
  width: 306px;
  height: 80px;
  border-radius: 3px;
  background-color: #ffffff;
  margin-bottom: 15px;
  padding: 10px;
  align-items: center;
  position: relative;
}
.shift-view .shift-item p {
  margin: 5px;
  font-size: 16px;
  line-height: 20px;
  font-family: open-sans-semibold;
  color: #4A4A4A;
}
.shift-view .shift-item .color-box {
  width: 56px;
  height: 50px;
  margin-right: 10px;
  border-radius: 3px;
  font-size: 9px;
  display: flex;
  align-items: center;
}
.shift-view .shift-item .btn-view {
  position: absolute;
  right: 10px;
  text-align: left;
}
.shift-view .shift-item .btn-view p {
  font-size: 12.8px;
  line-height: 16px;
  color: red;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}
.shift-view .shift-item .btn-view p:hover {
  cursor: pointer;
}
.shift-view .shift-item .hours-view {
  color: #4A4A4A;
  font-size: 18px;
  line-height: 25px;
  text-align: left;
  font-family: open-sans-semibold;
}
.shift-view .shift-item .hours-time {
  font-size: 14.4px;
  line-height: 18px;
  color: #9B9B9B;
  font-family: open-sans-semibold;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}
.shift-view .btn-linear-invite {
  bottom: 0;
  margin-top: 53px;
  position: initial;
  border-radius: 25px;
  font-family: "open-sans-regular";
}
.shift-view .color-view {
  margin-top: 10px;
}
.shift-view .color-div {
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin: 1px;
  font-size: 9px;
  display: flex;
  align-items: center;
}
.shift-view .create-shift-specific .display_row.form-input.align-center {
  width: 140px;
}
.shift-view .form-create-shift {
  display: flex;
  flex-wrap: wrap;
  width: 335px;
  justify-content: space-around;
}
.shift-view .form-create-shift .MuiStack-root .shift-time-picker {
  display: none;
}
.shift-view .form-create-shift .form-group {
  margin-bottom: 53px;
}
.shift-view .form-create-shift .form-group.has-error input {
  border-color: #FF9494;
}
.shift-view .form-create-shift .form-group.has-error input::placeholder {
  color: #FF9494;
}
.shift-view .form-create-shift .form-label {
  font-family: open-sans-semibold;
  width: auto;
  font-size: 19px;
  line-height: 24px;
  font-weight: 100;
  text-align: start !important;
  color: #4A4A4A;
  padding-left: 0 !important;
}
.shift-view .form-create-shift .form-input {
  width: 100%;
}
.shift-view .form-create-shift .rc-time-picker input {
  text-align: center;
  width: 50% !important;
}
.shift-view .form-create-shift .rc-time-picker-input1 {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #C4C4C4;
  color: #9B9B9B;
  font-size: 19px;
  line-height: 24px;
  text-align: center;
  font-family: open-sans-semibold;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  padding: 4px 0;
}
.shift-view .form-create-shift .rc-time-picker-input1.has-error {
  color: #FF9494;
  border-color: #FF9494;
}
.shift-view .form-create-shift .rc-time-picker-input1.none-has-error.has-error {
  border-color: #C4C4C4;
  color: #9B9B9B;
}
.shift-view .form-create-shift .rc-time-picker-input {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #C4C4C4;
  color: #9B9B9B;
  font-size: 19px;
  line-height: 24px;
  text-align: center;
  font-family: open-sans-semibold;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  padding: 4px 0;
}
.shift-view .form-create-shift .rc-time-picker-input.has-error {
  color: #FF9494;
  border-color: #FF9494;
}
.shift-view .form-create-shift .rc-time-picker-input.none-has-error.has-error {
  border-color: #C4C4C4;
  color: #9B9B9B;
}
.shift-view .form-create-shift .form-control {
  background: transparent;
  border: 1px solid #C4C4C4;
  border-width: 0 0 1px 0;
  height: 40px;
  line-height: 40px;
  border-radius: 0;
  padding: 0;
  outline: none !important;
  box-shadow: none !important;
}
.shift-view .form-create-shift :first-child {
  width: 100%;
  font-size: 19px !important;
}
.shift-view .form-create-shift :not(:first-child) {
  width: 48%;
}
.shift-view .form-create-shift :not(:first-child) .display_row {
  flex-direction: column;
  margin: 0 0;
}
.shift-view .MuiInputAdornment-sizeMedium {
  display: none;
}
.shift-view .shift-select-color {
  font-family: open-sans-semibold;
  font-size: 15px;
  line-height: 19px;
  color: #4A4A4A;
}
.shift-view .txt-condensed-extra-bold {
  color: #31CCBC;
}

.form-create-shift .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.time-shift .form-group {
  width: 100% !important;
}

.shadow_popup {
  box-shadow: 0 0.5rem 1rem rgb(255, 255, 255) !important;
}

.back-to-page {
  cursor: pointer;
  z-index: 9;
}

