@use '../../style/color.scss' as * ;
@import "../../style/font.css";

.card-element-container {
    border-radius: 4px;
    
    .login_btn_login{
        margin-top: 50px;
    }
  }
  
  .card-element {
    font-size: 16px;
    color: #333;
    border-radius: 4px;
    background-color: #f7f7f7;
    padding: 10px;
  }

  .changePayment{
     .card-element-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 40%;
    margin: auto;
    max-width: 100%;
  }
  .card-element {
    font-size: 16px;
    color: #333;
    border-radius: 4px;
    background-color: #f7f7f7;
    padding: 13px 10px;
    width: 98%;
}
}