@use '../../style/color.scss' as * ;
@import "../../style/font.css";

.register{
   width: 100% !important;
  padding: 100px 0 20px 0;
  padding-left: 52px;
  background-image: none;
  letter-spacing: -1px;
  .avatar-image{
    background-image: url('../../assets/images/photo-upload/img-ava-def.png');
    width: 198px;
    height: 198px;
    border-radius: 25px;
  }
  .image-boarding-two{
    width: 225px;
    height: 134px;
    max-width: 100%;
    object-fit: contain;
  }
  .col-sm-6:first-child{
    border-right:1px solid $color_CCC;
    margin-bottom: 90px;
    padding: 0 10px 0 60px;
  }
  .col-sm-6{
    padding: 0 40px;
  }
  .txt-condensed-extra-bold{
    font-family: 'open-sans-extrabold' !important;
    color: $color_def;
    font-size: 36px;
    color: #00CDAC;
    text-align: center;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0px;
  }
  .formRegister{
    margin-top: 40px;
    margin-left: 66px;
  }
  .image-register{
    width: 147px;
    height: 126px;
    object-fit: contain;
    margin-bottom: 56px;
  }
  .image-card.small{
    width: 40px;
    height: auto;
    object-fit: contain;
    margin: 0 0 0 6px;
    padding: 0;
    box-shadow: none;
  }
  .border-color{
    border:1px solid $color_31CCBC !important;
  }
  .image-card{
    //width: 110px;
    //object-fit: contain;
    //margin: 0 25px;

    border:1px solid transparent;
    width: 101px;
    height: 68px;
    -o-object-fit: contain;
    object-fit: contain;
    margin: 0 25px;
    padding: 5px 23px;
    box-shadow: 2px 2px 8px rgba(0,0,0,0.5);
    border-radius: 7px;
  }
  .btn-linear.btn-li{
    border-radius: 100px;
    min-width: 14px;
    height: 14px;
  }
  .note-register{
    font-size: 16px;
    
    text-align: left;
    margin: 0 0 20px 20px;
    font-weight: 100;
    margin-top: -5px;
    color: $color_grey;
    text-align: center;
    font-size: 22.5px;
    font-family: Open-Sans-regular;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -1.25px;
  }
}
.register-payment{
  letter-spacing: -1px;
  .formRegister{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .form-group{
    width: 100%;
  }
  .form-group:nth-child(3), .form-group:nth-child(4){
    width: calc(50% - 10px);
  }
  .icon-member{
    //width: 65px;
    height: 48px;
    margin: 20px 0 30px 0;
  }
  .txt-person{
    margin:0;
    text-align:center;
    font-size:23px;
    color: $color_4A4A4A;
    font-weight: 100;
    margin-bottom: 35px;
  }
  .txt-price{
    font-family:Avenir;
    font-size: 30px;
    font-weight:bold;
    color: $color_def;
    margin-bottom: 30px;
  }
}
.pricing_section{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.pricing_panel{
  background: #F9F9F9;
  padding: 50px 30px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 140%;
  right: 20%;
  margin-bottom: 100px;
}
.txt-condensed-bold{
  color: $color_def;
  font-size: 36px;
  font-weight: bold !important;
  font-size: 22.2px;
}
.price_team_count_section {
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.price_team_count {
  font-family: 'Avenir-Book';
  width: 65px;
  height: 65px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin:5px;
  color: #595959;
}
.price_team_count:hover {
  font-family: 'Avenir-Book';
  width: 65px;
  height: 65px;
  background-color: #31CCBC;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin:5px;
  color: #fff;
  -webkit-transition: 0.5s
}
.price_team_count_active {
  font-family: 'Avenir-Book';
  width: 65px;
  height: 65px;
  background-color: #31CCBC;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin:5px;
  color: #fff;
  -webkit-transition: 1s;
}
.price_team_count_number{
  font-family: 'Avenir-Book';
  font-style: normal;
  font-weight: bold !important;;
  font-size: 34px;
  line-height: 46px;
  text-align: center;
  letter-spacing: -1.88889px;
  margin: 0 !important;
}
.txt-condensed-normal {
  font-family: 'Avenir-Book';
  font-style: normal;
  font-weight: 500;
  font-size: 15.5px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.861111px;
  color: #717171;
}
.price_input {
  font-family: 'Avenir-Book';
  width: 200px;
  height: 65px;
  background-color: white;
  border-radius: 8px;
  border: none;
  font-style: normal;
  font-weight: bold !important;;
  font-size: 34px;
  line-height: 46px;
  text-align: center;
  letter-spacing: -1.88889px;
  color: #595959;
}
.price_input::placeholder {
  font-family: 'Avenir-Book';
  width: 200px;
  height: 65px;
  background-color: white;
  border-radius: 8px;
  border: none;
  font-style: normal;
  font-weight: bold !important;;
  font-size: 34px;
  line-height: 46px;
  text-align: center;
  letter-spacing: -1.88889px;
  color:#9B9B9Baa !important;
;
}
.toggle-switch {
  position: relative;
  width: 75px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
  margin-right: 10px; }
.toggle-switch.small-switch {
  width: 40px; }
.toggle-switch.small-switch .toggle-switch-inner:after, .toggle-switch.small-switch .toggle-switch-inner:before {
  content: "";
  height: 20px;
  line-height: 20px; }
.toggle-switch.small-switch .toggle-switch-switch {
  width: 16px;
  right: 20px;
  margin: 2px; }
.toggle-switch-checkbox {
  display: none; }
.toggle-switch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb !important;
  border-radius: 20px;
  margin: 0; }
.toggle-switch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s; }
.toggle-switch-inner:before, .toggle-switch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 34px;
  padding: 0;
  line-height: 34px;
  font-size: 14px;
  color: white;
  font-weight: bold;
  box-sizing: border-box; }
.toggle-switch-inner:before {
  content: "Yes";
  text-transform: uppercase;
  padding-left: 10px;
  background-color: #31CCBC !important;
  color: #fff; }
.toggle-switch-disabled {
  background-color: #31CCBC !important;
  cursor: not-allowed; }
.toggle-switch-disabled:before {
  background-color: #31CCBC !important;;
  cursor: not-allowed; }
.toggle-switch-inner:after {
  content: "No";
  text-transform: uppercase;
  padding-right: 10px;
  background-color: #31CCBC !important;
  color: #fff;
  text-align: right; }
.toggle-switch-switch {
  display: block;
  width: 24px;
  margin: 5px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 40px;
  border: 0 solid #bbb;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s; }
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
  margin-left: 0; }
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
  right: 0px; }
.price_text_monthly{
font-style: normal;
font-weight: bold !important;;
font-size: 25px;
line-height: 26px;
/* identical to box height */
color: #595959;
margin: 0;
}
.price_text_monthly-disabled{
  font-style: normal;
  font-weight: bold !important;;
  font-size: 25px;
  line-height: 26px;
  color: #595959;
  opacity: 0.17;
  margin: 0;
  }
.price_text_Annually-disabled {
  font-style: normal;
  font-weight: bold !important;;
  font-size: 25px;
  line-height: 26px;
  color: #595959;
  mix-blend-mode: normal;
  opacity: 0.17;
  margin: 0;
  margin-top: 17px;
}
.price_text_Annually {
  font-style: normal;
  font-weight: bold !important;;
  font-size: 25px;
  line-height: 26px;
  color: #595959;
  mix-blend-mode: normal;
  margin: 0;
  margin-top: 17px;
}
.price_text_discount{
font-style: normal;
font-weight: bold !important;;
font-size: 17px;
line-height: 19px;
color: #31CCBC;
margin: 0;
}

.price_plan_group_svg{
  margin-top: 30px;
}

.start_now_btn {
  width: 234px;
  height: 58px;
  background: #31CCBC;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 1.95px;
  border: none;
  color: white;
  margin-top: 40px;
  font-style: normal;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 20px;
  text-align: center;
  font-family: 'Avenir-Book';
}
.start_now_btn:hover {
  width: 234px;
  height: 58px;
  background: #595959;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 1.95px;
  border: none;
  color: white;
  margin-top: 40px;
  -webkit-transition: 0.5s;
  font-style: normal;
  line-height: 20px;
  text-align: center;
  font-weight: bold !important;
  font-size: 16px !important;
  font-family: 'Avenir-Book';
}

.your_price_section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.price_plan_ul{
  text-align: left;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 15px;
  line-height: 18px;
}

.price_plan_ul > li {
  position: relative;
  padding-left: 25px;
  margin: 0 0 27px;
  color: #717171;
}

.price_plan_ul > li::before{
  position: absolute;
  content: "";
  width: 22px;
  height: 17px;
  background: url(//teemieapp.com/wp-content/themes/teemie/images/tick.png) no-repeat 0 0;
  background-size: 16px;
  left: 0;
  top: 7px;
} 

.non_profit_section{
  height:524px;
  border-radius: 10px;
  position: relative;
  width: 86%;
  right: -7%;
  margin-bottom: 100px;
}
.non_profit_layout{
  height:524px;
  background: url(https://teemieapp.com/wp-content/uploads/2021/12/bg1.png) no-repeat 0 0;
  background-size: cover;
  border-radius: 10px;
  position: relative;
  width: 86%;
  right: -7%;
  margin-bottom: 100px;
}

.non_profit_section > h3{
  font-family: 'Futura-Condensed-Extra-Bold';
  color: #fff;
  text-transform: uppercase;
  font-size: 32px;
  line-height: 42px;
  text-align: center;
  margin-top: 65px;
}

.non_profit_section > p {
  color: #fff;
  border-radius: 10px;
  text-align: center;
  font-size: 22px;
  line-height: 31px;
  margin-bottom: 0;
  padding: 30px 100px 30px 150px;
  font-family: 'Avenir-Book';
}

.non_profit_section > ul {
  list-style: none;
  padding: 0;
}

.non_profit_section > ul > li {
  color: #fff;
  border-radius: 10px;
  text-align: center;
  font-size: 22px;
  line-height: 31px;
  margin-bottom: 0;
  font-family: 'Avenir-Book';
}

.non_profit_layout::before{
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  font-family: 'Avenir-Book';
}

.pricebtn {
  display: inline-block;
  padding: 18px 70px;
  border-radius: 2px;
  font-weight: 900;
  font-size: 15px;
  text-transform: uppercase;
  line-height: 18px;
  color: #fff;
  background: linear-gradient(to right bottom, #fed9a4 20%, #eb6a66 100%);
  border: none;
  font-weight: bold !important;
  font-size: 16px !important;
  font-family: 'Avenir-Book';
}

.pricebtn:hover{
  background: linear-gradient(to left top, #fed9a4 20%, #eb6a66 100%);
}


.non_profit_section_two{
  height:480px;
  border-radius: 10px;
  position: relative;
  width: 86%;
  right: -7%;
  margin-bottom: 100px;
}
.non_profit_layout_two{
  height:480px;
  background: url(https://teemieapp.com/wp-content/uploads/2021/12/bg2.png) no-repeat 0 0;
  background-size: cover;
  border-radius: 10px;
  position: relative;
  width: 86%;
  right: -7%;
  margin-bottom: 100px;
}

.non_profit_section_two > h3{
  font-family: 'Futura-Condensed-Extra-Bold';
  color: #fff;
  text-transform: uppercase;
  font-size: 32px;
  line-height: 42px;
  text-align: center;
  margin-top: 65px;
}

.non_profit_section_two > p {
  color: #fff;
  border-radius: 10px;
  text-align: center;
  font-size: 22px;
  line-height: 31px;
  margin-bottom: 0;
  padding: 20px 100px;
  font-family: 'Avenir-Book';
}

.non_profit_section_two > ul {
  list-style: none;
  padding: 0;
}

.non_profit_section_two > ul > li {
  color: #fff;
  border-radius: 10px;
  text-align: center;
  font-size: 22px;
  line-height: 31px;
  margin-bottom: 0;
  font-family: 'Avenir-Book';
}

.non_profit_layout_two::before{
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  font-family: 'Avenir-Book';
}
.register .login_btn_login{
  color: #FFF;
  text-align: center;
  font-size: 13px;
  font-family: open-sans-regular;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.5px;
}
.onboarding-2 .register .note-register {
  color: #9B9B9B;
  text-align: center;
  font-size: 20px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.8px; }
.onboarding-2 .avatar-image {
  margin-bottom: 27px; }

.onboarding-2 .btn-choose-file {
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.72px;
  text-decoration: none;
  cursor: pointer !important; }

.onboarding-2 .reg-error {
  margin-bottom: 41px;
  font-family: open-sans-regular;
  color: #31CCBC;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.72px;
}

.onboarding-2 .login_btn_login.btn-linear {
  background: #ffdea7 !important;
  background: linear-gradient(164deg, #ffdea7 0%, #ea6362 70%) !important; }

.onboarding-2 .note-register, .onboarding-3 .note-register {
  color: #9B9B9B;
  text-align: center;
  font-size: 22.5px;
  font-family: Open-Sans-regular;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -1.25px; }

.onboarding-2 .next-btn, .onboarding-3 .next-btn, .onboarding-3 .next-btn {
  font-family: open-sans-Semibold;
  color: #FFF;
  text-align: center;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 600;
  line-height: normal; }
.register-box .register {
  width: 100% !important;
  padding: 100px 0 20px 0;
  background-image: none;
  max-width: 966px !important;
  letter-spacing: -1px;
}
.register-box .register .note-register {
  font-size: 16px;
  color: #9B9B9B;
  text-align: left;
  margin: 0 0 20px 20px;
  font-weight: 100;
  font-family: sans-serif;
  margin-top: -5px;
  letter-spacing: -1px;
  line-height: 1.42857143;
}
.register-box .register .txt-condensed-extra-bold {
  font-family: "Futura-Condensed-Extra-Bold" !important;
  color: #31CCBC;
  font-size: 36px;
  letter-spacing: unset;
  line-height: 1.42857143;
}
.register-box .login_btn_login{
  margin-top: 48px;
  margin-left: 28%;
}

.background-image-full .register-box .register.row {
  // background-image: url(../../assets/images/back-ground-image.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top center;
}
.background-image-full .register-box .register.row {
   height: 100%; 
}
.onboarding-logo{
  display: flex;
  justify-content: center;
}
.onboarding-logo img.logo {
  width: 100px;
  height: 90.45px;
  top: 92.14px;
  left: 138px;
}
.onboarding{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}

.mob-logo{
  display: flex;
  justify-content: center;
  align-items: center;
}
.mob-lap{  
  width: 152.26px;
  height: 122.01px;
  top: 371.87px;
  left: 43.68px; 
  margin: 15px 20px;
}
.mob-mobile{ 
  width: 59.98px;
  height: 116.76px;
  top: 371.87px;
  left: 261.51px;
  margin: 15px 20px;
}
.txt-header{
  font-family:open-sans-bold;
  font-size: 16px;
  font-weight: 800;
  line-height: 22px;
  text-align: center;
  color:rgba(49, 204, 188, 1);
}
.text-center{
  font-family:open-sans-regular;
  font-size: 17px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0px;
  text-align: center;
  color:rgba(55, 54, 51, 1);    
}

//new csss
// .register-body{
//   background-image: url(../../assets/images/aa.png);
//   background-repeat: no-repeat;
//   background-size: contain;
//   height: 100vh;
//   background-color: #455154;
// }
.register-boxs{
  background-image: url(../../assets/images/register/register-background.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: content-box;
  height: 100vh;
}
.register-container{
  display: flex;
    justify-content: space-between;
}
.register-box{
  background-image: url(../../assets/images/register.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: content-box;

}
.image-register-icon{
  width: 194px;
height: 53.622px;
flex-shrink: 0;
}
.register-containers{
  display: flex;
justify-content: space-between;
}
.register-containers .register-form{
  width: 600px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffffd1;
}
.lap-top-image{
  width: 588px;
  height: 350px;
  padding-top: 7px;
  margin-left: 40px;
}
.register-containers .register-para{
  width: 766px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-top: 10px;
}
.register-containers .register-para .register-para-text{
  width: 681px;
  color: #FFF;
  font-family: 'open-sans-bold';
  font-size: 16.5px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.register-containers .register-instruction{
  display: flex;
      padding-left: 110px;
      flex-direction: column;
      justify-content: space-evenly;
}
.register-form .title{
  width: 363px;
  color:  #4A4A4A;
  text-align: center;
  font-family: 'open-sans-bold';
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.register-containers .formRegister .form-control {
  width: 348px;
  height: 49px;
  flex-shrink: 0;
  border-radius: 4.8px;
  background: #F6F6F6;
  color: #1a1919;
  font-family: 'open-sans-regular';
  font-size: 15.3px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.register-containers .formRegister{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 52px;
}
.register-confirm-btn{
  width: 345px;
  height: 56px;
  flex-shrink: 0;
  background: linear-gradient(to right bottom, #FFDEA7 0%, #EA6362 100%);
  color: #FFF;
  text-align: center;
  font-family: 'open-sans-bold';
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
  margin-top: 37px;
  border-radius: 5px;
}
.onboarding-1 .welcom-page-image{
  background-image: url(../../assets/images/register/on-boarding-welcomScreen.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: content-box;
  height: 100vh;
}
.onboarding-2 .welcom-page-image{
  background-image: url(../../assets/images/register/on-boarding-imageUpload.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: content-box;
  height: 100vh;
}
.onboarding-3 .welcom-page-image{
  background-image: url(../../assets/images/register/on-boarding-sucessScreen.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: content-box;
  height: 100vh;
}
 .image-trance-box {
  background: #138094;
  opacity: 0.79;
  height: 100vh;
  position: relative;
}
.lets-btn{
  margin-top: 51px;
}
.onboarding-1  .welcome-content , .onboarding-2 .welcome-content , .onboarding-3 .welcome-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:center ;
  gap: 34px
}
.onboarding-1 .welcome-content .para{
  width: 617px;
  color:  #4A4A4A;
  text-align: center;
  font-family: 'open-sans-regular';
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -1.25px;
  display: flex;
  flex-direction: column;
  gap: 35px;
}
.onboarding-3 .welcome-content .para{
  width: 617px;
  color:  #4A4A4A;
  text-align: center;
  font-family: 'open-sans-regular';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -1.25px;
  display: flex;
  flex-direction: column;
  gap: 35px;
  height: 200px;
}
.onboarding-2 .welcome-content .para{
  color:  #4A4A4A;
  text-align: center;
  font-family:'open-sans-semibold';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -1.25px;
  width: 617px;
}
.onboarding-1 .welcome-content .Title , .onboarding-2 .welcome-content .Title , .onboarding-3 .welcome-content .Title{
  color: #31CCBC;
  text-align: center;
  font-family: 'open-sans-extrabold';
  font-size: 29.5px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -2px;
}
.onboarding-2 .image-close-icon{
  background-color: black;
  position: absolute;
  right: 8px;
  top: 8px;
  width: 15px;
  height: 15px;
  border-radius: 38px;
}

.welcome-content .choose-image{
  width: 150px;
  height: 150px;
  flex-shrink: 0;
  border-radius: 15px;
  border: 1px dashed rgba(69, 81, 84, 0.30);
  background: #FAFAFA;
}
.welcome-content .image-containers{
  width: 365px;
  display: flex;
  justify-content: space-between;
  margin-top: 2.6%;
}
.welcome-content .image-inner{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: inherit;
  gap: 18px;
}
.welcome-content .image-text{
  width: 111px;
  height: 36px;
  color:  #455154;
  text-align: center;
  font-family:  'open-sans-bold';
  font-size: 9px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.onboarding-1 .welcome-content .welcome-button , .onboarding-2 .welcome-content .welcome-button , .onboarding-3 .welcome-content .welcome-button{
  width: 197.246px;
  height: 44.656px;
  color: #FFF;
  text-align: center;
  font-family: 'open-sans-semibold';
  font-size: 16.418px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: linear-gradient(to right bottom, #FFDEA7 0%, #EA6362 100%);
  border: none;
  border-radius: 25px;
}
.on-boarding-title{
  color: #31CCBC;
  font-family: 'open-sans-extrabold';
  font-size: 25px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.on-boarding-para{
  color:  #4A4A4A;
  text-align: center;
  font-family: 'open-sans-regular';
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -1.25px;
  width: 643px;
    display: inline-flex
;
}


@media screen and (max-width: 767px){
  .register .register-instruction{
    display: none;
  }
}
@media screen and (max-width: 480px){

  .register-box .register .txt-condensed-extra-bold{
    font-size: 28px;
  }
}