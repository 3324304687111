@use '../../style/color.scss' as * ;
@import "../../style/font.css";

.calendar-container.calendar-view{
  .header-roster-scroll{
    position: fixed;
    z-index: 99;
    left: 221px;
    width: 81%;
    .search-view{
      background-color: white;
      width: 103%;
      margin: 0px;
    }
  }
  
}
.select2-container--classic.select2-container--open .select2-selection--multiple {
border: none !important;
}
.form-control.field-form-control,.modal-open .modal .modal-dialog .form-control.field-form-control,.modal-open .modal .modal-dialog .form-select.field-form-control{
  background-color: #F4F6F6 !important;
  padding: 15px 6px 28px 20px !important;
}
.date-input , .time-input , .user-input{
  position: relative;
}
.calendar-time-picker{
  .MuiStack-root {
    padding-top: 0px !important;
    .MuiFormControl-root {
        height: 43px !important;
    }
  }
}

.create_event_modals-body{
  width: 100%;
  padding: 0 12%;
  height: 234px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding-top: 62px;
  row-gap: 30px;

  .time-input button{
    padding-left: 0px !important;
    padding-right: 7px;
  }
  .time-input button:hover{
    background-color: transparent;
  }
  .time-input .MuiFormControl-root.MuiTextField-root.css-z3c6am-MuiFormControl-root-MuiTextField-root{
  width: 306px;
  }
 .date-input button:hover{
  background-color: transparent;
}
  .field-form-control{
    margin: 10px;
  }
  .time-input p{
display: none;
  }
  .form-select{
    width: 273px !important;
    color: #9B9B9B;
    border: none;
  }
  /* Hide the default arrow */
select {
  -webkit-appearance: none; /* For Chrome and Safari */
  -moz-appearance: none;    /* For Firefox */
  appearance: none;
  background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%234bd9ca' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  position: relative;
  padding-right: 2rem; /* Space for custom arrow */
}

/* Custom arrow */
select::after {
  content: '';
  position: absolute;
  right: 1rem;
  top: 50%;
  width: 0.5rem;
  height: 0.5rem;
  border: solid #4bd9ca;
  border-width: 0.15rem 0.15rem 0 0;
  transform: rotate(45deg) translateY(-50%);
  pointer-events: none;
}

/* Dropdown wrapper styling */
.select-wrapper {
  position: relative;
  display: inline-block;
}

select {
  background-color: white;
}

.select2-container{
  background-color: #F4F6F6 !important;
  width: 273px !important;
  height: 42px;
  border-radius: 6px;
  textarea{
    background-color: #F4F6F6 !important;
    margin-top: 13px !important;
    font-size: 14px !important;
    padding-left: 12px !important;
    font-family: 'open-sans-regular' !important;
  }
  span{
    background-color: #F4F6F6;
    border: none !important;
    border-radius: 6px;
  }


  .select2-results__options{
    background: none;
    background-color: transparent;
    border-radius: 3px;
    border: 0.5px solid #DBDBDB;
    background: #FFF;
    padding-top: 8px;
    span{
      background: none;
      background-color: transparent;
    }
    .select2-results__option{
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 1px;
      line-height: 19px;
      font-family: 'open-sans-semibold';
      text-transform: capitalize;
      display: flex;
      flex-direction: column;
      padding: 10px 0px 0px 18px;
    }
    .select2-results__option--highlighted,.select2-results__option--selected{
      background: none;
      background-color: transparent;
      color: #31CCBC;
      font-family: 'open-sans-extrabold';
      font-size: 14px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      letter-spacing: -0.35px;
    }
  }
}

select:hover {
  border-color: #4bd9ca;
}

.select-wrapper:focus-within::after {
  border-color: #4bd9ca;
}

}
/* General styles for Select2 options */
.select2-results__option label {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 5px;
}

.select2-results__option input[type="checkbox"] {
  margin: 0;
}

.select2-results__option span {
  white-space: nowrap;
}

.calendar-container{
  .body_container{
    padding-left: 35px !important;
  }
}

.calendar-view{
  .button-not-resp{
    display: flex;
  }
  
  .button-resp{
    display: none;
  }
  .search-view{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    float: right;
    width: 100%;
    margin-right: 53px;
    height: 92px;
  }
  .tab-view{
    justify-content: flex-start;
    border:none !important;
    margin-bottom:0 !important;
    padding:10px 0px 10px 0px !important;
    .tab-item{
      line-height: 22px;
      border-bottom: 3px solid transparent;
      border-top: 3px solid transparent;
      cursor: pointer;
      padding: 0 10px;
      margin-left: 40px;
      color: #4A4A4A;
      font-family: 'open-sans-extrabold';
      font-size: 16.38px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      height: 30px;
    
      :first-child{
        margin-left: 0;
      }
    }
    .active{
      border-bottom-color: $color_def;
    }
  }

  .create_new_event_btn{
    cursor: pointer;
    margin-left: 20px;
    font-size: 12px !important;
    font-weight: 600 !important;
    line-height: 20px;
    font-family: 'open-sans-semibold';
    background-image: linear-gradient(to right, #FFDEA7, #EA6362) !important;
    background-color: #cacaca;
    background-repeat: repeat !important;
    border: transparent !important;
    color: white !important;
    border-radius: 25px !important;
    width: 145px;
    height: 32px;
    align-items: center;
    display: flow;
  }

  .icon-plus{
    width: 23px;
    height: 23px;
    object-fit: cover;
    margin-right: 10px;
  }
  .member-view{
    padding-left: 0px !important;
    padding-top: 0px !important;
  }
  .DayPicker{
    font-family: open-sans-bold;
  }
  .DayPicker-Caption{
    color: #31CCBC;
    text-align: center;
    font-family: 'open-sans-extrabold';
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-transform: uppercase;
    padding: 0 10px;
    position: relative;
  }
  .DayPicker-Month{
    margin: 6px 10px 0;
    font-family: open-sans-regular !important;

  }
 
  .DayPicker-wrapper{
    outline: none;
  }
  .DayPicker-Day{
    outline: white;
  }
  .DayPicker-Day.DayPicker-Day--outside{
    color: $color_CCC !important;
    background: transparent !important;
    border: none !important;
  }
  .DayPicker-Day .hasEvent{
    width: 24px;
    height: 24px;
    line-height: 24px;
    margin: auto;
    text-align: center;
    color:  #EC6E68;
    text-align: center;
    font-family: 'open-sans-extrabold';
    font-size: 16.8px;
    font-style: normal;
    font-weight: 800;
 
  }
  .month-view  .DayPicker-Day .hasEvent{
    line-height: 24px;


    color:  #EC6E68;

    font-family: 'open-sans-extrabold';
    font-size: 16.8px;
    font-style: normal;
    font-weight: 800;
 
  }
  .year-view .DayPicker-Month .hasEvent{
    cursor: pointer;
  }
  .DayPicker-Day--today{

    border-radius: 0 !important;
    font-weight: 400;

  }
  .DayPicker-Caption{
    text-align: center;
  }
  .year-view{
    .DayPicker-Caption{
      text-align: left !important;
      font-family:'open-sans-extrabold';
    }

    .DayPicker-Month{
      margin-bottom: 50px;
      width: 20%;
    }

    abbr[title], abbr[data-original-title]{
      display: none;
    }

    .DayPicker-Day{
      border: none;
      font-size: 10px;
      width: 38px;
      height: 38px;
      font-family: open-sans-bold;

    }
    .DayPicker--interactionDisabled .DayPicker-Day{
      color: #595959;
      text-align: center;
      font-family: 'open-sans-semibold';
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
  }

  .month-view{
    .DayPicker-NavBar{
      align-items: center;
      display: flex;
      height: 55px;
      color: $color_def;
      position: absolute;
  width: 230px;
      margin:-20px 0px;
      font-family: Avenir-Medium;
      .fa-angle-left{
        color: $color_def;
        position: initial;
        font-size: 25px;
        font-weight: bold;
        line-height: 30px !important;
        cursor: pointer;
        position: absolute;
        top: -2px;
        left: 9px;
      }
      .fa-angle-right{
        color: $color_def;
        position: initial;
        font-size: 25px;
        font-weight: bold;
        line-height: 30px !important;
        cursor: pointer;
        position: absolute;
        top: -2px;
        right: 0px;
      }
    }
    .DayPicker-Day{
      width: 212px;
      height: 202px;
      padding: 0;
      border-radius: 2px;
      border: 0.6px solid #DDDCDC;
      background: #FFF;
      color: #4A4A4A;
      font-family: 'open-sans-extrabold';
      font-size: 9px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;

    }
    .DayPicker-Weekday{
      text-align: justify;
      width: 145px;
      color: #31CCBC;
      font-family: 'open-sans-extrabold';
      font-size: 13px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
    }
    .DayPicker-Caption {
      width: 167px;
      height: 45px;
    }
    abbr[title], abbr[data-original-title] {

    }
  }
}

.modal-open .modal .modal-content{
  padding: 30px;
}
.modal-open .modal .modal-body{
  padding: 0;
  .btn-linear-invite{
    width: 195px;
    height: 46px;
    margin-top: 90px;
  }
}
.modal-open .tip-modal.modal .modal-dialog
{
  width: 641px !important;
  margin-top: 13%;
  margin-left: 35%;
  height: 425px !important;
  position: relative;
}
.modal-open .modal .modal-dialog, .modal-open .modal .modal-content, .modal-open .modal .modal-body{
  width: 932px !important;
  border-radius: 8px;
  max-width: 100%;
 
  

  .date-time-picker .fa-calendar {
    top: 15px !important;
  }
  .form-create-event{
    width: 47% !important;
    justify-content: space-between;
    margin: 0;
  }
 
 
  .form-group{
    .fa-calendar{
      border: none;
    }
    .rc-time-picker-input{
      border-radius: 0;
      border-width: 0 0 1px 0;
      border-color: $color_DBD7D7;
      height: 30px;
      font-size: 14px;
      color: $color_grey;
      padding: 6px 0;
    }
    .rc-time-picker{
      width: 100%;
    }
  }

  .drop-staff{
    width: 47%;
    height: 315px;
    .list-staff-choose{
      margin: 10px 0 0 -5px;
      width: calc(100% + 10px);
      max-height: 275px;
      overflow-x: auto;
      .fa-times{
        width: 20px;
        height: 20px;
        color: $color_D0021B;
        position: absolute;
        top: 1px;
        right: 0px;
        line-height: 20px;
        cursor: pointer;
      }
    }
    .avatar-staff{
      width: 100%;
      height: 100%;
    }
    .staff-view{
      position: relative;
      width: 109px;
      height: 94px;
      border-radius: 4px;
      overflow: hidden;
    }
    .name-staff{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 11px !important;
      padding: 5px !important;
      line-height: 22px !important;
      font-family: open-sans-extrabold;
      font-weight: 300;
      background-color: rgba(0,0,0,0.5);
    }
  }

  .form-control, form-control[disabled]{
    width: 100%;
    border: none;
    transition: none;
    background-color: transparent !important;
    height: 40px;
    font-size: 14px;
    color: #4A4A4A;
    line-height: 19px;
    padding: 18px 10px 18px 20px;
    border-radius: 0;
    box-shadow: none !important;
    outline: none !important;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    // opacity: 0.6;
    letter-spacing: -0.35px;

  }

  .select__control{
    align-items: flex-start;
    justify-content: flex-end;
    .select__value-container--is-multi{
      padding: 0;
      position: absolute;
      top:0;
      width: 100%;
      height: 105px;
   
    }
    .select__value-container--is-multi-disabled{
      position: absolute;
      top:0;
      width: 100%;
    }
    .select__multi-value__remove{
      position: absolute;
      right: 7px;
      top: 4px;
      width: 10px;
      height: 10px;
      color: white;
      background: rgba(255,0,0,1);
      border-radius: 12px;
      padding: 0;
    }
    .select__multi-value{
      position: relative;
      margin: 0;
      width: 87px;
      height: 81px;
      display: ruby;
      background: no-repeat;
    }
    .select__placeholder{
      top: calc((100% - 39px) / 2);
    }
  }
  .select__control, .Select-control, .Select-placeholder,.Select-input{
    height: 30px;
    min-height: 30px !important;
    &:hover{
      border-color: hsl(0,0%,80%) !important;
      box-shadow: none;
    }
  }
  .css-1hwfws3{
    max-height: 280px;
    overflow-y: auto;
  }
  .css-vj8t7z, .css-2o5izw{
    border-radius: 0;
    border-width: 0 0 1px 0;
    box-shadow: none;
    border-color: hsl(0,0%,80%);
  }
  .basic-multi-select.select__menu{
    max-height: 100px;
    
  }

}
.css-15k3avv.select__menu{
  width: 273px;
  margin-left: 30px;
  border-radius: 6px;
// background: #F4F6F6;
border-radius: 6px;
border: 0.5px solid #DBDBDB;
background: #FFF;
font-family: 'open-sans-semibold';
}
.modal-open .modal .modal-body .form-control{
  height: 30px !important;
  box-shadow: none !important;
  padding-top: 22px;
  font-family: 'open-sans-regular';
font-size: 14px;
font-style: normal;
font-weight: 400;
letter-spacing: 0.35px;
}
#holiday-modal{
  .title{
    font-size: 22px;
    line-height: 23px;
    display: block;
    margin-top: 20px;
    font-weight: 700;
    font-style: normal;
    font-family: 'open-sans-bold';
    letter-spacing: 1px;
  }
  .profile-name h5{
    color: #4A4A4A;
    text-align: center;
    font-family: 'open-sans-semibold';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .profile-name h6{
    color: #9B9B9B;
    text-align: center;
    font-family: 'open-sans-semibold';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: justify;
  }
  .profile-details h5 , .Already-Scheduled h5{
    color:  #31CCBC;
    font-family: 'open-sans-bold';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
  }
}
.modal-open .modal .title{
  font-size: 22px;
  line-height: 23px;
  display: block;
  margin-top: 20px;
  font-weight: 700;
  font-style: normal;
  font-family: 'open-sans-bold';
  letter-spacing: 1px;
}
.DayPicker{
  font-size: 1.2rem;
  outline: none;
}

.event-view{
  padding-top: 40px;
  margin-bottom: 1px;
  .txt-event{
    font-size: 22px;
    line-height: 30px;
    color: $color_D0021B;
    margin-bottom: 15px;
  }
  .no-event{
    color: $color_grey;
  }
  .even-row{
    border-bottom: 1px solid $color_DDDCDB;
    margin-bottom: 10px;
    margin-top: 4px;
    min-height: 40px;
    span{
      font-size: 18px;
      line-height: 40px;
      min-height: 40px;
      color: $color_4A4A4A;
    }
  }
}
.event-view-popup{
  padding-top: 0 !important;
  .login_btn_login{
    margin-top: 0px;
  }
}

.basic-multi-select .select__value-container--is-multi {
  position: absolute !important;
  padding: 0;
}
.basic-multi-select .select__control--is-focused .select__value-container--is-multi{
  position: absolute !important;
  left: -70%;
  padding: 0;
  width: 273px;
}
.basic-multi-select .select__control--is-focused .select__value-container--is-multi .select__placeholder{
  position: absolute !important;
  width: 273px;
  margin-left: 70%;
}
.basic-multi-select .select__control--is-focused{
  width: 273px;
}
.basic-multi-select .select__value-container.select__value-container--is-multi.select__value-container--has-value{
  position: absolute !important;
  margin: 40px 70px 0 0 !important;
  padding: 0;
}
.basic-multi-select .select__control--is-focused .select__placeholder{
  top: calc((100% - 39px) / 2) !important;
}
.basic-multi-select .select__placeholder {
  top: 17px !important;
}
.css-fhpqww{
  transform: scale(0.65);
}

.css-1267l58-MuiButtonBase-root-MuiIconButton-root{
  right: 95px !important;
}

.css-92nr1f-MuiButtonBase-root-MuiIconButton-root{
  left:-77px !important
}

.css-1qixffb-MuiButtonBase-root-MuiIconButton-root{
  left:-77px !important
}

.css-1s220mh-MuiButtonBase-root-MuiIconButton-root{
  right: 99px !important;
}

.css-epd502{
  width:190px !important;
  height:190px !important;
  }
  
  .css-u791bf-MuiPickersArrowSwitcher-root-MuiClockPicker-arrowSwitcher{
  right: 3px !important;
  top: 9px !important;
  }
  .css-fhpqww{
  margin-top:-4px !important;
  margin-left: 11px !important;
  }
  
  .css-eziifo {
      margin-right: 174px !important;
  }
  .css-cysikj {
    left: -77px !important;
}
.css-sfp64 {
    right: 95px !important;
}
.css-3h73n3 {
    left: -77px !important;
}
.css-ag8lru {
    right: 99px !important;
}

.event-schedule input {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85) !important;
  // opacity: 0.6 !important;
  line-height: 19px;
  font-family: 'open-sans-regular' !important;
  width: 272px !important;
  border: none;
  padding: 18px 10px 18px 20px;
  padding-left: 15px !important;
}

.event-schedule fieldset{
  display: none;
}
@media screen and (max-width: 1310px) {
  .calendar-view{
    // .button-not-resp {
    //
    // }
    .button-resp {
      display: flex !important;
    }
  }
}
.open-sans-regular{
  font-family: open-sans-regular;
}

.basic-multi-select .select__control .select__placeholder{
  color: rgba(107, 105, 105, 0.85);
  font-family: "open-sans-regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.35px;
}
.modal-content .create_event_modals-body #name{
  padding-left: 15px !important;
  opacity: 0.6;
}
.modal-open .modal.holiday-request .modal-content,.modal-open .modal.holiday-request .modal-dialog,.modal-open .modal.holiday-request .modal-body{
  width: 933px !important;
}
.modal.holiday-request .accept-btn,.modal.holiday-request .reject-btn{
  justify-content: center;
  margin-top: 20px;
  border-radius: 10px;
  margin: 15px;
}

.staff-hours.calendar-view .search-view {
  display: inline-flex;
  float: right;
  margin-right: 75px;
}
.popover{
  max-width: none;
  width:auto !important;
}

.popover.bottom > .arrow {
  border-bottom-color: #00000040;
  border-top-width: 0;
  left: 50%;
  margin-left: -11px;
  top: -11px;
}
.popover.bottom > .arrow {
  top: -11px;
  left: 50%;
  margin-left: -11px;
  border-top-width: 0;
  border-bottom-color: #999;
  border-bottom-color: rgba(0,0,0,.25);
}

.popover > .arrow, .popover > .arrow::after {
  border-color: #0000;
    border-bottom-color: rgba(0, 0, 0, 0);
  border-style: solid;
  display: block;
  height: 0;
  position: absolute;
  width: 0;
}

.popover > .arrow {
  border-width: 11px;
    border-top-width: 11px;
}
.DayPicker-Months{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.eventname > h5{
  width: 115px;
  height: 25px;
  font-family: 'open-sans-regular', sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color:#31CCBC;
}
.calendar.modals-body{
  width: 967;
  display: flex;
  justify-content: space-evenly;
  gap: 5px;
  margin: 0px;
  margin-top: 15px;
}
.calendar_create_event.modals-body{
  width: 932px;
  display: flex;
  justify-content: center;
  gap: 64px;
  margin: 0px; 
}
.eventUserName{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.event_button{
  display: flex;
  justify-content: center;
  column-gap: 25px;
  margin-top: 87px;
}
.event_calendar .modal-dialog .modal-content{
  width: 932px !important;
  margin-top: 80px;
  height: 599px;
}
.event-schedule .modal-dialog .modal-content{
width: 932px !important;
height: 599px;
}
.Event_title{
  color: #31CCBC;
  text-align: center;
  font-family: 'open-sans-bold';
  font-size: 22.4px;
  font-style: normal;
  font-weight: 700 ;
  line-height: normal;
  padding-top: 20px;
}
.and-more{
  margin-top: 10px;
  position: absolute;
  left: -241px;
  bottom: 0px;
  right: 0px;
  font-size: 16px;
  font-family: 'open-sans-semibold', sans-serif;
  color: #4a4a4a;
}

.calendar_arrow_icon{
  position: absolute;
  right: 17px;
  background: #F4F6F6;
  top: 9px;
  width: 15px;
}
.calendar_time_arrow_icon{
  position: absolute;
  right: 15px; 
  top: 10px;
  background: #F4F6F6;
}

.Create_Event_details .css-10nd86i .css-vj8t7z{
  width: 273px !important;
  height: 42px !important;
  border-radius: 6px !important;
  background: #F4F6F6;
  margin-left: 12%;
  margin-top: -10%;
}
.css-1492t68.select__placeholder{
  // padding:23px 10px 18px 15px;
  color: rgba(0, 0, 0, 0.85);
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 18.304px; 
letter-spacing: -0.35px;
margin-left: 25px;
font-family: 'open-sans-regular';
}

.Create_event_title{
  color: #31CCBC;
text-align: center;
font-family: 'open-sans-bold';
font-size: 22.4px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-top: 11px;
}
.and{
    position: absolute;
    bottom: 122px;
    left: 33%;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.85);
}

.DayPicker-Caption > div{
  width: 196px;
margin-top: -25px;
color: #31CCBC;
text-align: center;
font-family: 'open-sans-extrabold';
font-size: 18px;
font-style: normal;
font-weight: 800;
line-height: normal;
text-transform: uppercase;
  position: absolute;
}

.month-view .DayPicker-Day.DayPicker-Day--outside > div{
  width: 213px;
  height: 195px;
  text-align: justify;
  color: #cfcfcf;
  font-family: 'open-sans-extrabold';
  font-size: 9px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  padding: 10px;

}
.month-view .DayPicker-Day > div{
  text-align: justify;
  color:  #4A4A4A;
  font-family: 'open-sans-extrabold';
  font-size: 9px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  padding: 10px;
}
.month-view .DayPicker-Day{
position: relative;
}
.month-view .DayPicker-Day > .hasEvent{
  width: 212px;
  height: 202px;
  text-align: justify;
  font-family: 'open-sans-bold';
  font-size: 9px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 10px;
  border: 2px solid #31CCBC;
  border-radius: 2px;
}

.month-view .DayPicker-Week{
  display: flex !important;
  gap: 7.5px;
  padding-top: 13px;
  margin-left: -9px;
}
.month-view .DayPicker-WeekdaysRow{
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  margin-left: -17px;
}
.month-view .DayPicker-Body{
  display: block !important;
}
.DayPicker-Weekdays{
  margin-top: 0px;
}
.month-view .DayPicker-Weekdays{
  display: block;
}
.month_event_name {
  flex-shrink: 0;
  color: #4A4A4A;
  font-family: 'open-sans-extrabold';
  font-size: 9px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  cursor: pointer;
  text-transform: capitalize;
}
.and_more_option{
  font-family: 'open-sans-extrabold';
  position: absolute;
  bottom: 8px;
  right: 7px;
  color: #31CCBC;
  font-family: "Open Sans";
  font-size: 8px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.month_event_time{
  color: #4A4A4A;
  text-align: right;
  font-family:'open-sans-extrabold';
  font-size: 8px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  position: absolute;
  left: 95px;
  margin-top: 1px;
}

.event_continer{
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 10px;
  align-items: center;
  
}
.Create_Event_details .form-create-event .form-input input[type=text]{
  width: 273px !important;
  height: 42px;
  border-radius: 6px;
  position: relative;
  margin-left: -140%;
  background-color: #F4F6F6 !important;
  color: black;
  font-family: 'open-sans-regular';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.35px;
}
.Create_Event_details .form-create-event .date-time-picker{
  width: 273px !important;
  height: 42px;
  border-radius: 6px;
  background: #F4F6F6;
  position: absolute;
  left: 130%;
  top: -58px;
  color: #0000;
  font-family: 'open-sans-regular';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.35px;
}
.Create_Event_details .form-create-event .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl{
  width: 273px !important;
  height: 42px;
  border-radius: 6px;
  background: #F4F6F6;
  position: absolute;
  left: 112%;
}
.create_event_modals-body .css-z3c6am-MuiFormControl-root-MuiTextField-root{
  width: 321px;
}
.css-2o5izw.select__control.select__control--is--focuse{
  margin-left: -75% !important;
  left: -75% !important;
}
.profile-details{
width: 300px;
}
.profile-details2{
  margin-left: -70% !important;
  left: -70% !important;
}
.drop-staff .basic-multi-select .select__value-container.select__value-container--is-multi.select__value-container--has-value {
  margin: 60px 0px 0 0 !important;
}

.css-1hwfws3.select__value-container.select__value-container--is-multi.select__value-container--has-value{
  left: 1% !important;
}
.css-2o5izw.select__control.select__control--is-focused{
  margin-left: 30px;
}
.css-1hwfws3.select__value-container.select__value-container--is-multi.select__value-container--has-value .and-more{
  display: block;
}
.css-1hwfws3.select__value-container.select__value-container--is-multi .and-more{
  display: none;
}
.icon-close2{
  position: absolute;
  top: -5px;
  right: -4px;
  width: 44px;
  height: 44px;
  cursor: pointer;
  padding: 12px;
}
.calendar-view .year-view .DayPicker-Day--today .hasEvent{
  background-color: #31CCBC;
  color: #FFF;
  text-align: center;
  font-family: 'open-sans-bold';
  display: flex;
  align-items: center;
  border-radius: 3px;
  justify-content: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  
}
.calendar-view .year-view .DayPicker-Day--today div{
  width: 24px;
  height: 25px;
  flex-shrink: 0;
  background-color: #31CCBC;
  color: #FFF;
  text-align: center;
  font-family: "open-sans-bold";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  display: flex;
  align-items: center;
  border-radius: 3px;
  justify-content: center;
  line-height: 12.799px;
  
}


.calendar-view .month-view .DayPicker-Day--today .hasEvent{
 
  color: #31CCBC;
  text-align: start;
  font-family: 'open-sans-bold';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  
}
.calendar-view .DayPicker-Day--today , .calendar-view .DayPicker-Day--today > div{
  color: #fff !important;
}
.calendar-view .month-view .DayPicker-Day--today , .calendar-view .month-view .DayPicker-Day--today > div{
  color: #31CCBC !important;
}
//-----------weeks ----------------
.week-days {
  display: flex;
  justify-content: space-between;
  padding-top: 48px;
  gap: 20px;
}
.day-item {
  text-align: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  width: 100px;
  margin: 0 5px;
  background-color: #f9f9f9;
}
.day-name {
  color: #31CCBC;
  font-family:'open-sans-extrabold';
  font-size: 13px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-align: center;
  height: 20px;
  text-transform: uppercase;
}

.day-date {
  font-size: 0.9em;
  color: #666;
}
//---------------
.change_week_value{
  display: flex;
  width: 250px;
  justify-content: space-evenly;
}
.change_daysOfWeek{
  width: 220px;
  height: 25px;
  text-align: center;
}
.change_daysOfWeek > p{
  color: #31CCBC;
  text-align: center;
  font-family: 'open-sans-extrabold';
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
}

.Week_event_details{
  display: flex;
  justify-content: space-between;
  text-align: justify;
}
.event-item{
  width: 200px;
  height: 80px;
  flex-shrink: 0;
  border-radius: 3.6px;
  background: #FFF;
  margin-top: 13px;
  padding: 10px;
  box-shadow: 0px 2px 4px 0px rgba(191, 191, 191, 0.50);
  margin-bottom: 5px;
  position: relative;
  }
 .Week_event_details.image-containers{
  padding-top: 21px;
 }
.week_event-name{
  flex-shrink: 0;
  color: #31CCBC;
  font-family:  'open-sans-bold';
  font-size: 9px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 100px;
  height: 27px;
  margin: 0px;
  text-align: start;
}
.pop-up-event{
color:  #ff832a;
font-family: 'open-sans-regular';
font-size: 6px;
font-style: normal;
font-weight: 400;
line-height: normal;
cursor: pointer;
position: absolute;
right: 6px;
}
.event-time{
  color:  #455154;
  font-family: 'open-sans-semibold';
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  right: 6px;
  }
.staff-avator{
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  border-radius: 16.875px;
}

.avator_0 , .avator_1 , .avator_2{
  margin-left: -5px;
}
.event-container{
  padding-top: 35px;
  text-align: center;
}
.rotate-vertical {
  transform: rotate(-90deg);
  margin-top: 62px;
  color:  #c2bbbb;
  font-family: 'open-sans-extrabold';
  font-size: 22px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  width: inherit;
}

// -----------new 

.event-card{
display: flex;
justify-content: space-evenly;
height: 66px;
align-items: center;
}
.under-line{
  width: 741px;
  height: 0.5px;
  background: #EAEAEA;
  margin-left: 9%;
}
.event-time-title , .event-names , .view-btns{
  color: #31CCBC;
  font-family: 'open-sans-semibold';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}
.event-times , .event-name-titles{
  color: #4A4A4A;
  font-family: 'open-sans-semibold';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  padding-left: 6px;
}
.calendar-view .search-bars{
  height: 74px;
  z-index: 1;
  display: block;
  position: fixed;
  width: -webkit-fill-available;
  background-color: white;
  margin-top: -5px;
}
.multiple-event-pop-up{
  overflow: hidden;
  height: 265px;
  overflow-y: scroll;
  width: 905px;
  margin-top: 30px;
}
.multiple-event-pop-up::-webkit-scrollbar{
  width: 5px;
  height: 20px;
  margin-left: 100px;
}
.multiple-event-pop-up::-webkit-scrollbar-thumb{
  background-color: #e5e4e4;
}
.select2-results__options::-webkit-scrollbar{
  width: 3px;
  border-radius: 6px;
  background-color: white;
}
.select2-results__options::-webkit-scrollbar-thumb{
  background-color: #e5e4e4;
  border-radius: 6px;
}
.year-view .DayPicker-Months{
  margin-top: 46px;
}
.change_icons{
  cursor: pointer;
}

.react-datepicker.react-datepicker--time-only::-webkit-scrollbar{
display: none !important;
}
.create_event_modals-body .select2-container .select2-results__options .select2-results__option{
border-radius: 6px;
font-family: 'open-sans-regular';
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.35px;
display: flex;
flex-direction: column;
padding: 10px 0px 0px 18px;
}
.event_modal_body{
  .btn.disabled{
    pointer-events: visible;
    cursor: not-allowed;
    opacity: 0.65;
  }
}
.create_event_modals-body{
  .select2-container--classic .select2-selection--multiple .select2-selection__choice{
    display: none;
  }
  
  .user-input-icon{
    position: absolute;
    left: 279px;
    top: 15px;
  }
  .custom-display{
    position: absolute;
    top: 18px;
    left: 48px;
    opacity: 0.6;
    color: #18191a9c !important;
    text-transform: capitalize;
    text-align: justify;
    font-family: 'open-sans-regular';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.35px;
    height: 30px !important;
    white-space: nowrap;  
    overflow: hidden;  
    text-overflow: ellipsis;
    width: 184px;
  }
  
  .selected-staff-container{
    padding-left: 41px;
    padding-top: 17px;
    width: 273px;
    text-align: start;
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;

  .circle-image {
    width: 37px;
    height: 37px;
    border-radius: 50%;
    object-fit: cover; /* Ensures the image scales properly within the circle */
    opacity: 0.7;
  }
  .image-container {
    position: relative;
    width: 37px; /* Adjust size as needed */
    height: 37px;
    display: inline-block;
  }

.image-container::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 37px; /* Adjust size as needed */
    height: 37px;
    background: rgb(72 70 70 / 80%); 
    border-radius: 50%;
  }
  .remove-profile {
    position: absolute;
    left: 28%;
    top: 0%;
    background-color: transparent;
    color: #fff;
    font-size: 25px;
    font-weight: bold;
    cursor: pointer;
    width: 37px;
    height: 37px;
    border-radius: 50%;
    z-index: 1;
  }
  
  .remove-profile:hover {
    color: white;
  }
  .select2-results{
    width: 272px;
  }
}
.select2-dropdown.select2-dropdown--above{
  top: 217px;
  }
}
.event_modal_body .form-control.field-form-control, .modal-open .modal .modal-dialog .form-control.field-form-control{
  padding: 0px !important;
  height: 42px !important;
  padding-left: 17px !important;
  border-radius: 6px;
  background: #F4F6F6;
  color: #4A4A4A!important;
  width: 274px;
}
.line-div{
  margin-top: 14px;
  width: 234px;
    height: 1px;
    background: #F2F2F2;
}


@media (min-width:1888px) and (max-width: 1920px) {

  .month-view .DayPicker-WeekdaysRow{
    margin-left: -12px;
  }
  .month_event_name ,.calendar-view .month-view .DayPicker-Day > div , .calendar-view .month-view .DayPicker-Day .hasEvent , .week_event-name, .event-time{
    font-size: 13px;
  }
  .month_event_time{
    left: 152px;
  }
  .month_event_time  , .pop-up-event{
    font-size: 10px;
  }
  .event_continer{
    gap: 10px;
  }
  .and_more_option{
    margin-top: -14px;
    font-size: 11px;
  }
  .calendar-view .month-view .DayPicker-Weekday , .day-name{
    font-size: 16px;
  }
  .event_icon{
    width: 24px !important;
    height: 26px !important;
  }
  .event-item {
    width: 200px;
    height: 95px;
  }
  .Week_event_details.image-containers {
    padding-top: 30px;
}
.week-days{
  width: 1540px;
}
.event_0{
  bottom: 23px !important;
}
.event_1{
  bottom: 56px !important;
}
.event_2{
  bottom: 89px !important;
}
.event_3{
  bottom: 121px !important;
}
}

@media (min-width: 1821px) and (max-width: 1887px) {
  .calendar-view .month-view .DayPicker-Day , .month-view .DayPicker-Day > .hasEvent{
    width: 201px;
    height: 183px;
    
  }
  .month_event_name ,.calendar-view .month-view .DayPicker-Day > div , .calendar-view .month-view .DayPicker-Day .hasEvent , .week_event-name , .event-time{
    font-size: 13px;
  } 
  .month_event_time{
    left: 144px;
  }
  .month_event_time  , .pop-up-event{
    font-size: 10px;
  }
  .event_continer{
    gap: 10px;
  }
  .calendar-view .month-view .DayPicker-Weekday , .day-name{
    font-size: 18px;
  }
  .week-days{
    width: 1470px;
  }
  .event-item {
    width: 195px;
    height: 95px;
}
  .Week_event_details.image-containers {
    padding-top: 30px;
}
}

@media (min-width: 1802px) and (max-width: 1820px) {
.calendar-view .month-view .DayPicker-Day , .month-view .DayPicker-Day > .hasEvent{
  width: 195px;
  height: 178px;
}
.calendar-view .month-view .DayPicker-NavBar .fa-angle-right{
  margin-left: 14%;
}
.month_event_name ,.calendar-view .month-view .DayPicker-Day > div , .calendar-view .month-view .DayPicker-Day .hasEvent , .week_event-name{
  font-size: 13px;
}
.month_event_time{
  left: 138px;
}
.pop-up-event{
  font-size: 10px;
}
.month_event_time , .event-time  {
  font-size: 13px;
}
.calendar-view .month-view .DayPicker-Weekday , .day-name{
  font-size: 18px;
}
.event_continer{
  gap: 10px;
}
.event-item {
  width: 192px;
  height: 95px;
}
.Week_event_details.image-containers {
  padding-top: 33px;
}
}

@media (min-width: 1713px) and (max-width: 1801px) {
.calendar-view .month-view .DayPicker-Day , .month-view .DayPicker-Day > .hasEvent{
  width: 191px;
  height: 172px;
}
.calendar-view .month-view .DayPicker-NavBar .fa-angle-right{
  margin-left: 14.3%;
}
.month_event_name  ,.calendar-view .month-view .DayPicker-Day > div , .calendar-view .month-view .DayPicker-Day .hasEvent , .week_event-name{
  font-size: 13px;
}
.month_event_time{
  left: 134px;
}
.pop-up-event{
font-size: 10px;
}
.month_event_time , .event-time {
  font-size: 13px;
}
.event_continer{
  gap: 10px;
}
.calendar-view .month-view .DayPicker-Weekday , .day-name{
  font-size: 17px;
}
.event-item {
  width: 181px;
  height: 95px;
}
.Week_event_details.image-containers {
  padding-top: 33px;
}
}

@media (min-width: 1600px) and (max-width: 1712px) {
.calendar-view .month-view .DayPicker-Day , .month-view .DayPicker-Day > .hasEvent{
  width: 175px;
  height: 161px;
}
.calendar-view .month-view .DayPicker-NavBar .fa-angle-right{
  margin-left: 16%;
}
.month_event_name  ,.calendar-view .month-view .DayPicker-Day > div , .calendar-view .month-view .DayPicker-Day .hasEvent , .week_event-name{
  font-size: 13px;
}
.month_event_time{
  left: 118px;
}
.pop-up-event{
  font-size: 10px;
}
.month_event_time , .event-time {
  font-size: 13px;
}
.event_continer{
  gap: 10px;
}
.calendar-view .month-view .DayPicker-Weekday , .day-name{
  font-size: 17px;
}
.event-item {
  width: 168px;
  height: 95px;
}
.Week_event_details.image-containers {
  padding-top: 33px;
}
}

@media (min-width: 1540px) and (max-width: 1599px) {
.calendar-view .month-view .DayPicker-Day , .month-view .DayPicker-Day > .hasEvent{
  width: 167px;
  height: 153px;
}
.calendar-view .month-view .DayPicker-NavBar .fa-angle-right{
  right: -1px;
  top: 15px;
}
.calendar-view .month-view .DayPicker-NavBar .fa-angle-left{
  top: 15px;
  left: 3px;
}
.DayPicker-Caption > div{
  margin: -8px;
}

.month_event_name  ,.calendar-view .month-view .DayPicker-Day > div , .calendar-view .month-view .DayPicker-Day .hasEvent , .week_event-name , .event-time{
  font-size: 13px;
}
.pop-up-event{
  font-size: 10px;
}
.month_event_time {
  font-size: 12px;
}
.event_continer{
  gap: 10px;
}
.calendar-view .month-view .DayPicker-Weekday , .day-name{
  font-size: 16px;
}
.event-item {
  width: 161px;
  height: 90px;
}
.Week_event_details.image-containers {
  padding-top: 30px;
}
}
//---------------
@media (min-width: 1480px) and (max-width: 1539px) {
.event-item {
  width: 179px;
}
.calendar-view .month-view .DayPicker-Weekday , .day-name{
  font-size: 15px;
}
}
//===============

@media (min-width: 1441px) and (max-width: 1539px) {
.calendar-view .month-view .DayPicker-Day , .month-view .DayPicker-Day > .hasEvent{
  width: 165px;
  height: 155px;
}

.month_event_name ,.calendar-view .month-view .DayPicker-Day > div , .calendar-view .month-view .DayPicker-Day .hasEvent , .week_event-name , .event-time{
  font-size: 12px;
}
.month_event_time{
  left: 118px;
}
.month_event_time  , .pop-up-event{
  font-size: 9px;
}
.Week_event_details.image-containers {
  padding-top: 12px;
}
.event_continer{
  gap: 10px;
}
.event-item {
  width: 155px;
  height: 70px;
}
.event_icon{
  height: 23px !important;
  width: 18px !important
}
.calendar-view .month-view .DayPicker-NavBar .fa-angle-right{
  right: 12px;
  top: 15px;
}
.calendar-view .month-view .DayPicker-NavBar .fa-angle-left{
  top: 15px;
  left: 3px;
}
.DayPicker-Caption > div{
  margin: -8px;
}

}

@media (min-width: 1404px) and (max-width: 1440px) {
.calendar-view .month-view .DayPicker-Day , .month-view .DayPicker-Day > .hasEvent{
  width: 147px;
  height: 134px;
}
.calendar-view .month-view .DayPicker-NavBar .fa-angle-right{
  top: -1px;
  right: -6px;
}
.month_event_name ,.calendar-view .month-view .DayPicker-Day > div , .calendar-view .month-view .DayPicker-Day .hasEvent , .week_event-name , .event-time{
  font-size: 12px;
}
.month_event_time{
  left: 100px;
}
.month_event_time ,  .pop-up-event{
  font-size: 9px;
}
.event-item {
  width: 152px;
  height: 80px;
}
.rotate-vertical{
  font-size: 20px;
}
.week-days{
  gap: 11px;
}
}
//---------------------------------------------------------------------------------
@media (min-width: 1802px) and (max-width: 1820px) {
.calendar-view .month-view .DayPicker-Day , .month-view .DayPicker-Day > .hasEvent{
  width: 195px;
  height: 178px;
}
.calendar-view .month-view .DayPicker-NavBar .fa-angle-right{
  margin-left: 15%;
}
}
//----------------------------------------------------------------------------------

@media (min-width: 1355px) and (max-width: 1403px) {
.calendar-view .month-view .DayPicker-Day , .month-view .DayPicker-Day > .hasEvent{
  width: 146px;
  height: 133px;
}
.calendar-view .month-view .DayPicker-NavBar .fa-angle-right{
  right: -1px;
  top: 15px;
}
.calendar-view .month-view .DayPicker-NavBar .fa-angle-left{
  top: 15px;
  left: 3px;
}
.DayPicker-Caption > div{
  margin: -8px;
}

.month_event_name  ,.calendar-view .month-view .DayPicker-Day > div , .calendar-view .month-view .DayPicker-Day .hasEvent , .week_event-name, .event-time{
  font-size: 12px;
}
.week-days{
  gap: 16px;
}
.pop-up-event{
  font-size: 9px
}
.month_event_time {
  font-size: 10px;
}
.event-item {
  width: 142px;
  height: 80px;
}

.rotate-vertical{
  font-size: 19px;
}

}

@media (min-width :1312px) and (max-width: 1354px){
.calendar-view .month-view .DayPicker-Day , .month-view .DayPicker-Day > .hasEvent{
  width: 137px;
  height: 125px;
}
.calendar-view .month-view .DayPicker-NavBar .fa-angle-right{
  right: -1px;
  top: 15px;
}
.calendar-view .month-view .DayPicker-NavBar .fa-angle-left{
  top: 15px;
  left: 3px;
}
.DayPicker-Caption > div{
  margin: -8px;
}

.month_event_name  ,.calendar-view .month-view .DayPicker-Day > div , .calendar-view .month-view .DayPicker-Day .hasEvent , .week_event-name, .event-time{
  font-size: 11px;
}
.month_event_time{
  left: 92px;
}
.week-days{
  gap: 14px;
}
.month_event_time  , .pop-up-event{
  font-size: 9px;
}
.week-days {
  width: 1048px;
}
.event-item {
  width: 137px;
  height: 80px;
}
.events-container .day-name{
  width: 92px;
}
.rotate-vertical {
  font-size: 18px;
  margin-left: -27px;
}
 }

@media (min-width :1292px) and (max-width: 1311px) {
  .calendar-view .month-view .DayPicker-Day , .month-view .DayPicker-Day > .hasEvent{
    width: 137px;
    height: 125px;
  }
  .calendar-view .month-view .DayPicker-NavBar .fa-angle-right{
    right: -1px;
    top: 15px;
  }
  .calendar-view .month-view .DayPicker-NavBar .fa-angle-left{
    top: 15px;
    left: 3px;
  }
  .DayPicker-Caption > div{
    margin: -8px;
  }
 
  .month_event_time{
    left: 94px;
  }
  .event-item {
    width: 132px;
    height: 80px;
  }
  .week-days{
    gap: 18px;
  }
  .events-container .day-name{
    width: 92px;
  }
  .rotate-vertical{
    font-size: 18px;
    margin-left: -23px;
  }
}
@media (min-width :1288px) and (max-width: 1291px) {
  .calendar-view .month-view .DayPicker-Day , .month-view .DayPicker-Day > .hasEvent{
    width: 137px;
    height: 125px;
  }
  .month-view .DayPicker-WeekdaysRow{
    margin-left: -14px;
  }
  .calendar-view .month-view .DayPicker-NavBar .fa-angle-right{
    right: -1px;
    top: 15px;
  }
  .calendar-view .month-view .DayPicker-NavBar .fa-angle-left{
    top: 15px;
    left: 3px;
  }
  .DayPicker-Caption > div{
    margin: -8px;
  }
 
  .month_event_time{
    left: 95px;
  }
  .events-container{
    width: 170px;
  }
  .rotate-vertical{
    font-size: 17px;
    margin-left: -6px;
  }
  .week-days {
    width: 1025px;
  }
  .event-item {
    width: 130px;
    height: 80px;
  }
}

@media (min-width :1284px) and (max-width: 1287px) {
  .calendar-view .month-view .DayPicker-Day , .month-view .DayPicker-Day > .hasEvent{
    width: 137px;
    height: 125px;
  }
  .month-view .DayPicker-WeekdaysRow{
    margin-left: -16px;
  }
  .calendar-view .month-view .DayPicker-NavBar .fa-angle-right{
    right: -1px;
    top: 15px;
  }
  .calendar-view .month-view .DayPicker-NavBar .fa-angle-left{
    top: 15px;
    left: 3px;
  }
  .DayPicker-Caption > div{
    margin: -8px;
  }
  .month_event_time{
    left: 95px;
  }
  .event-item {
    width: 127px;
    height: 80px;
  }
  .events-container{
    width: 170px;
  }
  .rotate-vertical{
    font-size: 17px;
    margin-left: -7px;
  }
}

@media (min-width :1270px) and (max-width: 1283px) {
  .calendar-view .month-view .DayPicker-Day , .month-view .DayPicker-Day > .hasEvent{
    width: 132px;
    height: 121px;
  }
  .calendar-view .month-view .DayPicker-Weekday{
    width: 136px;
  }
  .month-view .DayPicker-WeekdaysRow{
    margin-left: -15px;
  }
  .calendar-view .month-view .DayPicker-NavBar .fa-angle-right{
    right: -1px;
    top: 15px;
  }
  .calendar-view .month-view .DayPicker-NavBar .fa-angle-left{
    top: 15px;
    left: 3px;
  }
  .DayPicker-Caption > div{
    margin: -8px;
  }
  .month_event_time{
    left: 89px;
  }
  .event-item {
    width: 121px;
    height: 72px;
  }
  .week-days{
    width: 106%;
  }
  .Week_event_details{
    text-align: justify;
  }
  .avator_1 , .avator_2 , .avator_0{
    margin-top: -8px;
  }
  .events-container .day-name{
    width: 92px;
  }
  .events-container{
    width: 170px;
  }
  .rotate-vertical{
    font-size: 17px;
    margin-left: -26px;
  }
}

// --------------------
@media (min-width: 1240px) and (max-width: 1269px) {
  .calendar-view .month-view .DayPicker-Day, .month-view .DayPicker-Day > .hasEvent {
    width: 127px;
    height: 116px;
}
.calendar-view .month-view .DayPicker-Weekday{
  width: 136px;
}
.calendar-view .month-view .DayPicker-NavBar .fa-angle-right{
  right: -1px;
  top: 15px;
}
.calendar-view .month-view .DayPicker-NavBar .fa-angle-left{
  top: 15px;
  left: 3px;
}
.DayPicker-Caption > div{
  margin: -8px;
}
.event_continer > div > .event_icon{
  width: 11px;
  height: 10px;
}
.month_event_time{
  left: 84px;
}
.event-item {
  width: 123px;
  height: 86px;
}
.week-days{
  width: 106%;
}
.avator_0 , .avator_1 , .avator_2{
  margin-top: -8px;
}
.events-container .day-name{
  width: 92px;
}
.events-container{
  width: 170px;
}
.rotate-vertical{
  font-size: 17px;
  margin-left: -26px;
}

}

@media (min-width: 1195px) and (max-width: 1239px) {
  .calendar-view .month-view .DayPicker-Day, 
  .month-view .DayPicker-Day > .hasEvent {
    width: 123px;
    height: 112px;
  }
  .calendar-view .month-view .DayPicker-Weekday{
    width: 132px;
  }
  .calendar-view .month-view .DayPicker-NavBar .fa-angle-right{
    right: -1px;
    top: 15px;
  }
  .calendar-view .month-view .DayPicker-NavBar .fa-angle-left{
    top: 15px;
    left: 3px;
  }
  .DayPicker-Caption > div{
    margin: -8px;
  }
  .month_event_time{
    left: 80px;
  }
  .event-item {
    width: 124px;
    height: 86px;
  }
  .week-days{
    width: 106%;
  }
  .avator_1 , .avator_2 , .avator_0{
    margin-top: -8px;
  }
  .rotate-vertical{
    font-size: 17px;
    margin-left: -14px;
  }
}
@media (min-width: 1150px) and (max-width: 1194px) {
  .calendar-view .month-view .DayPicker-Day, 
  .month-view .DayPicker-Day > .hasEvent {
    width: 117px;
    height: 107px;
  }
  .calendar-view .month-view .DayPicker-Weekday{
    width: 125px;
  }
  .calendar-view .month-view .DayPicker-NavBar .fa-angle-right{
    right: -1px;
    top: 17px;
  }
  .calendar-view .month-view .DayPicker-NavBar .fa-angle-left{
    top: 17px;
    left: 3px;
  }
  .DayPicker-Caption > div{
    margin: -8px;
  }
  .event_continer > div > .event_icon{
    width: 11px;
    height: 10px;
  }
  .month_event_time{
    left: 66px;
  }
}
@media (min-width: 1100px) and (max-width: 1151px) {
  .calendar-view .month-view .DayPicker-Day, 
  .month-view .DayPicker-Day > .hasEvent {
    width: 110px;
    height: 101px;
  }
  .calendar-view .month-view .DayPicker-Weekday{
    width: 119px;
  }
  .calendar-view .month-view .DayPicker-NavBar .fa-angle-right{
    margin-left: 24%;
    margin-top: -2px;
  }
  .calendar-view .month-view .DayPicker-NavBar .fa-angle-left{
    margin-top: -2px;
  }
  .event_continer > div > .event_icon{
    width: 11px;
    height: 10px;
  }
  .month_event_time{
    left: 60px;
  }
}
@media (min-width: 1050px) and (max-width: 1099px) {
  .calendar-view .month-view .DayPicker-Day, 
  .month-view .DayPicker-Day > .hasEvent {
    width: 101px;
    height: 92px;
  }
  .calendar-view .month-view .DayPicker-Weekday{
    width: 110px;
  }
  .calendar-view .month-view .DayPicker-NavBar .fa-angle-right{
    margin-left: 26%;
    margin-top: -2px;
  }
  .calendar-view .month-view .DayPicker-NavBar .fa-angle-left{
    margin-top: -2px;
  }
  .event_continer > div > .event_icon{
    width: 11px;
    height: 10px;
  }
  .month_event_time{
    left: 60px;
  }
}
@media (min-width: 1016px) and (max-width: 1049px) {
  .calendar-view .month-view .DayPicker-Day, 
  .month-view .DayPicker-Day > .hasEvent {
    width: 97px;
    height: 89px;
  }
  .calendar-view .month-view .DayPicker-Weekday{
    width: 106px;
  }
.calendar-view .month-view .DayPicker-NavBar .fa-angle-right{
  margin-left: 27%;
  margin-top: -2px;
}
.calendar-view .month-view .DayPicker-NavBar .fa-angle-left{
  margin-top: -2px;
}
.event_continer > div > .event_icon{
  width: 11px;
  height: 10px;
}
.month_event_time{
  left: 56px;
}
}