form{
  width: 100%;
  margin-top: 10px;
}

.dropdown{
  //margin-bottom: 15px;
  width: 100%;

  button{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-image: none !important;
    //color: #ababab
  }
  ul{
    width: 100%;
  }
}

.form-control-feedback{
  right: -30px !important;
}

.has-feedback .form-control{
  padding-right: 10px !important;
}

.image-form{
  position: relative;
  margin-right: 15px;
  margin-bottom: 15px;
}
.has-success .form-control{
  //box-shadow: none !important;
}
.form-row{
  display: flex;
}

.close-media{
  position: absolute;
  top: -15px;
  right: -15px;
  font-size: 25px;
  cursor: pointer;
  color: black;
}

.media_upload{
  position: relative;
  height: 32px;
  width: 100px;
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 16px;
  border: 1px solid #ccc;
  cursor: pointer;
  .media_upload_txt{
    font-weight: 700;
    font-size: 12px;
    color: #333;
  }

  .media_upload_input{
    overflow: hidden;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    width: inherit;
  }
}

.media-image{
  width: 70px;
  height: 70px;
  object-fit: cover;
}

.media-video{
  width: 70px;
  height: 70px;
  object-fit: contain;
  background-color: black;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spin-loader{
  position: absolute;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

.media-loader{
  display: flex;
  width: 70px;
  height: 70px;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  .img-loader{
    width: 70px;
    height: 70px;
    object-fit: cover;
    opacity: 0.3;
  }

  .video-loader{
    width: 70px;
    height: 70px;
    object-fit: contain;
    opacity: 0.3;
    background-color: black;
  }
}
p.dateError {
  position: absolute;
  top: 51px;
  color: #FF9494;
}
.css-12ha4i7{
  background-color: #31ccbc !important;
}
.css-7lip4c{
  background-color: #31ccbc !important;
}
.css-118whkv{
  background-color: #31ccbc !important;
  border: 16px solid #31ccbc !important;
}
.css-2ujp1m{
  background-color: #31ccbc !important;
  border: 16px solid #31ccbc !important;
}
.css-a1rc6s{
  background-color: #31ccbc !important;
}
.MuiInputBase-inputAdornedEnd .MuiInputBase-inputAdornedEnd{
    height: 50px !important;
    width: 258px !important;
    color: #808080c9 !important;
    border: none !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}
.css-1laqsz7-MuiInputAdornment-root{
  padding-bottom: 8px;
}

.MuiInputBase-inputAdornedEnd .MuiInputBase-inputAdornedEnd{
  padding:0 !important;
  padding-bottom: 8px !important;
  font-size: 13px !important;
}
.popup-create-roster .MuiOutlinedInput-notchedOutline{
  min-width: inherit !important;
  padding: inherit !important;
  margin: inherit !important;
  border: none !important;
  border-bottom: 1px solid #d1cece !important;
  border-radius: 0 !important;
  // border-radius: 5px !important;
  margin-bottom: 13px !important;
}
.css-j5h6pi-MuiPopper-root{
  margin-left: 20px !important;
}
.css-eziifo{
	margin-right: 31px !important;
}
.css-cysikj{
    background-color: #31ccbc !important;
}
.css-ag8lru{
    background-color: #31ccbc !important;
}
.css-eziifo{
  background-color: #31ccbc1a !important;
}
.css-epd502{
  width: 294px !important;
}
.css-1v2gfp5{
  font-size: 1rem;
}
.manualInput-time-shift .MuiOutlinedInput-input {
  padding-left: 0px !important;
  width: 123px !important;
}