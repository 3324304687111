@import "../../style/font.css";
.tasks-view {
  background: #ffffff !important;
  border-radius: 3px;
  padding: 80px 35px 70px;
}
.tasks-view .txt-not-task {
  font-size: 45px;
  line-height: 75px;
  font-family: open-sans-semibold;
  color: rgba(49, 204, 188, 0.43);
  text-align: center;
  margin: auto;
}
.tasks-view span {
  font-size: 21px;
  position: relative;
  padding: 10px 0px;
}
.tasks-view span.active {
  border-bottom: 3px solid #31CCBC;
}
.tasks-view span:hover {
  cursor: pointer;
}
.tasks-view .completed_tasks,
.tasks-view .upcoming_tasks {
  color: #4A4A4A;
  font-family: "open-sans-extrabold";
  font-size: 16.38px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.tasks-view .task-list-view .task-empty-section-content {
  min-height: 415px;
}
.tasks-view .task-list-view .task_shortdes {
  margin: 0px;
}
.tasks-view .task-list-view .member-information {
  margin-left: 3px;
  width: 99%;
  height: 135px;
}
.tasks-view .task-list-view .info-in {
  padding: 0px 37px 0px 12px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  height: 135px;
  width: 100%;
  fill: #FFF;
  gap: 13%;
}
.tasks-view .task-list-view .task-details {
  display: flex;
  gap: 39px;
  width: 50%;
}
.tasks-view .task-list-view .task-details .task-image {
  width: 110px;
  height: 110px;
}
.tasks-view .task-list-view .task-details div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}
.tasks-view .task-list-view .task-details .task-title {
  color: #31CCBC;
  font-family: "open-sans-bold";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.tasks-view .task-list-view .task-details .task-instructions {
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 2 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 364px;
  color: #4A4A4A;
  font-family: "open-sans-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.tasks-view .task-list-view .task-user-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 129px;
  gap: 8px;
  width: 50%;
}
.tasks-view .task-list-view .task-user-details .task-assignee-details {
  display: flex;
  justify-content: space-between;
  gap: 10%;
}
.tasks-view .task-list-view .task-user-details .task-assignee-details .view-button-section {
  padding: 50px 0px;
}
.tasks-view .task-list-view .task-user-details .task-assignee-details .assignee-image-section {
  position: relative;
}
.tasks-view .task-list-view .task-user-details .task-assignee-details .assignee-image-section img.users-profiles.second, .tasks-view .task-list-view .task-user-details .task-assignee-details .assignee-image-section img.users-profiles.third, .tasks-view .task-list-view .task-user-details .task-assignee-details .assignee-image-section img.users-profiles.fourth {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.tasks-view .task-list-view .task-user-details .task-assignee-details .assignee-image-section img.users-profiles.second {
  left: 16%;
}
.tasks-view .task-list-view .task-user-details .task-assignee-details .assignee-image-section img.users-profiles.third {
  left: 26%;
}
.tasks-view .task-list-view .task-user-details .task-assignee-details .assignee-image-section img.users-profiles.fourth {
  left: 37%;
}
.tasks-view .task-list-view .task-user-details .task-assignee-details .assignee-image-section .plus_add.assignee-count {
  padding: 0px 0pc 0px 79px;
  color: #4A4A4A;
  font-family: "open-sans-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.tasks-view .task-list-view .task-user-details .task-assignee-details .assignee-image-section .users-profiles.second {
  position: absolute;
  top: 0%;
  left: 7%;
}
.tasks-view .task-list-view .task-user-details .task-assignee-details .task-assignee-section {
  display: flex;
  gap: 8px;
  flex-direction: column;
  padding: 14px 0px;
}
.tasks-view .task-list-view .task-user-details .users-profiles {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}
.tasks-view .task-list-view .task-user-details .assigned-staff-name {
  color: #4A4A4A;
  font-family: "open-sans-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 255px;
  display: block;
}
.tasks-view .task-list-view .task-user-details .tbc-time, .tasks-view .task-list-view .task-user-details .task-status-completed {
  color: #EC6E68;
  font-family: "open-sans-semibold";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.tasks-view .task-list-view .task-view-btn p {
  color: #31CCBC;
  font-family: "open-sans-bold";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0px;
}
.tasks-view .task-list-view .task-view-btn-delete p {
  color: #EC6E68;
  font-family: "open-sans-bold";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.tasks-view .task-list-view .task-attachments-profile {
  width: 110px;
  height: 110px;
  border-radius: 2.5px;
}
.tasks-view .task-list-view .task_shortdes {
  font-size: 18px;
  color: #4A4A4A;
  line-height: 25px;
  max-height: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}
.tasks-view .task-list-view .completed {
  font-weight: bold;
  font-family: open-sans-bold;
  color: #31CCBC;
}
.tasks-view .left_images {
  min-width: 100px;
  position: relative;
  width: 240px;
  min-width: 240px;
  height: 100%;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}
.tasks-view .left_images img {
  border-radius: 7px;
}
.tasks-view .left_images .image-3,
.tasks-view .left_images .image-4 {
  height: 50%;
  width: 63px;
  padding: 3px;
}
.tasks-view .left_images .image-2 {
  height: 70%;
  padding: 3px;
  margin-right: 8px;
  border-radius: 8px;
}
.tasks-view .left_images .image-1 {
  height: 100%;
  float: left;
  padding: 2px;
  border-radius: 10px;
}
.tasks-view .left_images .one_img.wrap_key_0 img {
  width: 100%;
}
.tasks-view .left_images .one_img.wrap_key_1 img {
  width: 50%;
}
.tasks-view .left_images .wrap_left_img_5 img,
.tasks-view .left_images .wrap_left_img_4 img,
.tasks-view .left_images .wrap_left_img_3 img,
.tasks-view .left_images .wrap_left_img_2 img {
  width: 50%;
  float: left;
  padding: 5px;
  border-radius: 15px;
}
.tasks-view .left_images .wrap_left_img_1 img {
  width: 100% !important;
}
.tasks-view .left_images .plus_add {
  position: absolute;
  bottom: 3px;
  left: 66px;
  width: 57px;
  height: 54px;
  font-size: 25px;
  color: white;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  justify-content: center;
}
.tasks-view .shadow-1 {
  margin-bottom: 12px;
  padding: 10px;
  position: relative;
}
.tasks-view span.btn_task_delete {
  position: absolute;
  right: 15px;
  top: 0;
}
.tasks-view span.btn_task_delete img {
  width: 20px;
  height: 20px;
}

.tasks-view-detail-page .task-detail-page-section {
  padding: 0px 50px 0px 26px;
}
.tasks-view-detail-page .task-detail-page-section .header-container {
  width: 80%;
}
.tasks-view-detail-page .task-detail-page-section .task-view-button-section {
  display: flex;
  justify-content: right;
  padding: 20px 0px 0px 0px;
}
.tasks-view-detail-page .task-detail-page-section .task-view-button-section .task-content-section {
  padding: 0px 0px 0px 10px;
}
.tasks-view-detail-page .task-detail-page-section .task-name-title {
  margin-bottom: 40px;
}
.tasks-view-detail-page .task-detail-page-section .task-note-desc {
  padding-bottom: 60px;
  min-height: 180px;
  font-size: 16px;
  font-weight: 400;
  font-family: "open-sans-regular";
}
.tasks-view-detail-page .task-detail-page-section .task-sub-details {
  display: flex;
  width: 100%;
  gap: 15%;
}
.tasks-view-detail-page .task-detail-page-section .task-sub-details .task-sub-description table tr td {
  vertical-align: middle;
  padding: 20px 20px;
  white-space: nowrap;
}
.tasks-view-detail-page .task-detail-page-section .task-sub-details .task-sub-description table tr {
  height: 85px;
}
.tasks-view-detail-page .task-detail-page-section .task-sub-details .task-sub-image {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 35px;
  margin-left: auto;
  margin-right: 0;
  height: 100%;
}
.tasks-view-detail-page .task-detail-page-section .task-sub-details .task-sub-image .individual-image {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 12.5px;
  width: 150px;
  height: 150px;
}
.tasks-view-detail-page .task-detail-page-section .task-sub-details .task-sub-image .individual-image img {
  object-fit: cover !important;
}
.tasks-view-detail-page .task-detail-page-section .task-sub-details .lable-name {
  color: #31CCBC;
  font-family: "open-sans-semibold";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  width: 180px;
}
.tasks-view-detail-page .task-detail-page-section .task-sub-details .label-desciption {
  color: #4A4A4A;
  font-family: "open-sans-semibold";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  width: 161px;
}
.tasks-view-detail-page .task-detail-page-section .task-sub-details .image-section {
  padding-bottom: 45px;
}
.tasks-view-detail-page .task-detail-page-section .task-sub-details .task-images-individual {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  border-radius: 12.295px;
  box-shadow: 0px 3px 7px 0px #DDDCDC;
}

.task-create-form-section {
  padding: 95px 35px 0px 50px;
}
.task-create-form-section .task-uploade-image-container.upload-image-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 35px;
  width: 350px;
  margin-left: auto;
  margin-right: 0;
}

.MuiDivider-root.MuiDivider-fullWidth {
  display: none;
  border: none !important;
}

.create-task {
  /* Custom arrow */
  /* Dropdown wrapper styling */
}
.create-task .form-create-task {
  padding: 0;
}
.create-task .form-create-task .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
  width: 62px;
}
.create-task .form-create-task .form-control {
  padding: 0;
  box-shadow: none;
}
.create-task .form-create-task .date-time-picker .form-control {
  display: flex;
  align-items: center;
}
.create-task .button-view {
  position: inherit !important;
  justify-content: flex-end;
  align-items: center;
  height: 80px !important;
}
.create-task .button-view button {
  top: 6px;
  right: 10px;
  width: 240px !important;
  height: 35px !important;
  line-height: 35px !important;
  border-radius: 6px;
  border: 0px !important;
}
.create-task .button-view .btn-add-task {
  height: 54px !important;
  width: 232px !important;
}
.create-task .txt-note-task {
  height: 180px;
  background-repeat: repeat-y;
  background-size: 100% 100%, 100% 100%, 100% 45px;
  background-attachment: local;
  border: none !important;
  outline: none !important;
  padding: 0 !important;
  resize: none;
}
.create-task .form-column {
  width: 45%;
  min-width: 45%;
  max-width: 45%;
  margin-bottom: 50px;
}
.create-task .form-control {
  background-color: transparent;
  transition: none;
  color: #9B9B9B;
  outline: none;
  height: 40px;
  border-radius: 0;
  border-width: 0 0 1px 0 !important;
  border-color: #F4F6F6 !important;
  box-shadow: none;
}
.create-task .drop-staff {
  width: 100%;
  max-height: 315px;
}
.create-task .drop-staff .list-staff-choose {
  margin: 10px 0 0 -5px;
  width: calc(100% + 10px);
  max-height: 275px;
  overflow-y: auto;
}
.create-task .drop-staff .list-staff-choose .fa-times {
  width: 20px;
  height: 20px;
  color: #D0021B;
  position: absolute;
  top: 1px;
  right: 0px;
  line-height: 20px;
  cursor: pointer;
}
.create-task .drop-staff .avatar-staff {
  width: 100%;
  height: 100%;
}
.create-task .drop-staff .name-staff {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 11px !important;
  padding: 5px !important;
  line-height: 22px !important;
  font-size: 12px;
  font-family: open-sans-extrabold;
  font-weight: 300;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
}
.create-task .select__control {
  align-items: flex-start;
  justify-content: flex-end;
}
.create-task .select__control .select__value-container--is-multi {
  margin: 40px 0 0 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.create-task .select__control .select__multi-value__remove {
  position: absolute;
  right: 5px;
  top: 12px;
  width: 12px;
  height: 12px;
  color: white;
  background: rgb(255, 0, 0);
  border-radius: 12px;
  padding: 0;
}
.create-task .select__control .select__multi-value {
  margin: 0;
  width: 110px;
  min-width: 102px;
  background: no-repeat;
}
.create-task .select__control .select__placeholder {
  top: calc((100% - 39px) / 2);
}
.create-task .select__control,
.create-task .Select-control,
.create-task .Select-placeholder,
.create-task .Select-input {
  height: 30px;
  min-height: 30px !important;
}
.create-task .select__control:hover,
.create-task .Select-control:hover,
.create-task .Select-placeholder:hover,
.create-task .Select-input:hover {
  border-color: hsl(0, 0%, 80%) !important;
  box-shadow: none;
}
.create-task .css-vj8t7z,
.create-task .css-2o5izw {
  border-radius: 0;
  border-width: 0 0 1px 0;
  box-shadow: none;
  border-color: hsl(0, 0%, 80%);
}
.create-task .attach .imgPreview {
  width: 150px;
  height: 150px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}
.create-task .attach .imgPreview .fa-times {
  font-size: 8px;
  position: absolute;
  right: 0;
  top: 0;
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
  border-radius: 100%;
  color: white;
  padding: 5px;
  cursor: pointer;
}
.create-task .attach .imgPreview img,
.create-task .attach .imgPreview input {
  border-radius: 25px;
  padding: 10px;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: contain;
}
.create-task .upload.uploadimg {
  position: absolute;
  left: 0px;
  width: 150px;
  height: 150px;
  top: 0px;
  opacity: 0;
  z-index: 1;
}
.create-task .create-task-btn {
  width: 100%;
  display: flex;
  justify-content: end;
}
.create-task .task-uploade-image-container {
  display: flex;
  flex-direction: column;
  gap: 39px;
}
.create-task .in-active {
  display: none;
}
.create-task .task-upload-image {
  width: 150px;
  height: 150px;
  flex-shrink: 0;
  border-radius: 12.295px;
  position: relative;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1019607843);
}
.create-task .task-upload-image::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 12.295px;
}
.create-task .task-updated-image {
  width: 150px;
  height: 150px;
  border-radius: 12.295px;
}
.create-task .Upload-Photo {
  position: absolute;
  top: 46px;
  left: 27px;
  color: #FFF;
  text-align: center;
  font-family: "open-sans-bold";
  font-size: 13.934px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  z-index: 1;
}
.create-task .attach-up {
  position: absolute;
  top: 73px;
  left: 27px;
  color: #FFF;
  width: 95.082px;
  text-align: center;
  font-family: "open-sans-regular";
  font-size: 10.656px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  z-index: 1;
}
.create-task .task-text-area {
  width: 642px;
  height: 269px;
  border-radius: 6px;
  background: #FAFAFA;
  border: none;
  padding: 20px;
  color: #4A4A4A;
  font-family: "open-sans-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.create-task .create-task-details {
  display: flex;
  justify-content: space-between;
  gap: 49px;
  padding-top: 44px;
}
.create-task .task-text-area-container {
  position: relative;
  height: 269px;
}
.create-task .remaining-characters {
  position: absolute;
  bottom: 15px;
  right: 15px;
  color: #878888;
  font-family: "open-sans-regular";
  font-size: 14px;
  font-style: italic;
  line-height: normal;
}
.create-task .create-task-container .task_title {
  padding-top: 31px;
  color: #31CCBC;
  font-family: "open-sans-extrabold";
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  border: none;
  width: 25%;
}
.create-task select {
  -webkit-appearance: none; /* For Chrome and Safari */
  -moz-appearance: none; /* For Firefox */
  appearance: none;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%234bd9ca' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  padding-right: 2rem; /* Space for custom arrow */
}
.create-task select::after {
  content: "";
  position: absolute;
  right: 1rem;
  top: 50%;
  width: 0.5rem;
  height: 0.5rem;
  border: solid #4bd9ca;
  border-width: 0.15rem 0.15rem 0 0;
  transform: rotate(45deg) translateY(-50%);
  pointer-events: none;
}
.create-task .select-wrapper {
  display: inline-block;
}
.create-task select {
  background-color: white;
}
.create-task .select2-container {
  background-color: #fff !important;
  width: 273px !important;
  height: 34px;
  border-radius: 6px;
}
.create-task .select2-container textarea {
  background-color: #F4F6F6 !important;
  margin-top: 13px !important;
  font-size: 14px !important;
  padding-left: 12px !important;
  font-family: "open-sans-regular" !important;
}
.create-task .select2-container span {
  background-color: #F4F6F6;
  border: none;
  border-radius: 6px;
}
.create-task .select2-container .select2-results__options {
  background: none;
  background-color: transparent;
  border-radius: 3px;
  border: 0.5px solid #DBDBDB;
  background: #FFF;
  padding-top: 8px;
}
.create-task .select2-container .select2-results__options span {
  background: none;
  background-color: transparent;
}
.create-task .select2-container .select2-results__options .select2-results__option {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 19px;
  font-family: "open-sans-regular";
  text-transform: capitalize;
  display: flex;
  flex-direction: column;
  padding: 10px 0px 0px 18px;
}
.create-task .select2-container .select2-results__options .select2-results__option--highlighted, .create-task .select2-container .select2-results__options .select2-results__option--selected {
  background: none;
  background-color: transparent;
  color: #31CCBC;
  font-family: "open-sans-regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -0.35px;
}
.create-task select:hover {
  border-color: #4bd9ca;
}
.create-task .select-wrapper:focus-within::after {
  border-color: #4bd9ca;
}
.create-task .select2-container--classic .select2-selection--multiple .select2-selection__choice {
  display: none;
}
.create-task .back-to-page {
  cursor: context-menu !important;
}
.create-task .back-to-page .back-button-section {
  cursor: pointer !important;
}
.create-task .task-create-form-section .MuiStack-root {
  width: 226px;
}
.create-task .task-create-form-section .MuiStack-root .MuiFormControl-root {
  min-width: auto;
}
.create-task .user-input-icon {
  position: absolute;
  left: 180px;
  top: 11px;
  cursor: pointer;
  padding: 0px 5px;
}
.create-task .custom-display {
  position: absolute;
  top: 12px;
  left: 0px;
  color: #4A4A4A;
  font-family: "open-sans-semibold";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.35px;
  height: 30px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 184px;
}
.create-task .selected-staff-container {
  padding-top: 17px;
  width: 273px;
  text-align: start;
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
}
.create-task .selected-staff-container .image-container {
  position: relative;
  width: 37px; /* Adjust size as needed */
  height: 37px;
  display: inline-block;
}
.create-task .selected-staff-container .image-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 37px; /* Adjust size as needed */
  height: 37px;
  background: rgba(72, 70, 70, 0.8);
  border-radius: 50%;
}
.create-task .selected-staff-container .circle-image {
  width: 37px;
  height: 37px;
  border-radius: 50%;
  object-fit: cover; /* Ensures the image scales properly within the circle */
  border-radius: 50%;
}
.create-task .selected-staff-container .remove-profile {
  position: absolute;
  left: 28%;
  top: 0%;
  background-color: transparent;
  color: #fff;
  font-size: 25px;
  font-weight: bold;
  cursor: pointer;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  z-index: 1;
}
.create-task .selected-staff-container .remove-profile:hover {
  color: white;
}
.create-task .create-task-div {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.create-task .create-task-div .create-task-value fieldset {
  border: none !important;
}
.create-task .create-task-div .create-task-value svg.MuiSvgIcon-root {
  background: none !important;
}
.create-task .create-task-value {
  display: flex;
  align-items: baseline;
}
.create-task .create-task-value .lable {
  width: 180px;
  color: #31CCBC;
  font-family: "open-sans-semibold";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.create-task .create-task-value #completion-div {
  margin-left: -3px;
}
.create-task .create-task-value .value {
  color: #4A4A4A;
  font-family: "open-sans-semibold";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.create-task .full-height {
  height: 350px;
  margin-top: -26px;
}
.create-task .select2-container span {
  background-color: #fff;
}
.create-task .select2-container--classic .select2-search--inline .select2-search__field {
  display: none;
}
.create-task .select2-selection.select2-selection--multiple {
  border: none;
}
.create-task .form-input {
  width: 300px;
}
.create-task #taskTitle {
  color: gray;
  font-style: italic;
}
.create-task .lazy-image.avatar-image.lazy-image--fade-in {
  position: absolute;
  top: 0px;
  height: 150px;
  width: 150px;
}
.create-task .form-control.field-form-control {
  background: transparent !important;
}
.create-task .form-input {
  padding: 0px;
  background: transparent !important;
}
.create-task .css-187w9pk-MuiSvgIcon-root {
  background: none;
}
.create-task .css-187w9pk-MuiSvgIcon-root:hover {
  background-color: transparent !important;
}
.create-task .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  justify-content: space-between;
  padding-right: 22px !important;
}
.create-task .css-1pqe5oj-MuiPopper-root-MuiPickersPopper-root.MuiPaper-root {
  margin-left: -10px !important;
}

.form-control.field-form-control, .modal-open .modal .modal-dialog .form-control.field-form-control {
  padding: 0px !important;
  height: 42px !important;
  border-radius: 6px;
  background: #F4F6F6;
  color: #181818 !important;
}

.line-div {
  margin-top: 14px;
  width: 234px;
  height: 1px;
  background: #F2F2F2;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPickersPopper-paper.css-z17wx0-MuiPaper-root-MuiPickersPopper-paper {
  margin-left: -9px !important;
}

.task-btns-container {
  margin-top: 8%;
  display: flex;
  justify-content: end;
  gap: 23px;
}

.task-detail-btn {
  width: 144.171px;
  height: 32.64px;
  text-align: center;
  font-family: "open-sans-semibold";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.task-detail-profile {
  text-align: end;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.task-detail-profile img {
  width: 150px;
  height: 150px;
  flex-shrink: 0;
  border-radius: 12.295px;
  box-shadow: 0px 3px 7px 0px #DDDCDC;
}

.task-details-div {
  display: flex;
  gap: 58px;
  padding-top: 44px;
  padding-top: 44px;
  color: #4A4A4A;
  font-family: "open-sans-regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.task-detail-note {
  width: 639px;
  text-align: justify;
}

.task-avatar-staff {
  width: 45.352px;
  height: 45.352px;
  border-radius: 50%;
  margin-left: -5px;
  flex-shrink: 0;
  filter: drop-shadow(0px 1.458px 4.373px #DDDCDC);
}

.my-team.my-team-detail .about-user-companys {
  padding-top: 94px;
}

.about-user-companys .details div span {
  width: 312px;
  color: #4A4A4A;
  font-family: "open-sans-semibold";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.task-details-titles {
  color: #31CCBC;
  font-family: "open-sans-extrabold";
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-top: 31px;
  text-transform: capitalize;
}

.background-grey {
  background: #F4F6F6 !important;
  border: 0px;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.toolbar {
  padding: 16px 45px;
  background-color: #fff;
  margin-bottom: 18px;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}

.my-team-detail.create-task.row {
  margin: 0;
}

.toolbar .toolbar-image {
  width: 45px;
  height: 45px;
  border-radius: 100%;
  overflow: hidden;
}

.toolbar .toolbar-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.toolbar .toolbar-name {
  margin-left: 62px;
  display: flex;
  align-items: center;
}

.toolbar .toolbar-name h2 {
  margin: 0;
  color: #4A4A4A;
  font-size: 16px;
  font-family: Open-Sans-regular;
  font-weight: 600;
  letter-spacing: 0.612px;
}

.toolbar .toolbar-name img {
  width: 10px;
  transform: rotate(-92deg);
  margin-left: 15px;
}

.my-team .back-to-team {
  width: 25%;
  z-index: 999;
  margin-bottom: 0;
}

.my-team .title-section {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}

.task-name {
  position: absolute;
  z-index: 0;
  top: 0;
}

.my-team .form-create-task {
  margin-top: 0;
}

.my-team.create-task .my-team-detail.create-task .form-create-task input {
  width: 190px !important;
  height: 50px;
  color: #4A4A4A !important;
  font-family: "open-sans-semibold";
  font-size: 18px !important;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.my-team.create-task .my-team-detail.create-task .css-v0pjtz-MuiStack-root {
  padding: 0px;
}

.create-task .form-column.task-detail,
.create-task .form-column.assign-staff {
  padding: 0 48px;
}

.create-task .form-column.task-detail .form-input {
  background-color: transparent !important;
}

.instruction-text-area {
  border-radius: 6px !important;
  background: #F4F6F6;
  margin-bottom: 54px;
}

.task-image-show-pop-up {
  width: 680px;
  height: 383px;
  flex-shrink: 0;
  aspect-ratio: 680/383;
}

.image-clike {
  cursor: pointer;
}

#task-details-images .modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
}
#task-details-images .modal-body .task-page-pop-up-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
#task-details-images .modal-content {
  width: 912px !important;
  height: 485px;
  padding: 20px !important;
}

.attachment .title.imgPreview {
  width: 100%;
  border-radius: 6px;
  background: #F4F6F6;
}

.attachment .drag {
  margin-top: 27px;
  color: #C4C4C4;
  font-size: 12px;
  font-family: Open-Sans-Semibold;
  font-weight: 600;
  float: left;
  width: 100%;
}

.my-team-detail .member-view .button-view {
  margin-top: 0;
}

.my-team.my-team-detail .member-view .button-view {
  font-family: open-sans-regular !important;
  color: #FFF !important;
  text-align: center !important;
  font-size: 11.52px;
}

.display_col.main {
  padding: 21px 33px 57px 13px;
}

.create-task .attach .imgPreview {
  display: flex;
  justify-content: center;
}

.my-team.my-team-detail .upload-img {
  max-width: 76px;
  position: static !important;
}

.create-task .txt-note-task {
  height: 234px;
}

.drop-staff svg {
  fill: #48d8c9;
}

.drop-staff .select__indicator-separator {
  display: none;
}

.drop-staff .select__dropdown-indicator {
  padding: 0 8px;
}

.create-task .select__control,
.create-task .Select-control,
.create-task .Select-placeholder,
.create-task .Select-input {
  min-height: 50px !important;
  height: 50px;
}

.create-task .select__control .select__value-container--is-multi {
  margin: 0px;
  padding: 12px 15px 19px 17px;
}

.create-task .basic-multi-select .select__placeholder {
  top: 25px !important;
}

.create-task .basic-multi-select .select__control--is-focused .select__placeholder {
  display: none;
}

.create-task .select__menu {
  border-radius: 6px;
  border: 0.5px solid #DBDBDB !important;
  outline: none;
  background: #FFF;
}

.create-task .menu-left-action.btn-add-task {
  font-family: "open-sans-regular";
}

.drop-staff .basic-multi-select .select__value-container.select__value-container--is-multi.select__value-container--has-value {
  margin: 60px 0 0 0 !important;
}

.task-fullview {
  min-height: calc(100vh - 105px);
}

.tasks-view .txt-not-task.no-content {
  line-height: 75px;
  font-size: 32px;
}

.create_task_container .member-view {
  padding: 25px 50px 0 15px !important;
}
.create_task_container .create-task .button-view .btn-add-task {
  width: 320px !important;
}

.drop-staff .css-y190se.select__menu {
  width: 418px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 0.5px solid #DBDBDB;
  background: #FFF;
  cursor: pointer;
}

.drop-staff .css-y190se div div {
  background-color: #FFF;
  width: 400px;
  margin-top: 10px;
  border-bottom: 0.1px solid #eeeeee;
  margin-left: 10px;
  color: #4A4A4A;
  font-family: "open-sans-semibold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.35px;
}

.drop-staff .css-y190se div div:hover {
  color: #31CCBC;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -0.35px;
  font-family: "open-sans-extrabold";
  cursor: pointer;
}

.drop-staff .css-2o5izw.select__control.select__control--is-focused {
  border: none;
}

.drop-staff .css-2o5izw.select__control.select__control--is-focused .css-1hwfws3.select__value-container {
  margin-left: -5px;
}

.drop-staff .css-1492t68.select__placeholder {
  margin: 0px;
}

.drop-staff .css-vj8t7z.select__control {
  margin-left: 23px;
}

.drop-staff .css-jwv7en.select__control.select__control--is-focused {
  border: none;
  width: 418px;
}

.drop-staff .css-jwv7en.select__control.select__control--is-focused:hover {
  border: none;
}

.task-page-scrool::-webkit-scrollbar {
  width: 3px;
  border-radius: 10px;
  background-color: white;
}

.task-page-scrool::-webkit-scrollbar-thumb {
  background-color: #bfc0c0;
  border-radius: 10px;
}

.my-team.my-team-detail.create-task.task-detail .about-user-companys .details {
  align-items: center;
  display: flex;
}

.task-section-new {
  display: flex;
  gap: 98px;
}

