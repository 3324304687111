@use '../../style/color.scss' as * ;
@import "../../style/font.css";

hr {
  border: 2px dashed $color_def !important;
}

@media screen and (max-width: 480px) {
  .register .txt-condensed-extra-bold {
    font-size: 27px;
  }
}