@use '../../style/color.scss' as * ;
@import "../../style/font.css";

.staff-calendar{
  font-family: Avenir;
  font-weight: 400;
  .time-tab{
    line-height: 30px;
    font-size: 16px;
    color: #4A4A4A;
    font-family: 'open-sans-regular';
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    span{
      margin: 0 20px;
      cursor: pointer;
    }
    .active{
      border-bottom: 3px solid $color_def;
      color: #4A4A4A;
      font-family:'open-sans-semibold';
      font-size: 16.38px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  .time-scroll{
    font-size: 16px;
    color: $color_def;
    line-height: 22px;
    min-width: 260px;
    align-items: center;
    font-family: Avenir-Medium;
    .fa{
      margin: 0 15px;
      font-size: 25px;
      cursor: pointer;
    }
  }
  .calendar-item{
    margin: 10px;
  }
  .calendar-view{
    flex-wrap: wrap;
  }
  .week-view, .month-view{
    //margin-top: 40px;
    flex-wrap: wrap;
    margin: 40px -10px 0 -10px;
  }

  .month-view{
    flex-wrap: wrap;
    //flex-direction: ;
  }

  .day-item{
    width: 144px;
    height: 144px;
    border-radius: 10px;
    margin: 10px;
    font-family: Avenir-Medium;
    .txt-day{
      color: #4A4A4A;
      text-align: center;
      font-family: 'open-sans-extrabold';
      font-size: 20px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
    }
    .txt-hours{
      font-size: 42px;
      line-height: 57px;
      color: $color_def;
    }
    .txt-unit{
      font-size: 15px;
      line-height: 20px;
      color: $color_def;
      font-family:"open-sans-regular";
    }
  }
}
