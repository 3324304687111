@use '../../style/color.scss' as * ;
@import "../../style/font.css";

.staff-detail{


  .txt-name {
    color: #00CDAC;
    font-family: 'open-sans-semibold';
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 10px;
    text-transform: capitalize;
  }

  .txt-alt{
    font-size: 16.9px;
    line-height: 20px;
    margin-bottom: 4px;
    color: $color_9B9B9B;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;

  }
  .back-to-roster{
    margin-left: 0px;
  }
  .staff-info{
    padding: 20px 25px !important;
  }

}