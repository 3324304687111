@use 'color' ;

//body > #adminRoot > div {
//  min-height: 100vh;
//}

//html, body { height: 100%; width: 100%; margin: 0; }
html,
.adminRoot {
  max-height: 100vh;
}

body {
  //min-height: 100%;
  max-height: 100vh;
  background: white;
  //font-family: open-sans-semibold;
}

body {
  max-height: 100vh;
}

#app div {
  //height:100%
  max-height: 100vh;

}

.loading-pdf {
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  //border: 1px solid #e7e7e7;
  // overflow-y: scroll;
}

a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none;
}

input {
  outline: none !important;
}

input:-webkit-autofill {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  -webkit-text-fill-color: #9B9B9B !important;
  color: #9B9B9B !important; 
  font-family: "open-sans-regular" !important; 
  font-size: 15px !important; 
}



button {
  outline: none !important;
  font-size: 12px ;
  font-family: Avenir;
  font-weight: 400 !important;
  cursor: pointer;
}

.btn-default {
  text-shadow: none;
  //display: flex;
  //justify-content: center;
}

.shadow-1 {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1) !important;
  width: calc(100% - 2px);
  //margin: 2px;
}

.btn-choose-file {
  color: #31CCBC;
  text-decoration: underline;
  position: relative;
  font-size: 18px;
  margin-top: 15px;

  //width: 100px;
  //height: 50px;
  input {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 321%;
    top: -70px;
    left: 0;
  }
}

.animation-show {
  //visibility: hidden;
  //opacity: 0;
  //transition: visibility 0s linear 10s, opacity 10s linear;
}

.text-center {
  text-align: center !important;
}

.display_row {
  display: flex;
  //flex-wrap: wrap;
}

.flex_wrap {
  flex-wrap: wrap;
}

.display_col {
  display: flex;
  flex-direction: column;
  //margin: auto;
}

.align-left {
  align-items: flex-start;
}

.align-right {
  align-items: flex-end;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.align-between {
  justify-content: space-between;
}

.align-around {
  justify-content: space-around;
}

.valign-middle {
  justify-content: center;
}

.valign-top {
  justify-content: flex-start;
}

.valign-bottom {
  justify-content: flex-end;
}

a {
  cursor: pointer;
}

.scroll {
  overflow-y: auto;
}

.pos_fixed {
  position: fixed !important;
}

.full_w {
  width: 100% !important;
  max-width: 100%;
}

.full_h_login {
  height: 100vh;
  display: flex;
  justify-content: center;
}

.full_h_1 {
  // max-height: 100% !important;
  // height: 100%;
}

.full_h_loading {
  max-height: 100% !important;
  height: 100%;
  background-color: transparent !important;
}

.full_h {
  // max-height: 100vh !important;
  // height: 100%;
  // height: 100vh !important;
  display: flex;
  //justify-content: center;
}

.height-100vh {
  min-height: calc(100vh - 69px);
}

.h-100 {
  height: 100%;
}

.sub-header-fixed {
  z-index: 8;
  width: calc(100% - 270px);
}
.header-roster-scroll.sub-header-fixed{
  position: fixed;
}

@media (max-width: 991px) and (min-width: 768px) {

  .sub-header-fixed,
  .roster-view .header-roster.scroll-active {
    width: calc(100% - 80px);
  }

  .roster-view .header-roster.scroll-active {
    margin-top: 0px;
  }
}

.sub-header-fixed.header-roster-scroll {
  box-shadow: none !important;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: white;
  // border-bottom: 1px solid rgba(0,0,0,0.1);
}

.crop-modal-img .modal-dialog {
  margin: auto;
  margin-top: 70px;
}

.crop-modal-img .modal-dialog .modal-content {
  max-height: 88vh !important;
}

.fix-modal .modal-content {
  margin-top: 60px !important;
}

.custom-modal {
  width: auto !important;
  max-width: 800px !important;
}

.loading-view {
  position: absolute;
  bottom: calc(50% - 15px);
  left: calc(50% - 15px);
}

.tags-page {
  .action-view {
    margin-top: 10px;
  }

  .text-content {
    width: 300px;
  }

  .btn-action {
    width: 100px;
    margin-left: 10px;
  }
}


.table-container {
  width: 100%;
  height: 100%;
}

.table-title {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 1px solid #e7e7e7;

  span {
    font-size: 30px;
  }
}

.table-main {
  float: right;
  color: #333;
  border: 1px solid #ccc;
  width: 200px;
  margin-top: 10px;
  margin-bottom: 30px;
  display: inline-flex;
  border-radius: 5px;

  .table-filter {
    padding: 3px 0;
    width: 50px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    background-color: #eee;
    border-radius: 0 5px 5px 0;
    border-left: 1px solid #ccc;
    text-align: center;
  }

  .griddle-filter {
    border: none;
    width: 100%;
    border-radius: 5px;
    padding-left: 5px;
  }

  :-moz-placeholder {
    color: #eaeaea;
  }
}

.popup-fake-rating {
  .title-left {
    width: 30%;
    min-width: 150px;
    max-width: 200px;
  }

  .content-right {
    width: 100%;
  }
}

.griddle-pagination {
  margin: 0 10px;
}

.griddle-next-button,
.griddle-previous-button {
  border: none;
  background-color: color.$color_def;
  color: white;
}

.griddle-page-select {
  border: 1px solid #cdcdcd;
  margin: 0 5px 10px 5px;
}

.griddle-table {
  width: 100%;
  margin: 10px 0;

  tr {
    padding: 10px;
  }
}

.griddle-row:hover {
  background-color: rgba(22, 128, 249, 0.35) !important;
  color: white;
}

.griddle-table-body>.griddle-row,
.griddle-table-heading>tr {
  border-bottom: 1px #ddd solid;
}

.griddle-table-body>tr:nth-child(odd) {
  background-color: #f9f9f9;
}

.griddle-table-heading {
  color: #0074D9;
  font-size: 15px;
  border-bottom: 2px #ddd solid;
}

.griddle-cell {
  padding: 5px 0;

  button {
    margin: 0 2px;
    padding: 1px;
    width: 30px;
    height: 30px;
  }
}

.func {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 5px;
}

.table-content-cell {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 800px;
  padding: 0 8px;
  word-wrap: break-word;
}

.griddle-table-heading-cell {
  padding: 8px;
  background-color: #dfe4ed;
}

.table-row-detail {
  padding-left: 30px;
}

.table-info {
  padding: 5px 10px 30px 10px;
  display: flex;
  border-radius: 5px;
  min-width: 435px;
  flex-direction: column;
  justify-content: center;

  //background: #ffffff url("../images/bg-title2.png") repeat-x;
  .btn-toolbar {
    position: relative;

    .fa-angle-down {
      //position: absolute;
      //right: 7px;
      z-index: 999;
      font-size: 32px;
      pointer-events: none;
    }
  }

}

.title-bar {
  margin-bottom: 8px;
}

.pu-title {
  color: #0074D9;
  font-size: 20px;
}

.btn-close {
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.btn-close:hover {
  opacity: 0.7;
}

.table-info-title {
  font-size: 32px;
  color:color.$color_def;
  border-bottom: 1px solid color.$color_def;
  margin-bottom: 20px;
}

.number-badge {
  position: absolute;
  top: -2px;
  left: 26px;
  border-radius: 10px;
  background-color: color.$color_def;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  color: color.$color_white;
  font-family: open-sans-bold;
}

.table-info-row {
  display: flex;
  margin-top: 10px;

  .row-left {
    width: 20%;
    font-weight: 700;
  }

  .row-right {
    width: 80%;
    word-break: break-all
  }
}

.popup-fake-rating {
  .row-left {
    width: 30%;
    min-width: 170px;
    font-weight: 700;
  }

  .row-right {
    //width: 80%;
    //word-break: break-all
  }

  .btn-upload-logo {
    width: 100px;
    height: 100px;
    //opacity: 0;
  }

  .btn-upload-logo[type="file"] {
    color: transparent;
  }

  .btn-upload-logo::before {
    content: '+';
    width: 100px;
    height: 100px;
    color: white;
    font-size: 40px;
    background: gray;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
  }

  .img-ava {
    width: 100px;
    height: 100px;
    object-fit: cover;
    object-fit: cover;
    position: absolute;
    top: 0;
    background: white;
    pointer-events: none;
  }
}

.table-info-buttons {
  width: 100%;
}

.table-info-bottom {
  margin-top: 10px;
  width: 100%;
  padding: 0 13px;

  .btn {
    width: 48%;
    min-width: 90px;
    font-size: 20px;
    font-weight: 300;
    padding: 3px 0;
  }
}

.btn-small {
  font-size: 25px;
  cursor: pointer;
  margin-left: 10px;
  line-height: 67px;
  color: #337ab7;
}

.table-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 25px;
  cursor: pointer;
}

.error {
  color: red;
}

.form-label {
  font-weight: 700;
  margin-bottom: 5px;
  width: 240px;
  padding-left: 3px;
  line-height: 34px;

  .note {
    font-weight: 300;
    font-size: 13px;
    line-height: 15px;
    color: #555555;
  }
}

.space {
  width: 45%;
}

.space:first-child {
  margin-right: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-control {
  //width: 100%;
  background-color: rgba(246, 246, 246, 100);
  border: 0px;
  transition: none;
  color: color.$color_grey;
  outline: none;
  height: 40px;
}

.btn-linear-orange {
  background-image: linear-gradient(to right, #FFDEA7, #EA6362) !important;
  background-color: #cacaca;
  background-repeat: repeat !important;
  border: transparent !important;
  color: white !important;
  border-radius: 25px !important;
  font-family: 'open-sans-semibold';
  font-size: 12px !important;
  font-weight: 600;
}
.register1 .btn-linear-orange{
  background-image: linear-gradient(to right, #FFDEA7, #EA6362) !important;
  background-color: #cacaca;
  background-repeat: repeat !important;
  border: transparent !important;
  color: white !important;
  border-radius: 25px !important;
  font-family: 'open-sans-semibold';
  font-weight: 600;
  font-size: 12px;
}
.btn-linear {
  background-image: linear-gradient(to right, #00CDAC, #6AD5E5) !important;
  background-repeat: repeat !important;
  font-size: 12px !important;
  background-color: lightgray;
  border: transparent !important;
  color: white !important;
  font-family: 'open-sans-semibold';
  font-weight: 600;
}

.orange-btns{
  color: #FFF;
  text-align: center;
  font-family: 'open-sans-semibold';
  font-size: 13.681px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background-image:linear-gradient(135deg, #FFDEA7 0%, #EA6362 100%);
  width: 164.372px;
  height: 37.213px;
  border: none;
}
.blue-btns{
  color: #FFF;
  text-align: center;
  font-family: 'open-sans-semibold';
  font-size: 13.681px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background-image:linear-gradient(135deg, #00CDAC 0%, #6AD5E5 100%);
  width: 164.372px;
  height: 37.213px;
  border: none;
}
.loader-fixed{
  position: absolute;
  top: 0px;
  right: 0px;
}
.form-input {
  width: 100%;
  //padding-right: 30px;
  position: relative;
}

.form-control-feedback {
  right: 0px !important;
}

.promotion .form-control-feedback {
  right: -3px !important;
}

.has-feedback .form-control {
  padding-right: 10px !important;
}

.them {
  right: 10px !important;
}

.table-new {
  color: #333;
  cursor: pointer;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  align-items: center;
  display: flex;
}

.table-image {
  height: 70px;
  object-fit: cover;
  margin-right: 5px;
  margin-bottom: 5px;
}



.table-video {
  width: 70px;
  height: 70px;
  object-fit: contain;
  background-color: black;
}

.text-area {
  height: 200px !important;
}

.table-btn-remove {
  color: #333;
  cursor: pointer;
  border: 1px solid #ccc;
  padding: 0;
  width: 75px;
  margin: 0 2px;
  border-radius: 3px;
}

.wrap {
  flex-wrap: wrap;
  display: flex;
}

.form-row {
  margin-right: 30px;
  width: 20%;
}

.form-row-2 {
  margin-right: 30px;
  width: 40%;
}

.bold {
  font-weight: bold;
}

.loading {
  width: 100%;
  text-align: center;
  margin: 150px auto;
}

.tooltip .tooltip-inner {
  background-color: #d6d6d6;
  color: #000000;
}

.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #d6d6d6;
}

.griddle-table-heading th:last-child {
  text-align: right;
}

.login-container {
  height: 100%;
  width: 100%;
  //padding: 0 90px;
  //background: white;
  margin: auto;
}

.menu-left-container {
  position: fixed;
  left: 0;
  //background-color: #f8f8f8;
  width: 188px;
  max-height: 500px;
  color: #337ab7;
  font-size: 14px;
  background-image: url("../assets/images/bg-menu-left.png");
  background-size: 80% auto;
  background-position-x: center;
  background-repeat: repeat-y;
  justify-content: flex-start;
  align-items: center;
  //font-family: Avenir-Book;
  font-family: SourceSansPro-SemiBold;
  top: 140px;

  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  label {
    margin: 5px;
  }

  .icon-menu-left {
    display: none;
  }

  .label-menu-left {
    display: block;
  }
}

.menu-left-action,
.menu-left-no-action {
  position: relative;
  cursor: pointer;
  width: 95%;
  height: 36px;
  border-radius: 8px;
  padding: 0 15px;
  margin: 2px;

  //display: flex;
  align-items: center;
  color: color.$color_354052;

  label {
    font-weight: lighter;
    cursor: pointer;
    font-size: 17px;
    line-height: 30px;
    //font-family: Avenir-Book;
    font-family: SourceSansPro-SemiBold;
    color: color.$color_354052;
    margin: 3px;
  }
}

.view-loading {
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2)
}

.menu-left-title {
  width: 95%;
  height: 36px;
  border-radius: 8px;
  padding: 0 20px;
  color: #9FA9BA;
  font-size: 14px;
  line-height: 18px;
  font-family: SourceSansPro-SemiBold;
  cursor: default;
}

.menu-left-action label {
  text-align: left;
  //background-image: linear-gradient(to right,#FFDEA7,#EA6362) !important;
  color: white !important;
}

.menu-left-no-action {
  text-align: left;
  cursor: pointer;
  color: color.$color_grey;
}

.menu-left-no-action:hover {
  background-color: #eee;
  cursor: pointer;
}

.modal-open .modal .modal-content,
.modal-open .modal .modal-body {
  max-height: 90vh;
  overflow-y: hidden;
}

.modal-open .modal .modal-dialog {
  max-height: 100vh;
}

.header-container {
  position: fixed;
  top: 0;
  z-index: 9;
  width: 84%;
  background-color: color.$color_white;
  display: flex;
  padding: 0 35px;
  flex-wrap: wrap;
  height: 84px;
  justify-content: space-between;
  align-items: center;
  // filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.02));

  .icon-social-header {
    width: 24px;
    height: 24px;
    margin-left: 15px;
  }

  .header-avatar {
    border-radius: 100px;
    width: 45px;
    height: 45px;
    object-fit: cover;
    // margin-right: 10px;
  }

  .header-name {
    font-size: 13px;
    font-family: Avenir-Roman;
    color: color.$color_C4C4C4;
    margin: 0 10px 0 20px;
    letter-spacing: 2px;
    white-space: pre;
  }

  .header-logout {
    color: color.$color_31CCBC;
    text-decoration: none;
  }

  .fa-bell-o {
    margin-right: 10px;
    width: 40px;
    height: 40px;
    font-size: 33px;
    align-items: center;
    justify-content: center;
    display: block;
    padding: 8px;
    color: color.$color_C4C4C4;
  }

  .fa-bell-o.active {
    //color: red;
    color: color.$color_DC5960;
  }
}

.header-name {
  //padding: 5px 0;
  //max-width: 50%;
  display: flex;

  span {
    //min-width: 50px;
    //overflow: hidden;
    //max-height: 20px;
    //line-height: 20px;
    text-overflow: ellipsis;
    //margin: 3px 0;
  }
}

.header-name:hover,
.header-name:active {
  //background-color: #eee;
  //color: #337ab7;
}

.header-logo {
  width: 60px;
  object-fit: contain;
}

.main {
  margin-left: 188px;
  padding: 15px 10px 0 10px;
  // padding: 0;
  background: rgba(196, 196, 196, 0.1);
  width: calc(100% - 188px);
  border-left: 1px solid #e7e7e7;
}

.login-container .form-input {
  padding: 0;
}
.login-screen .login .form-input{
  width: 348px;
  height: 49px;
  border-radius: 4.8px;
}
.login_title {
  font-size: 35px !important;
  color: #0074d9 !important;
}

// .padding-0 {
//   padding: 0 !important;
// }

.margin-0 {
  margin: 0 !important;
}

.background-image-full .register.row {
  background-image: url("../assets/images/bg-image.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: top center;
}
.background-image-full .register.row{
  height: calc(100vh - 300px);
}
.body_container{
  padding-top: 80px !important;
  background: white;
  padding-left: 57.85px !important;
}

.login,
.register {
  position: relative;
  max-width: 100%;
  width: 348px;
  margin: auto;
  //height: 100%;
  //display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .image-login {
    width: 270px;
    height: 227px;
    max-width: 100%;
    object-fit: contain;
  }

  .textName {
    margin-top: 80px;
  }

  .note-login {
    font-size: 17px;
    color: color.$color_31CCBC;
    text-align: center;
    margin-top: 48px;
  }

  .note-forgot {
    font-size: 14px;
    color: color.$color_31CCBC;
    text-align: right;
    margin-top: 5px;
    margin-right: 1px;
    font-family: 'open-sans-regular';
  }

  //background-color: black;
  //background-color: white;
}

.register_error {
  color: #FF9494;
  // color: $color_def;
  width: 100%;
  // position: absolute;
  bottom: 138px;
  height: 48px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login .note-login{
  color: #31CCBC;
  font-family: 'open-sans-regular';
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.shadow {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  margin: 4px;
}

.login_btn_login {
  width: 100%;
  color: white !important;
  border: none !important;
  margin-top: 17px;
  text-shadow: none !important;
  position: relative;
  height: 56px;
  max-width: 240px;
  border-radius: 4px;
  font-weight: 600;
  font-family:'open-sans-semibold';
  letter-spacing: 0.5px;
  font-size: 13px;

  .ico_face {
    font-size: 22px;
    position: absolute;
    left: 10px;
  }
}

fieldset {
  min-width: inherit !important;
  padding: inherit !important;
  margin: inherit !important;
  border: 2px groove #ccc !important;
  border-radius: 5px !important;
  margin-bottom: 13px !important;
}

legend {
  width: auto !important;
  border-bottom: 0 !important;
  padding: 5px !important;
  margin-bottom: 0 !important;
  font-size: 16px !important;
  font-weight: 300 !important;
  margin-left: 8px !important;
}

.not-found {
  margin: 100px auto;
  width: 100%;
  text-align: center;
  font-size: 18px;
}

.message-body {
  border: 1px solid #f1eded;
  background: #f5f4f4;
}

.icon-eye {
  width: 20px;
  height: 13px;
  position: absolute;
  right: 20px;
  color: #9b9b9b;
}

.body-main {
  background-color: #f8f8f8;
  background-image: url(../assets/images/bg-menu-left.png);
  background-size: 260px auto;
  background-position-x: 20px;
  background-repeat: repeat-y;
}

.popup {
  width: 100%;
  max-width: 400px;
}

.txt-condensed-extra-bold {
  font-family: 'open-sans-extrabold' !important;
  color: color.$color_def;
  font-weight: 800;
  //font-size: 36px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccca;
  height: 100px;
  border-radius: 14px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ccca;
}

@media screen and (max-width: 768px) {
  .menu-left-container {
    width: 60px;

    .icon-menu-left {
      display: block !important;
      width: 100%;
      object-fit: contain;
      padding: 2px;
      line-height: 36px;
      height: 36px;
    }

    .label-menu-left {
      display: none;
    }

    .menu-left-title {
      padding: 0 5px;
      text-align: center;
    }
  }

  .main {
    width: calc(100% - 60px);
    margin-left: 60px;
  }
}

.label_success {
  color: #0d8630;
  width: 100%;
  bottom: 138px;
  height: 48px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-screen .welcome {
  color: #31CCBC;
  text-align: center;
  font-size: 32.3px;
  font-family: 'open-sans-extrabold';
  font-weight: 800;
}

.login-screen .login {
  width: 416px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-screen .login .form-group {
  max-width: 345px;
  border-radius: 4.8px;
  background: #F6F6F6;
  width: 100%;
  margin-bottom: 18px;
}
.login-screen .login .form-control{
  width: 345px;
  height: 48px;
  background: #F6F6F6;
}
.login-screen .login .form-control::placeholder {
  color: #9B9B9B;
  font-size: 15.3px;
  font-family: Open Sans;
}

.login-screen .login .login-form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.login-screen .login .note-forgot {
  max-width: 345px;
  width: 100%;
  margin: 0 auto;
}

.login-screen .login_btn_login {
  background: rgb(255, 222, 167) !important;
  background: linear-gradient(164deg, #ffdea7 0%, #ea6362 70%) !important;
  max-width: 345px;
  color: #FFF;
  text-align: center;
  font-size: 13px;
  font-weight: 400px;
  font-family: 'open-sans-regular';
  letter-spacing: 0.5px;
}

.login-screen .login-fields {
  display: flex;
  align-items: center;
  background-image: url("../assets/images/login-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.login-screen .login-ins {
  padding: 0 78px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  background-image: url("../assets/images/mask-copy.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 0;
  justify-content: space-between;
  overflow: hidden;
}

.login-screen .login-ins::before {
  content: '';
  opacity: 0.8600000143051147;
  background: linear-gradient(269deg, #6AD5E5 0%, #00CDAC 100%);
  width: 100%;
  height: 100%;
  z-index: -1;
  position: absolute;
}

.login-screen .login-ins .logo {
  padding: 0 60px;
  margin-bottom: 54px;
  padding-top: 106px;
}

.login-screen .login-ins .logo img {
  max-width: 444px;
  width: 100%;
}

.login-screen .login-ins .instruction ul li {
  color: #FFF;
  font-size: 14px;
  font-family: 'open-sans-bold';
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 14px;
  list-style: none;
  position: relative;
  width: 526px;
}

.login-screen .login-ins .instruction ul li:before {
  content: url("../assets/images/ellipse-1.svg");
  width: 14px;
  height: 14px;
  display: inline-block;
  position: absolute;
  left: -37px;
  top: 3px;
}
.login-screen .login-ins .brand {
	float: left;
	width: 100%;
	display: flex;
	justify-content: center;
}
.background-image-full .register.register-payment.row, .background-image-full .register-company.row {
  min-height: calc(100vh - 61px);
  float: left;
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;
  margin-top: 61px;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.login-fields .logo{
  display: none;
}
// Media query
@media screen and (max-height: 992px) {
  .login-screen .login-ins .brand {
    width: 50%;
  }
}
@media screen and (max-width: 992px) {
  .login-screen .login-ins {
    padding: 0 20px;
  }
  .login-screen .login-fields,
  .login-screen .login-ins {
    width: 50%;
  }
  .login-fields .logo {
    display: block;
    box-shadow: 2px 6px 13px #00000021;
    height: fit-content;
    border-radius: 100%;
    padding: 20px;
    overflow: hidden;
    margin-top: 10px;
  }
  .login-fields .logo img {
    width: 100%;
    height: fit-content;
  }
}

@media screen and (max-width: 767px) {
  .login-screen .login-ins{
    display: none;
  }
  .login-screen .login-fields{
    width: 100%;
  }
}
@media screen and (max-width: 480px) {
  .login-screen .login{
    max-width: 416px;
    width: 100%;
  }
  .login-screen .welcome{
    font-size: 22px;
  }
  .login-fields .logo {
    display: block;
    box-shadow: 2px 6px 13px #00000021;
    height: fit-content;
    border-radius: 100%;
    padding: 20px;
    overflow: hidden;
    margin-top: 10px;
  }
  .login-fields .logo img {
    width: 100%;
    height: fit-content;
  }
}
@media screen and (max-width: 350px) {
  .login .textName, .register .textName {
    margin-top: 30px;
  }
  .login .note-login, .register .note-login{
    margin-top: 20px;
    padding-bottom: 20px;
  }
}
.login-screen .textName.login-form {
	width: 100%;
}

.main-container{
  margin-left: 15%;
    width: calc(100% - 200px);
    overflow-y: auto;
    background-color: #ffffff;
   
}

 

.main-container::-webkit-scrollbar {
  display: none; /* Chrome/Safari */
}

#my-team-details-modal{
  .modal-title{
    color: #00CDAC;
    font-family: 'open-sans-bold';
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
      text-align: center;
      margin-top: 10px;
      text-transform: capitalize;
  }
  .sub-title{
    color: #9B9B9B;
    text-align: center;
    font-family: 'open-sans-regular';
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 20px;
    margin-top: 20px;
  }

}

.modal-title{
  font-size: 22px;
  color:color.$color_def;
  font-family: 'open-sans-bold';
    line-height: normal;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    margin-top: 10px;
    text-transform: capitalize;

}
.showteam_icon{
  font-size: x-large !important;
  font-weight: bolder !important;
}
.message-view .body_container{
  padding-left: 0px !important;
}
.green_left{
  color: #31ccbc;
  font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
    font-family: Avenir-Medium;
    margin: 0 0 6px;
    max-height: 25px;
    width: 100%;
    letter-spacing: 1px;
    text-align: left !important;
    text-transform: capitalize;
}
.red{
  letter-spacing: 1px;
  text-transform: capitalize;
}


@media (max-width: 1399px) and (min-width: 1024px) {
.left-sidebar {
    width: 15%;
}
.sidebar-main {
  width: 100%;
}
.main-container {
  margin-left: 15%;
}
.roster-container.roster-view
{
  .search-bar {
    padding-left: 0px !important;
  }
  .search-view{
    margin-left: 0px !important;
  }
  .day-view,.shittime{
    font-size: 12px;
  }
  .roster-member-col .bottom-name-view {
    line-height: 15px;
  }
}
}
